import { message } from "antd";
import React, { useEffect, useState } from "react";
import logger from "services/logger/logger";
import { SurveyModel } from "survey-core";
import "survey-core/defaultV2.css";
import { Survey } from "survey-react-ui";
import ExcelDataExtractor from "../../components/ExcelDataExtractor";
import SurveyResponseProcessor from "../../components/SurveyResponseProcessor/SurveyResponseProcessor";
import { surveyThemeConfig } from "../../config/survey-theme-config";
import "../../styles/survey-theme.scss";
import { json } from "./default_survey";
import "./SmartBEA.scss";

const SmartBEA = () => {
  const [survey, setSurvey] = useState<SurveyModel | null>(null);
  const [showExcelExtractor, setShowExcelExtractor] = useState(false);
  const [showSurveyProcessor, setShowSurveyProcessor] = useState(false);
  const [surveyData, setSurveyData] = useState<any>(null);
  const [combinedData, setCombinedData] = useState<any>(null);

  useEffect(() => {
    // Apply theme CSS variables only to the survey form
    const surveyContainer = document.querySelector(".smart-bea-container");
    if (surveyContainer) {
      Object.entries(surveyThemeConfig.cssVariables).forEach(([key, value]) => {
        (surveyContainer as HTMLElement).style.setProperty(key, value);
      });
    }

    try {
      const parsedJson = json;
      const newSurvey = new SurveyModel(parsedJson);

      // Handle survey completion
      newSurvey.onComplete.add((sender: SurveyModel) => {
        const completedData = sender.data;

        // Check if the completed data contains location_consumption with Excel file
        // This matches the field name in your survey JSON for the file upload
        if (
          completedData.location_consumption &&
          Array.isArray(completedData.location_consumption) &&
          completedData.location_consumption.length > 0
        ) {
          // Store the survey data and show the Excel extractor
          setSurveyData(completedData);
          setShowExcelExtractor(true);
        } else {
          // Handle regular survey completion without Excel data
          message.success("Survey completed successfully!");
          // Process the survey data directly
          setSurveyData(completedData);
          setShowSurveyProcessor(true);
        }
      });

      // Set survey width to match container width
      newSurvey.width = "100%";
      // Set max width to ensure it doesn't stretch too wide
      newSurvey.maxWidth = "800px";
      setSurvey(newSurvey);
    } catch (error) {
      logger.error("Error parsing JSON:", error);
    }
  }, []);

  const handleExcelDataConfirm = (extractedData: any) => {
    if (surveyData) {
      // Create a copy of the survey data
      const updatedData = { ...surveyData };

      // Add the extracted data to the survey data
      updatedData.extractedExcelData = extractedData.extractedExcelData;

      // Also store the original Excel data structure for display purposes
      updatedData.originalExcelData = extractedData.originalData;

      // If the extracted data includes a consumptionFileURL (S3 upload link),
      // add it to the survey data for processing
      if (extractedData.consumptionFileURL) {
        updatedData.consumptionFileURL = extractedData.consumptionFileURL;
      }

      // Set the combined data for processing
      setCombinedData(updatedData);

      // Hide the Excel data extractor and show the survey processor
      setShowExcelExtractor(false);
      setShowSurveyProcessor(true);

      message.success("Excel data extracted and added to the survey submission");
    } else {
      message.error("No survey data available");
    }
  };

  const handleExcelDataCancel = () => {
    // Hide the Excel data extractor
    setShowExcelExtractor(false);

    // Show a message that the Excel data extraction was skipped
    message.info("Excel data extraction skipped");

    // Still proceed with survey processing using just the survey data
    setCombinedData(surveyData);
    setShowSurveyProcessor(true);
  };

  const handleProcessingComplete = (result: { locationId: string; userId: string }) => {
    message.success(`Successfully created location (${result.locationId}) and user (${result.userId})`);

    // Reset the state to allow for a new survey
    setShowSurveyProcessor(false);
    setSurveyData(null);
    setCombinedData(null);

    // You might want to redirect the user or show a different view here
  };

  // Map survey data to the format expected by the processor
  const mapSurveyDataToProcessorFormat = (data: any) => {
    if (!data) return {};

    // Map the survey fields to the expected format
    const mappedData = {
      // User information
      first_name: data.name?.text1 || "",
      last_name: data.name?.text2 || "",
      company_role: data.company_role || "",
      phone_number: data.phone_number || "",
      email: data.email || "",

      // Location information
      location_name: data.location_name || "",
      organization_name: data.organization_name || data.company_name || data.location_name || "", // Fallback to location name if org name not specified

      // Address information
      address_1: data.address_1 || "",
      address_2: data.address_2 || "",
      city: data.city || "",
      state: data.state || "",
      zip_code: data.zip_code || "",
      country: data.country || "",

      // Property information
      total_square_footage: data.total_square_footage || "",
      hospitality_type: data.hospitality_type || "",
      total_guest_rooms: data.total_guest_rooms || "",
      no_of_studios: data.no_of_studios || "",
      no_of_1_bedrooms: data.no_of_1_bedrooms || "",
      no_of_2_bedrooms: data.no_of_2_bedrooms || "",
      no_of_3_bedrooms: data.no_of_3_bedrooms || "",
      rooms_floor_percentage: data.rooms_floor_percentage || "",
      common_areas_floor_percentage: data.common_areas_floor_percentage || "",
      restaurants_floor_percentage: data.restaurants_floor_percentage || "",
      spas_floor_percentage: data.spas_floor_percentage || "",
      back_offices_floor_percentage: data.back_offices_floor_percentage || "",
      other_areas_floor_percentage: data.other_areas_floor_percentage || "",
      hvac_system_type: Array.isArray(data.hvac_system_type)
        ? data.hvac_system_type.join(", ")
        : data.hvac_system_type || "",
      energy_initiatives: data.energy_initiatives || "",
      has_energy_initiatives: data.has_energy_initiatives || false,

      // Billing periods
      electricity_billing_period_start: data.electricity_billing_period_start || "",
      electricity_billing_period_end: data.electricity_billing_period_end || "",
      water_billing_period_start: data.water_billing_period_start || "",
      water_billing_period_end: data.water_billing_period_end || "",
      gas_billing_period_start: data.gas_billing_period_start || "",
      gas_billing_period_end: data.gas_billing_period_end || "",
      waste_billing_period_start: data.waste_billing_period_start || "",
      waste_billing_period_end: data.waste_billing_period_end || "",

      // Include extracted Excel data if available
      extractedExcelData: data.extractedExcelData || null,
      consumptionFileURL: data.consumptionFileURL || null,

      // Include original Excel data for display
      originalExcelData: data.originalExcelData || null,
    };

    return mappedData;
  };

  if (!survey && !showExcelExtractor && !showSurveyProcessor) {
    return null;
  }

  if (showExcelExtractor && surveyData) {
    return (
      <div className="smart-bea-wrapper">
        <div className="smart-bea-container" style={{ maxWidth: "1000px" }}>
          <ExcelDataExtractor
            jsonData={surveyData}
            onConfirm={handleExcelDataConfirm}
            onCancel={handleExcelDataCancel}
          />
        </div>
      </div>
    );
  }

  if (showSurveyProcessor && (surveyData || combinedData)) {
    const processData = combinedData || surveyData;
    const mappedData = mapSurveyDataToProcessorFormat(processData);

    return (
      <div className="smart-bea-wrapper">
        <div className="smart-bea-container" style={{ maxWidth: "1000px" }}>
          <SurveyResponseProcessor surveyResponse={mappedData} onComplete={handleProcessingComplete} />
        </div>
      </div>
    );
  }

  return (
    <div className="smart-bea-wrapper">
      <div className="smart-bea-container">
        <Survey model={survey} />
      </div>
    </div>
  );
};

export default SmartBEA;
