import { useState } from "react";
import { useAuth0 } from "services/auth/authService";
import {
  useUpsertLocationMutation,
  useUserCreateMutation,
  useUsersQuery,
  useCreateLocationAddressMutation,
  useCreatePropertyInformationMutation,
  useCreateConsumptionRecordsMutation,
  useUpdateUserProfileMutation,
  useCreateSurveyResponseMutation,
  useCreateLocationOccCustomerDataMutation,
  useCreateBulkLocationOccCustomerDataMutation,
} from "pacts/app-webcore/hasura-webcore.graphql";
import errorHandler from "errorHandler";
import logger from "../services/logger/logger";
import processSurveyResponseAndCreateEntities, {
  SurveyProcessorService,
} from "../services/survey/processSurveyResponse";
import { validateUserFields } from "../services/survey/surveyUtils";

interface ProcessResult {
  locationId?: string;
  userId?: string;
  loading: boolean;
  error?: Error;
  userExists?: boolean;
}

/**
 * Hook to process survey responses and create locations and users
 */
const useSurveyResponseProcessor = () => {
  const [result, setResult] = useState<ProcessResult>({ loading: false });
  const { user: authUser } = useAuth0();

  // GraphQL mutations
  const [createLocationMutation, { loading: locationLoading }] = useUpsertLocationMutation();
  const [userCreate, { loading: userLoading }] = useUserCreateMutation({
    onError: (error) => {
      errorHandler.handleError(error);
      setResult((prev) => ({ ...prev, error, loading: false }));
    },
  });
  const [createLocationAddress, { loading: addressLoading }] = useCreateLocationAddressMutation({
    onError: (error) => {
      errorHandler.handleError(error);
      setResult((prev) => ({ ...prev, error, loading: false }));
    },
  });
  const [createPropertyInformation, { loading: propertyInfoLoading }] = useCreatePropertyInformationMutation({
    onError: (error) => {
      errorHandler.handleError(error);
      setResult((prev) => ({ ...prev, error, loading: false }));
    },
  });
  const [createConsumptionRecords, { loading: consumptionLoading }] = useCreateConsumptionRecordsMutation({
    onError: (error) => {
      logger.error("Error creating consumption records:", error);
      errorHandler.handleError(error);
    },
  });
  const [updateUserProfileMutation, { loading: profileUpdateLoading }] = useUpdateUserProfileMutation({
    onError: (error) => {
      logger.error("Error updating user profile:", error);
      errorHandler.handleError(error);
    },
  });
  const [createSurveyResponseMutation, { loading: surveyResponseLoading }] = useCreateSurveyResponseMutation({
    onError: (error) => {
      logger.error("Error creating survey response:", error);
      errorHandler.handleError(error);
    },
  });
  const [createLocationOccCustomerData, { loading: occCustomerDataLoading }] = useCreateLocationOccCustomerDataMutation(
    {
      onError: (error) => {
        logger.error("Error creating location occupancy customer data:", error);
        errorHandler.handleError(error);
      },
    }
  );
  const [createBulkLocationOccCustomerData, { loading: bulkOccLoading }] = useCreateBulkLocationOccCustomerDataMutation(
    {
      onError: (error) => {
        logger.error("Error creating bulk location occupancy data:", error);
        errorHandler.handleError(error);
      },
    }
  );

  // Query to check if user exists
  const { refetch: checkUsers, loading: checkingUser } = useUsersQuery({
    skip: true,
    fetchPolicy: "network-only",
    onError: (errorData: any) => {
      errorHandler.handleError(errorData);
      setResult((prev) => ({
        ...prev,
        error: errorData instanceof Error ? errorData : new Error("Unknown error occurred"),
        loading: false,
      }));
    },
  });

  // Service implementation for processSurveyResponseAndCreateEntities
  const surveyProcessorService: SurveyProcessorService = {
    createLocation: async (locationInput) => {
      const response = await createLocationMutation({ variables: { locationInput } });
      return response.data?.createLocation;
    },
    createUser: async (email, userInput) => {
      const response = await userCreate({
        variables: {
          email,
          user: userInput,
        },
      });
      return response.data?.userCreate;
    },
    createLocationAddress: async (addressInput) => {
      const response = await createLocationAddress({ variables: { locationAddress: addressInput } });
      return response.data?.insert_sensorflow_location_addresses_one;
    },
    createPropertyInformation: async (propertyInfoInput) => {
      const response = await createPropertyInformation({ variables: { propertyInfo: propertyInfoInput } });
      return response.data?.insert_sensorflow_properties_information_one;
    },
    createConsumptionRecords: async (records) => {
      const response = await createConsumptionRecords({ variables: { records } });
      return response.data?.insert_sensorflow_location_consumptions;
    },
    updateUserProfile: async (userId, profileData) => {
      const response = await updateUserProfileMutation({
        variables: {
          userId,
          userInput: {
            firstName: profileData.firstName,
            lastName: profileData.lastName,
            companyRole: profileData.companyRole,
            phoneNumber: profileData.phoneNumber,
          },
        },
      });
      return response.data?.update_sensorflow_users_by_pk;
    },
    createSurveyResponse: async (surveyResponseInput) => {
      // Format input for GraphQL schema
      const surveyResponseObj = {
        locationId: surveyResponseInput.locationId,
        userId: surveyResponseInput.userId,
        surveyResponse: JSON.stringify(surveyResponseInput.surveyResponse),
        consumptionFileURL: surveyResponseInput.consumptionFileURL,
      };

      try {
        const response = await createSurveyResponseMutation({
          variables: {
            surveyResponse: surveyResponseObj,
          },
        });

        // Check if we have a proper response before accessing data
        if (!response || !response.data) {
          logger.warn("Survey response mutation returned empty response");
          return null;
        }

        return response.data.insert_sensorflow_survey_responses_one;
      } catch (error) {
        logger.error("Error in createSurveyResponse:", error);
        return null;
      }
    },
    createLocationOccCustomerData: async (occCustomerData) => {
      // Format input for GraphQL schema
      const customerDataObj = {
        locationId: occCustomerData.locationId,
        occupancy: occCustomerData.occupancy !== undefined ? occCustomerData.occupancy : null,
        clientForecast: occCustomerData.clientForecast !== undefined ? occCustomerData.clientForecast : null,
        periodStart: occCustomerData.periodStart,
        periodEnd: occCustomerData.periodEnd,
      };

      const response = await createLocationOccCustomerData({
        variables: {
          customerData: customerDataObj,
        },
      });
      return response.data?.insert_sensorflow_location_occ_customer_data_one;
    },
    createBulkLocationOccCustomerData: async (records) => {
      // Format records for GraphQL schema
      const formattedRecords = records.map((record) => ({
        locationId: record.locationId,
        occupancy: record.occupancy !== undefined ? record.occupancy : null,
        clientForecast: record.clientForecast !== undefined ? record.clientForecast : null,
        periodStart: record.periodStart,
        periodEnd: record.periodEnd,
      }));

      const response = await createBulkLocationOccCustomerData({
        variables: {
          records: formattedRecords,
        },
      });

      return response.data?.insert_sensorflow_location_occ_customer_data;
    },
  };

  /**
   * Process a survey response
   */
  const processSurveyResponse = async (surveyResponse: any) => {
    try {
      setResult((prev) => ({ ...prev, loading: true }));

      // Check for existing location
      const isExistingLocation = !!surveyResponse.existing_location_id;

      if (isExistingLocation) {
        // Update existing location
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        await handleExistingLocation(surveyResponse);
      } else {
        // Process new location
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        await handleNewLocation(surveyResponse);
      }
    } catch (error) {
      logger.error("Error processing survey response:", error);
      setResult({
        loading: false,
        error: error instanceof Error ? error : new Error("An error occurred while processing the survey response"),
      });
    }
  };

  /**
   * Handle updating an existing location
   */
  const handleExistingLocation = async (surveyResponse: any) => {
    try {
      const locationId = surveyResponse.existing_location_id;

      // Update property information if available
      if (locationId) {
        try {
          const propertyInfoInput = {
            locationId,
            totalSquareFootage: parseInt(surveyResponse.total_square_footage || "0", 10),
            hospitalityType: surveyResponse.hospitality_type || "",
            totalGuestRooms: parseInt(surveyResponse.total_guest_rooms || "0", 10),
            noOfStudios: parseInt(surveyResponse.no_of_studios || "0", 10),
            noOf1Bedrooms: parseInt(surveyResponse.no_of_1_bedrooms || "0", 10),
            noOf2Bedrooms: parseInt(surveyResponse.no_of_2_bedrooms || "0", 10),
            noOf3Bedrooms: parseInt(surveyResponse.no_of_3_bedrooms || "0", 10),
            roomsFloorPercentage: parseFloat(surveyResponse.rooms_floor_percentage || "0"),
            commonAreasFloorPercentage: parseFloat(surveyResponse.common_areas_floor_percentage || "0"),
            restaurantsFloorPercentage: parseFloat(surveyResponse.restaurants_floor_percentage || "0"),
            spasFloorPercentage: parseFloat(surveyResponse.spas_floor_percentage || "0"),
            backOfficesFloorPercentage: parseFloat(surveyResponse.back_offices_floor_percentage || "0"),
            otherAreasFloorPercentage: parseFloat(surveyResponse.other_areas_floor_percentage || "0"),
            hvacSystemType: Array.isArray(surveyResponse.hvac_system_type)
              ? surveyResponse.hvac_system_type.join(", ")
              : surveyResponse.hvac_system_type || "",
            energyInitiatives: surveyResponse.energy_initiatives || "",
            hasEnergyInitiatives: surveyResponse.has_energy_initiatives === true,
            hasOtherHVACSolutions: surveyResponse.has_other_hvac_solutions === true,
            hvacConsumptionPercentage: parseFloat(surveyResponse.hvac_consumption_percentage || "0"),
            hvacSolutions: surveyResponse.hvac_solutions || "",
            electricityBillingPeriodStart: surveyResponse.electricity_billing_period_start || null,
            electricityBillingPeriodEnd: surveyResponse.electricity_billing_period_end || null,
            waterBillingPeriodStart: surveyResponse.water_billing_period_start || null,
            waterBillingPeriodEnd: surveyResponse.water_billing_period_end || null,
            gasBillingPeriodStart: surveyResponse.gas_billing_period_start || null,
            gasBillingPeriodEnd: surveyResponse.gas_billing_period_end || null,
            wasteBillingPeriodStart: surveyResponse.waste_billing_period_start || null,
            wasteBillingPeriodEnd: surveyResponse.waste_billing_period_end || null,
          };

          await surveyProcessorService.createPropertyInformation(propertyInfoInput);
        } catch (error) {
          logger.error("Error updating property information:", error);
          // Continue with the process even if property info update fails
        }

        // Store the survey response
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let responseId = null;
        if (surveyProcessorService.createSurveyResponse) {
          try {
            // We need a user ID for the survey response
            // Use the current authenticated user if available
            const userId = authUser?.sub || "unknown";

            const surveyResponseResult = await surveyProcessorService.createSurveyResponse({
              locationId,
              userId,
              submissionDate: new Date(),
              surveyResponse: {
                ...surveyResponse,
                extractedExcelData: undefined,
                originalExcelData: undefined,
              },
              consumptionFileURL: surveyResponse.consumptionFileURL || null,
            });

            // Check if we have a valid response before accessing properties
            if (surveyResponseResult) {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              responseId = surveyResponseResult.responseId;
            } else {
              logger.warn("Survey response was created but no ID was returned");
            }
          } catch (error) {
            logger.error("Error creating survey response record:", error);
            // Continue with the process
          }
        }

        // Process Excel data in the core service without creating a new location
        // Don't need to run entire processSurveyResponseAndCreateEntities when we have an existing location
        // Just pass the existing locationId and let the service handle consumption data
        const modifiedSurveyResponse = {
          ...surveyResponse,
          skipLocationCreation: true, // Signal to skip location creation
          skipPropertyInformation: true, // Signal to skip property information creation
          skipUserValidation: true, // Signal to skip user field validation
          skipSurveyResponseCreation: true, // Signal to skip survey response creation since we already created it
          user_id: authUser?.sub || "existing-user", // Provide the current user's ID

          // Ensure address fields are passed through even for existing locations
          address_1: surveyResponse.address_1,
          address_2: surveyResponse.address_2,
          city: surveyResponse.city,
          state: surveyResponse.state,
          country: surveyResponse.country,
          zip_code: surveyResponse.zip_code,
        };

        const { locationId: resultLocationId } = await processSurveyResponseAndCreateEntities(
          modifiedSurveyResponse,
          surveyProcessorService
        );

        setResult({
          locationId: resultLocationId || locationId,
          loading: false,
        });
      }
    } catch (error) {
      logger.error("Error handling existing location:", error);
      throw error;
    }
  };

  /**
   * Handle creating a new location and user
   */
  const handleNewLocation = async (surveyResponse: any) => {
    try {
      // Validate user fields
      validateUserFields(surveyResponse);
      // Check if user already exists
      const { email } = surveyResponse;
      const userExistsResponse = await checkUsers({
        filter: { search: email },
      });

      // Check for existing user
      if (userExistsResponse?.data?.users?.users?.length > 0) {
        const existingUser = userExistsResponse.data.users.users.find(
          (user: any) => user.email && user.email.toLowerCase() === email.toLowerCase()
        );

        if (existingUser) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          await handleExistingUser(surveyResponse, existingUser);
          return;
        }
      }

      // Process as completely new survey
      const { locationId, userId } = await processSurveyResponseAndCreateEntities(
        surveyResponse,
        surveyProcessorService
      );

      setResult({
        locationId,
        userId,
        loading: false,
      });
    } catch (error) {
      logger.error("Error handling new location:", error);
      throw error;
    }
  };

  /**
   * Handle the case of an existing user
   */
  const handleExistingUser = async (surveyResponse: any, existingUser: any) => {
    try {
      const { userId } = existingUser;

      if (!userId) {
        throw new Error("Existing user does not have a valid userId");
      }

      // Update user profile with new information
      if (surveyProcessorService.updateUserProfile) {
        await surveyProcessorService.updateUserProfile(userId, {
          firstName: surveyResponse.first_name || "",
          lastName: surveyResponse.last_name || "",
          companyRole: surveyResponse.company_role || "",
          phoneNumber: surveyResponse.phone_number || "",
        });
      }

      // Create location for existing user
      const locationResponse = await surveyProcessorService.createLocation({
        locationName: surveyResponse.location_name,
        organisation_id: surveyResponse.organization_id,
      });

      if (!locationResponse?.locationId) {
        throw new Error("Failed to create location for existing user");
      }

      const { locationId } = locationResponse;

      // Create a survey response first
      let responseId = null;
      if (surveyProcessorService.createSurveyResponse) {
        try {
          const surveyResponseResult = await surveyProcessorService.createSurveyResponse({
            locationId,
            userId,
            submissionDate: new Date(),
            surveyResponse: {
              ...surveyResponse,
              extractedExcelData: undefined,
              originalExcelData: undefined,
            },
            consumptionFileURL: surveyResponse.consumptionFileURL || null,
          });

          // Check if we have a valid response before accessing properties
          if (surveyResponseResult) {
            responseId = surveyResponseResult.responseId;
          } else {
            logger.warn("Survey response was created but no ID was returned");
          }
        } catch (error) {
          logger.error("Error creating survey response record:", error);
          // Continue with the process
        }
      }

      // Create additional entities using the core function
      // Add skip flags to prevent duplication
      await processSurveyResponseAndCreateEntities(
        {
          ...surveyResponse,
          user_id: userId,
          skipSurveyResponseCreation: true, // Skip survey response creation since we already created it
          skipUserValidation: true, // Skip user validation since we're using an existing user
          responseId, // Pass the response ID for consumption records
        },
        surveyProcessorService
      );

      setResult({
        locationId,
        userId,
        userExists: true,
        loading: false,
      });
    } catch (error) {
      logger.error("Error handling existing user:", error);
      throw error;
    }
  };

  return {
    result,
    processSurveyResponse,
    loading:
      result.loading ||
      locationLoading ||
      userLoading ||
      addressLoading ||
      propertyInfoLoading ||
      consumptionLoading ||
      profileUpdateLoading ||
      surveyResponseLoading ||
      occCustomerDataLoading ||
      bulkOccLoading ||
      checkingUser,
  };
};

export default useSurveyResponseProcessor;
