import { CloseCircleOutlined } from "@ant-design/icons";
import { Alert, Button, Col, List, Menu, message, Row, Tooltip } from "antd";
import { FormInstance } from "antd/lib/form";
import { FILTER_DROPDOWN_COUNT_HIDDEN_VALUE } from "components/FilterDropdown/FilterDropdown.d";
import { AutomationIcon, PreCoolIcon } from "components/Icons";
import ListFilter from "components/ListFilter/ListFilter";
import MenuDropdown from "components/MenuDropdown";
import showModal from "components/Modal/show";
import ModalWrapper from "components/Modal/Wrapper";
import errorHandler from "errorHandler";
import useBreakpoint from "hooks/use-breakpoint";
import useModalWrapperTrigger from "hooks/use-modal-wrapper-trigger";
import { isNumber } from "lodash";
import get from "lodash/get";
import moment from "moment";
import {
  CustomerKeyListWithNodeMeasurementsSubscription,
  FilterOption,
  KeyWithThermostatOff,
  NodeToSlotMappingByLocationAggregateQuery,
  NodeType,
  PositionConfigurationByPositionIdsQuery,
  TemporaryKeyListWithNodeMeasurementsQuery,
  useAddPrecoolCommandsMutation,
  useAutomationModeDisabledSubscription,
  useCancelPrecoolCommandMutation,
  useCustomerKeyListWithNodeMeasurementsSubscription,
  useGetShowHeatingModeQuery,
  useNodeToSlotMappingByLocationAggregateQuery,
  usePositionConfigurationByPositionIdsQuery,
  usePositionPrecoolStatusByLocationIdSubscription,
  useTemporaryKeyListWithNodeMeasurementsQuery,
} from "pacts/app-webcore/hasura-webcore.graphql";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { MAX_POSSIBLE_ROW_CNT } from "utils/constants";
import { AvailableOperationalModes } from "utils/locationMetadata";
import useKeyExpansion from "../hooks/use-key-expansion";
import useKeyRoomSelection from "../hooks/use-key-room-selection";
import useLocationLoader from "../hooks/use-location-loader";
import usePagination from "../hooks/use-pagination";
import { expandAndCollapseButton, getPositionQueryFilter } from "../key.helper";
import BulkConfigurationEditModal from "../KeyConfigurationList/BulkConfigurationEditModal/BulkConfigurationEditModal";
import { KeysFilterInput, OperationalMode, PositionWithRooms } from "../types";
import { ActivateComfortPlus } from "./ActivateComfortPlusModal";
import { DeactivateComfortPlusForm } from "./DeactivateComfortPlusForm/DeactivateComfortPlusForm";
import { Key, PositionPrecoolStatus, PreCoolStatus, PresetValues } from "./KeyList.d";
import { fixedFilterOptions, transformKeys } from "./KeyList.helper";
import KeyListTable from "./KeyListTable";
import SetupPreCoolTimeModal from "./SetupPreCoolTimeModal/SetupPreCoolTimeModal";

const errorMessage = {
  havingSameOperationalMode:
    "The rooms selected have different heating/cooling operation settings. Please choose only rooms that are currently in cooling mode or only rooms that are currently in heating mode.",
  temperature: (minTemp: number, maxTemp: number) =>
    `One or more of the selected rooms have setpoint limits of ${minTemp}-${maxTemp}ºC, please select a setpoint within these limits to perform pre-cool.`,
};

const KeyList = () => {
  const { locationId } = useParams<{ locationId: string }>();
  const [keys, setKeys] = useState<Key[]>([]);
  const [allKeyIds, setAllKeyIds] = useState<string[]>([]);
  const [tempKeyListQueryData, setTempKeyListQueryData] = useState<TemporaryKeyListWithNodeMeasurementsQuery | null>(
    null
  );
  const [filterOptions, setFilterOptions] = useState<FilterOption[]>([]);
  const [filter, setFilter] = useState<KeysFilterInput>({ locationId });
  const [locationCategories, setLocationCategories] = useState<any[]>([]);
  const [showDoorColumn, setShowDoorColumn] = useState(false);
  const [showHeatingMode, setShowHeatingMode] = useState<boolean>(false);
  const [isAutomationModeDisabled, setAutomationModeDisabled] = useState<boolean>(false);
  const [activateComfortPlusModalRef, openActivateComfortPlusModal] = useModalWrapperTrigger();
  const [bulkConfigurationEditModalRef, openBulkConfigurationEditModal] = useModalWrapperTrigger();
  const [availableOperationalModes, setAvailableOperationalModes] = useState<AvailableOperationalModes>();
  const [selectedRoomIds, setSelectedRoomIds] = useState<string[]>([]);
  const [precoolErrorMessage, setPrecoolErrorMessage] = useState<React.ReactNode | null>(null);
  const [positionPrecoolStatuses, setPositionPrecoolStatuses] = useState<PositionPrecoolStatus[]>([]);
  const [isAtLeastOneActivePrecoolRoom, setIsAtLeastOneActivePrecoolRoom] = useState<boolean>(false);
  const [uncancelableMessage, setUncalncelableMessage] = useState<React.ReactNode | undefined>();
  const [selectedKey, setSelectedKey] = useState<Key>();
  const [isOpenDeactivateModal, setIsOpenDeactivateModal] = useState<boolean>(false);

  const { pagination, setPagination, resetPagination, handlePaginationChange, getPagingMessage } = usePagination();

  // override default to 100
  pagination.pageSize = 100;

  const {
    resetSelection,
    hasRoomSelected,
    onSourceBinding,
    selectedPositions,
    selectionStatsMessage,
    selectedRooms,
    onSelectAll,
    handlePositionSelected,
    onTotalCountChange,
    getSelectedRooms,
  } = useKeyRoomSelection();

  const { onExpandAll, handleKeyExpanded, expandedKeys, onSourceBinding: onExpansionSourceBinding } = useKeyExpansion();

  const { location, getLocationStatusMessage } = useLocationLoader(locationId);
  const screen = useBreakpoint();

  const reloadBinding = (data?: CustomerKeyListWithNodeMeasurementsSubscription) => {
    if (data) {
      const keysData = transformKeys({
        subscriptionData: data,
        queryData: tempKeyListQueryData || undefined,
      });
      setKeys(keysData);
      onSourceBinding(keysData);
      onExpansionSourceBinding(keysData);
    }
  };

  usePositionPrecoolStatusByLocationIdSubscription({
    variables: {
      locationId,
    },
    onSubscriptionData: ({ subscriptionData: data }) => {
      if (data.data?.positionPrecoolStatus) {
        const precoolStatuses: PositionPrecoolStatus[] = data.data.positionPrecoolStatus.map((position) => {
          const now = moment();
          const presetEndTime = moment(position.startedAt).add(position.totalMinutes, "minutes");
          let isActive = true;
          switch (position.status) {
            case PreCoolStatus.CANCEL:
            case PreCoolStatus.ERROR:
            case PreCoolStatus.FAILED:
              isActive = false;
              break;
            case PreCoolStatus.SUCCESS:
              isActive = presetEndTime > now;
              break;
            default:
          }
          return {
            positionId: position.positionId,
            isActive,
          };
        });
        setPositionPrecoolStatuses(precoolStatuses);
      }
    },
  });

  useEffect(() => {
    const categoryFilterOptions = locationCategories.map((c) => ({
      field: "categories",
      value: c.categoryName,
      label: c.categoryName,
      count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
      groupName: "Category",
    }));
    setFilterOptions([...categoryFilterOptions, ...fixedFilterOptions]);
  }, [locationCategories]);

  useEffect(() => {
    // This do the trick, set selectedRoomIds state need be completed
    // before open the modal otherwise ref.current will be null
    if (selectedRoomIds.length > 0) {
      openBulkConfigurationEditModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoomIds]);

  const { loading: keysLoading, error: keysError } = useTemporaryKeyListWithNodeMeasurementsQuery({
    variables: {
      locationId,
      positionName: filter.positionName || "",
      where: getPositionQueryFilter(locationId, filter),
      offset: 0,
      limit: MAX_POSSIBLE_ROW_CNT, // with extremely large numbers, this ensures the query fetch all keys
    },
    onCompleted: (data: TemporaryKeyListWithNodeMeasurementsQuery) => {
      if (data) {
        setTempKeyListQueryData(data);
        setAllKeyIds(data.locationOne?.locationKeys?.map((k) => k.positionId) || []);
        setLocationCategories(data?.locationOne?.keyCategories ?? []);
        const totalCount = data.locationOne?.positionsAggregate.aggregate?.count || 0;
        setPagination((currentPagination) => ({
          ...currentPagination,
          total: totalCount,
        }));
        onTotalCountChange(totalCount);
      }
    },
    onError: errorHandler.handleError,
  });

  useNodeToSlotMappingByLocationAggregateQuery({
    variables: {
      locationId,
      nodeType: NodeType.Door,
    },
    onCompleted: (data: NodeToSlotMappingByLocationAggregateQuery) => {
      const doorNodeMappingCount = get(data, "nodeToSlotMappingByLocationAggregate.aggregate.count", 0);
      if (doorNodeMappingCount > 0) {
        setShowDoorColumn(true);
      }
    },
    onError: errorHandler.handleError,
  });

  useCustomerKeyListWithNodeMeasurementsSubscription({
    fetchPolicy: "network-only",
    variables: {
      where: {
        positionId: {
          _in: allKeyIds.slice(
            (pagination.current - 1) * pagination.pageSize,
            pagination.current * pagination.pageSize
          ),
        },
      },
    },
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData) {
        reloadBinding(subscriptionData.data);
      }
    },
  });

  useGetShowHeatingModeQuery({
    variables: {
      locationId,
    },
    onCompleted: (data) => {
      setShowHeatingMode(!!data.locationMetadata?.showHeatingMode);
      setAvailableOperationalModes(
        (data.locationMetadata?.availableOperationalModes as AvailableOperationalModes) ??
          AvailableOperationalModes.Both
      );
    },
  });

  const statusMessage = useMemo(() => {
    if (getLocationStatusMessage()) return getLocationStatusMessage();
    if (location) {
      const { locationName } = location;
      return getPagingMessage(locationName);
    }

    return "";
  }, [location, getLocationStatusMessage, getPagingMessage]);

  const handleFilterChange = (newFilter: KeysFilterInput) => {
    resetPagination();
    setFilter(newFilter);
    resetSelection();
  };

  const roomListWithThermostatOff = (roomKeys: KeyWithThermostatOff[]): React.ReactNode => {
    const list = roomKeys
      .filter((key) => key.rooms && key.rooms.length > 0)
      .map((key) => (
        <div key={key.keyId} className="">
          <List className="text-left">
            {key.keyName}:
            {key.rooms?.map(
              (room) =>
                room && (
                  <List.Item key={room.roomId} className="pl-l py-none border-none">
                    {room.roomName}
                  </List.Item>
                )
            )}
          </List>
        </div>
      ));
    return (
      <>
        Cannot pre-set timer for those rooms because thermostat is offline:
        {list}
      </>
    );
  };

  const [createPrecoolCommandsMutation, { loading: createPrecoolCommandLoading }] = useAddPrecoolCommandsMutation({
    onCompleted: (data) => {
      if (data.addPrecoolCommands) {
        const numOfSelectedRooms = selectedRooms.size;
        resetSelection();
        if (data.addPrecoolCommands.length > 0) {
          const numOfFailedRooms = data.addPrecoolCommands.length;
          setPrecoolErrorMessage(roomListWithThermostatOff(data.addPrecoolCommands));
          const successMessage =
            numOfFailedRooms < numOfSelectedRooms
              ? `Pre-Set jobs successfully set for ${numOfSelectedRooms - numOfFailedRooms} rooms. `
              : "";
          message.error(
            `${successMessage}Some of the room's thermostat are offline and cannot be preset all of those.`
          );
        } else {
          message.success(`Pre-Set jobs successfully set for ${numOfSelectedRooms} rooms.`);
        }
      }
    },
    onError: errorHandler.handleError,
  });

  const [cancelPrecool, { loading: cancelPrecoolLoading }] = useCancelPrecoolCommandMutation({
    onCompleted: ({ cancelPrecoolCommand: data }) => {
      if (data) {
        const numOfCanceledSuccessfullyRooms = data.canceledCommands?.length;
        resetSelection();

        if (numOfCanceledSuccessfullyRooms)
          message.success(`Pre-set jobs successfully cancelled for ${numOfCanceledSuccessfullyRooms} rooms`);

        if (data.uncancelableRooms && data.uncancelableRooms.length > 0) {
          const roomNames: string[] = [];
          data.uncancelableRooms.forEach((room) => {
            const positionStatus = positionPrecoolStatuses.find((position) => position.positionId === room.roomId);
            if (positionStatus?.isActive) roomNames.push(room.roomName);
          });
          if (roomNames.length > 0)
            setUncalncelableMessage(
              <>Pre-set jobs could not be canceled for rooms {roomNames.join(", ")} as they have already started.</>
            );
        }
      }
    },
  });

  const fetchSelectedRooms = () => {
    return getSelectedRooms({
      where: getPositionQueryFilter(locationId, filter),
    });
  };

  const openEditModal = async () => {
    const roomIds = await fetchSelectedRooms();
    await setSelectedRoomIds(roomIds);
  };

  const { refetch: reloadPositionConfigurations } = usePositionConfigurationByPositionIdsQuery({
    skip: true,
  });

  const showModalSetupPreCoolTimeModal = (
    data: PositionConfigurationByPositionIdsQuery,
    roomIds: string[],
    operationalMode: OperationalMode
  ) =>
    showModal({
      element: SetupPreCoolTimeModal,
      config: {
        onOk: async ({ mins, mode, temperature, startTime }: PresetValues, form: FormInstance<PresetValues>) => {
          let minTemp = 16;
          let maxTemp = 32;
          data.positionConfigurations.forEach((config) => {
            const { minTemp: minTempConfig, maxTemp: maxTempConfig } = config;
            if (isNumber(minTempConfig) && isNumber(maxTempConfig)) {
              minTemp = minTempConfig >= minTemp ? minTempConfig : minTemp;
              maxTemp = maxTempConfig <= maxTemp ? maxTempConfig : maxTemp;
            }
          });
          if (temperature < minTemp || temperature > maxTemp) {
            form.setFields([{ name: "temperature", errors: [errorMessage.temperature(minTemp, maxTemp)] }]);
            throw new Error("Setpoint limits fail");
          }
          createPrecoolCommandsMutation({
            variables: {
              positionIds: roomIds,
              totalMinutes: mins,
              mode,
              temperature,
              startedAt: startTime?.utc().format(),
            },
          });
        },
        showHeatingMode,
        operationalMode,
      },
    });

  const preCoolRoom = async () => {
    const roomIds = await fetchSelectedRooms();
    const { data } = await reloadPositionConfigurations({ positionIds: roomIds });
    const operationalMode = data.positionConfigurations[0].operationalMode as OperationalMode;
    let havingSameOperationalMode = true;
    data.positionConfigurations.forEach((room) => {
      if (operationalMode !== room.operationalMode) havingSameOperationalMode = false;
    });
    if (!havingSameOperationalMode) return message.error(errorMessage);
    showModalSetupPreCoolTimeModal(data, roomIds, operationalMode);
  };

  const changeAutomationMode = () => {
    if (hasRoomSelected()) openEditModal();
  };

  const isUpdateInProgress = useMemo(
    () => keysLoading || createPrecoolCommandLoading || cancelPrecoolLoading,
    [keysLoading, createPrecoolCommandLoading, cancelPrecoolLoading]
  );

  useAutomationModeDisabledSubscription({
    variables: {
      locationId,
    },
    onSubscriptionData: (data) => {
      setAutomationModeDisabled(data.subscriptionData.data?.locationMetadata?.isAutomationModeDisabled ?? false);
    },
  });

  const reasonDisableSetAutomatiomModeButton = () => {
    if (isAutomationModeDisabled) {
      return "Automation has been disabled, please enable it to edit configurations.";
    }
    if (!hasRoomSelected()) {
      return "Please select at least 1 key to set Automation Mode.";
    }
  };

  const handleOnCancel = async () => {
    const roomIds = await fetchSelectedRooms();
    await cancelPrecool({ variables: { roomIds } });
  };

  useEffect(() => {
    let isHasOneActivePrecoolRoom = false;
    selectedRooms.forEach((selectedRoom) => {
      if (positionPrecoolStatuses) {
        const positionStatus = positionPrecoolStatuses.find((position) => position.positionId === selectedRoom);
        if (positionStatus && positionStatus.isActive) {
          isHasOneActivePrecoolRoom = true;
        }
      }
    });
    setIsAtLeastOneActivePrecoolRoom(isHasOneActivePrecoolRoom);
  }, [selectedRooms, positionPrecoolStatuses]);
  return (
    <>
      <Row justify="space-between" className="mb-l">
        <Col>
          <h2 className="d-inline font-weight-bold mr-xl">Keys</h2>
          <p className="d-inline">{statusMessage}</p>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Row align="middle" justify="space-between" className="mb-l">
            <Col xs={{ span: 22 }} lg={{ span: 12 }}>
              <ListFilter
                currentFilter={filter}
                onChange={handleFilterChange}
                searchField="positionName"
                searchPlaceholder="Search key name"
                filterOptions={filterOptions}
                waitApplyAll
              />
            </Col>
            <Col xs={{ span: 2 }} lg={{ span: 12 }} className="text-right">
              {screen.mobileAndTabletOnly && (
                <MenuDropdown
                  mode="vertical"
                  menu={
                    <Menu className="normalize-dropdown-menu-icons">
                      {!(!hasRoomSelected() || isUpdateInProgress || !isAtLeastOneActivePrecoolRoom) && (
                        <Menu.Item
                          icon={<CloseCircleOutlined className="pr-xs" style={{ fontSize: "1rem" }} />}
                          onClick={handleOnCancel}
                        >
                          Cancel Pre-set
                        </Menu.Item>
                      )}
                      <Menu.Item
                        disabled={!hasRoomSelected() || isUpdateInProgress}
                        icon={<PreCoolIcon className="pr-xs" />}
                        onClick={preCoolRoom}
                      >
                        Pre-set
                      </Menu.Item>
                      <Tooltip title={reasonDisableSetAutomatiomModeButton()}>
                        <Menu.Item
                          disabled={!hasRoomSelected() || isUpdateInProgress || isAutomationModeDisabled}
                          icon={<AutomationIcon className="pr-xs" />}
                          onClick={changeAutomationMode}
                        >
                          Set Automation Mode
                        </Menu.Item>
                      </Tooltip>
                    </Menu>
                  }
                />
              )}
            </Col>
          </Row>
          {screen.desktopUp && (
            <div className="d-flex justify-content-between">
              {expandAndCollapseButton(keys as PositionWithRooms[], onExpandAll)}
              <div className="d-flex justify-content-end">
                {!(!hasRoomSelected() || isUpdateInProgress || !isAtLeastOneActivePrecoolRoom) && (
                  <Button
                    icon={<CloseCircleOutlined className="mr-xs text-error" style={{ fontSize: "1rem" }} />}
                    type="default"
                    className="mr-m"
                    data-testid="btn-cancel-precool-testId"
                    onClick={handleOnCancel}
                  >
                    Cancel Pre-set
                  </Button>
                )}

                <Button
                  icon={<PreCoolIcon className="mr-xs" />}
                  type="primary"
                  className="mr-m"
                  data-testid="btn-precool-testId"
                  onClick={preCoolRoom}
                  disabled={!hasRoomSelected() || isUpdateInProgress}
                >
                  Pre-set
                </Button>
                <Tooltip title={reasonDisableSetAutomatiomModeButton()}>
                  <Button
                    icon={<AutomationIcon className="mr-xs" />}
                    type="primary"
                    className="mr-m"
                    data-testid="btn-change-automation-testId"
                    onClick={changeAutomationMode}
                    disabled={!hasRoomSelected() || isUpdateInProgress || isAutomationModeDisabled}
                  >
                    Set Automation Mode
                  </Button>
                </Tooltip>
              </div>
            </div>
          )}
          {precoolErrorMessage && (
            <Alert
              onClose={() => setPrecoolErrorMessage(null)}
              description={precoolErrorMessage}
              type="error"
              className="mt-l"
              showIcon
              closable
            />
          )}
          {uncancelableMessage && (
            <Alert
              onClose={() => setUncalncelableMessage(null)}
              description={uncancelableMessage}
              type="error"
              className="mt-l"
              showIcon
              closable
            />
          )}
          {!keysLoading && (
            <div className="py-s">
              <span className="text-primary">{selectionStatsMessage}</span>
            </div>
          )}
          <KeyListTable
            tableData={keys}
            pagination={{
              ...pagination,
              pageSizeOption: ["10", "20", "50", "100"],
              showSizeChanger: true,
            }}
            keysLoading={keysLoading}
            handleTableChange={(paginationInner: any) => {
              handlePaginationChange(paginationInner);
              window.scrollTo({ top: 0, behavior: "auto" });
            }}
            loading={keysLoading}
            error={keysError}
            expandable={{
              rowExpandable: () => true,
              childrenColumnName: "rooms",
            }}
            onExpand={(expand: boolean, record: Key) => {
              handleKeyExpanded(keys as PositionWithRooms[], [record] as PositionWithRooms[], expand);
            }}
            expandedRowKeys={expandedKeys}
            showDoorColumn={showDoorColumn}
            rowSelection={{
              selectedRowKeys: Array.from(selectedPositions),
              type: "checkbox",
              onSelectAll: (selected: boolean) => {
                onSelectAll(selected, keys as PositionWithRooms[]);
              },
              onSelect: (record: Key, selected: boolean) => {
                handlePositionSelected(keys as PositionWithRooms[], [record] as PositionWithRooms[], selected);
              },
              getCheckboxProps: (record: Key) => ({
                name: record.positionId,
              }),
              preserveSelectedRowKeys: true,
            }}
            onComfortPLusChange={(checked: boolean, key: Key) => {
              setSelectedKey(key);
              if (checked) openActivateComfortPlusModal();
              else setIsOpenDeactivateModal(true);
            }}
          />
        </Col>
      </Row>
      <ModalWrapper
        ref={bulkConfigurationEditModalRef}
        modal={BulkConfigurationEditModal}
        props={{
          showHeatingMode,
          availableOperationalModes,
          selectedPositionIds: selectedRoomIds,
          choosesAutomationMode: true,
          onSuccess: () => {
            //
          },
        }}
      />
      <ModalWrapper ref={activateComfortPlusModalRef} modal={ActivateComfortPlus} props={{ selectedKey }} />
      <DeactivateComfortPlusForm
        open={isOpenDeactivateModal}
        onClose={() => setIsOpenDeactivateModal(false)}
        reservation={selectedKey?.reservation || []}
      />
    </>
  );
};

export default KeyList;
