import React, { useState } from "react";

import { message, Modal } from "antd";
import { ModalWrapperProps } from "components/Modal/Modal";
import { useGetHolisticsTokenQuery } from "pacts/app-webcore/hasura-webcore.graphql";

export type RoomTrendsModalProps = {
  locationId: string;
  locationName: string;
  roomName: string;
  keyName: string;
};

const embedId = process.env.REACT_APP_HOLISTICS_EMBED_ID;

const RoomTrendsModal = ({
  roomName,
  keyName,
  locationId,
  locationName,
  modalWrapperProps: { onCancel, ...modalWrapperOtherProps },
}: RoomTrendsModalProps & ModalWrapperProps) => {
  const [holisticsPayload, setHolisticsPayload] = useState<string>("");

  const filters = {
    f1: {
      default_condition: {
        operator: "is",
        values: [locationName],
        modifier: null,
      },
    },
    f2: {
      default_condition: {
        operator: "is",
        values: [keyName],
        modifier: null,
      },
    },
    f3: {
      default_condition: {
        operator: "is",
        values: [roomName],
        modifier: null,
      },
    },
  };

  useGetHolisticsTokenQuery({
    variables: { embedId, locationId, filters: JSON.stringify(filters) },
    onCompleted: (holisticsData) => {
      if (!holisticsData.getHolisticsToken || holisticsData.getHolisticsToken === "") {
        message.error("Unable to generate dashboard token. Please check configuration.");
      }
      setHolisticsPayload(holisticsData.getHolisticsToken ?? "");
    },
  });

  return (
    <Modal
      title="Room Trends"
      width="1200px"
      {...modalWrapperOtherProps}
      centered
      onCancel={onCancel}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      bodyStyle={{ padding: 0, height: "calc(100vh - 55px)" }}
    >
      <iframe
        title="holistics embed"
        src={`https://secure.holistics.io/embed/${embedId}?_token=${holisticsPayload}`}
        id="embedded-iframe"
        width="100%"
        height="100%"
        className="p-0"
        frameBorder="0"
        allowFullScreen
      />
    </Modal>
  );
};

export default RoomTrendsModal;
