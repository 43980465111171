import { message } from "antd";
import { useGetHolisticsTokenQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import React, { useState } from "react";

export type KeyRoomTrendsProps = {
  locationId: string;
  locationName: string;
  roomName: string;
  keyName: string;
};

const embedId = process.env.REACT_APP_HOLISTICS_EMBED_ID ?? "";
const KeyRoomTrends = ({ locationId, locationName, roomName, keyName }: KeyRoomTrendsProps) => {
  const [holisticsPayload, setHolisticsPayload] = useState<string>("");

  const filters = {
    f1: {
      default_condition: {
        operator: "is",
        values: [locationName],
        modifier: null,
      },
    },
    f2: {
      default_condition: {
        operator: "is",
        values: [keyName],
        modifier: null,
      },
    },
    f3: {
      default_condition: {
        operator: "is",
        values: [roomName],
        modifier: null,
      },
    },
  };

  useGetHolisticsTokenQuery({
    variables: { embedId, locationId, filters: JSON.stringify(filters) },
    onCompleted: (holisticsData) => {
      if (!holisticsData.getHolisticsToken || holisticsData.getHolisticsToken === "") {
        message.error("Unable to generate dashboard token. Please check configuration.");
      }
      setHolisticsPayload(holisticsData.getHolisticsToken ?? "");
    },
  });
  return (
    <div>
      <iframe
        title="holistics embed"
        src={`https://secure.holistics.io/embed/${embedId}?_token=${holisticsPayload}`}
        id="embedded-iframe"
        width="100%"
        height="800px"
        className="p-0"
        frameBorder="0"
        allowFullScreen
      />
    </div>
  );
};

export default KeyRoomTrends;
