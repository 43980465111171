import AWS from "aws-sdk";
import logger from "../logger/logger";

/**
 * Service for interacting with AWS S3
 */
class S3Service {
  private s3: AWS.S3;

  constructor() {
    // Initialize AWS SDK with credentials from environment variables
    // In a production environment, these would be properly configured
    // through environment variables or AWS IAM roles
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION || "us-east-1",
    });

    this.s3 = new AWS.S3();
  }

  /**
   * Upload a file to S3
   * @param file The file data (base64 or binary)
   * @param fileName The name of the file
   * @param fileType The MIME type of the file
   * @param bucketName The S3 bucket name (defaults to environment variable)
   * @returns Promise with the S3 upload result
   */
  async uploadFile(
    file: string | Buffer,
    fileName: string,
    fileType: string,
    bucketName: string = process.env.REACT_APP_S3_BUCKET_NAME || ""
  ): Promise<AWS.S3.ManagedUpload.SendData> {
    if (!bucketName) {
      logger.error("S3 bucket name is not defined");
      throw new Error("S3 bucket name is not defined");
    }

    // Convert base64 to buffer if needed
    let fileBuffer: Buffer;
    if (typeof file === "string") {
      // Check if it's a base64 string
      if (file.startsWith("data:")) {
        // Extract the base64 part
        const base64Data = file.split(",")[1];
        fileBuffer = Buffer.from(base64Data, "base64");
      } else {
        // Assume it's already a base64 string without the data URL prefix
        fileBuffer = Buffer.from(file, "base64");
      }
    } else {
      fileBuffer = file;
    }

    // Generate a unique key for the file
    const timestamp = new Date().getTime();
    const key = `excel-uploads/${timestamp}-${fileName}`;

    // Set up the upload parameters
    const params: AWS.S3.PutObjectRequest = {
      Bucket: bucketName,
      Key: key,
      Body: fileBuffer,
      ContentType: fileType,
      ACL: "private", // Set appropriate ACL based on your requirements
    };

    try {
      // Upload the file to S3
      const uploadResult = await this.s3.upload(params).promise();
      return uploadResult;
    } catch (error) {
      logger.error("Error uploading file to S3:", error);
      throw error;
    }
  }

  /**
   * Get a signed URL for a file in S3
   * @param key The S3 object key
   * @param bucketName The S3 bucket name (defaults to environment variable)
   * @param expiresIn The number of seconds until the URL expires (default: 3600 = 1 hour)
   * @returns The signed URL
   */
  getSignedUrl(
    key: string,
    bucketName: string = process.env.REACT_APP_S3_BUCKET_NAME || "",
    expiresIn: number = 3600
  ): string {
    if (!bucketName) {
      logger.error("S3 bucket name is not defined");
      throw new Error("S3 bucket name is not defined");
    }

    const params = {
      Bucket: bucketName,
      Key: key,
      Expires: expiresIn,
    };

    return this.s3.getSignedUrl("getObject", params);
  }
}

// Create a singleton instance
const s3Service = new S3Service();

export default s3Service;
