import { loadSmplrJs } from "@smplrspace/smplr-loader";
import * as React from "react";
import { FC, useEffect } from "react";

import logger from "services/logger/logger";
import { SpaceViewerProps } from "./types";
import { DEFAULT_WALL_AND_FLOOR_OPACITY } from "./constants";

const SpaceViewer: FC<SpaceViewerProps> = ({ env, spaceId, clientToken, mode, onReady, onResize }) => {
  useEffect(() => {
    loadSmplrJs("umd", env)
      .then((smplr) => {
        const space = new smplr.Space({
          spaceId,
          clientToken,
          containerId: "sensorflow-smplrspace",
        });
        space.startViewer({
          preview: false,
          mode,
          allowModeChange: true,
          onReady: () => onReady(space, smplr),
          onError: (error) => logger.error("Could not start viewer", error),
          onResize,
          hideLevelPicker: true,
          renderOptions: {
            floorplan: {
              alpha: DEFAULT_WALL_AND_FLOOR_OPACITY,
            },
            walls: {
              alpha: DEFAULT_WALL_AND_FLOOR_OPACITY,
            },
          },
        });
      })
      .catch((error) => logger.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div id="sensorflow-smplrspace" className="smplr-embed" />;
};

export default SpaceViewer;
