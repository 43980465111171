import { Button, Card, Result, Spin, Steps, Typography, message } from "antd";
import useSurveyResponseProcessor from "hooks/use-survey-response-processor";
import React, { useState } from "react";
import { SurveyModel } from "survey-core";
import { Survey } from "survey-react-ui";
import ExcelDataExtractor from "../../../components/ExcelDataExtractor/ExcelDataExtractor";
import SurveyResponseProcessor from "../../../components/SurveyResponseProcessor/SurveyResponseProcessor";
import { surveyThemeConfig } from "../../../config/survey-theme-config";
import "../../../styles/survey-theme.scss";
import { json } from "../default_survey";
import "./SmartBEA.scss";

const { Paragraph, Title } = Typography;
const { Step } = Steps;

interface OnboardingComponentProps {
  locationId: string;
}

const OnboardingComponent: React.FC<OnboardingComponentProps> = ({ locationId }) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [survey, setSurvey] = useState<SurveyModel | null>(null);
  const [surveyData, setSurveyData] = useState<any>(null);
  const [showExcelExtractor, setShowExcelExtractor] = useState<boolean>(false);
  const [combinedData, setCombinedData] = useState<any>(null);
  // Set a fixed userId for the existing user without creating a new one
  const [userId] = useState<string>("existing-user-id");
  const [isStartScreen, setIsStartScreen] = useState<boolean>(true);
  const { loading } = useSurveyResponseProcessor();

  // Initialize the survey with the predefined JSON
  React.useEffect(() => {
    // Apply theme CSS variables only to the survey form
    const surveyContainer = document.querySelector(".onboarding-component");
    if (surveyContainer) {
      Object.entries(surveyThemeConfig.cssVariables).forEach(([key, value]) => {
        (surveyContainer as HTMLElement).style.setProperty(key, value);
      });
    }

    // Create the survey model
    try {
      const newSurvey = new SurveyModel(json);

      // Handle survey completion
      newSurvey.onComplete.add((sender) => {
        const completedData = sender.data;
        setSurveyData(completedData);

        // Check if the completed data contains location_consumption with Excel file
        if (
          completedData.location_consumption &&
          Array.isArray(completedData.location_consumption) &&
          completedData.location_consumption.length > 0
        ) {
          // Store the survey data and show the Excel extractor
          setShowExcelExtractor(true);
        } else {
          // Skip email verification and go directly to processing
          setCombinedData(completedData);
          setCurrentStep(1);
        }
      });

      // Set survey width
      newSurvey.width = "100%";
      newSurvey.maxWidth = "800px";
      setSurvey(newSurvey);
    } catch (error) {
      message.error("Error initializing the survey form");
    }
  }, []);

  const handleStartSurvey = () => {
    // Hide the start screen and show the survey
    setIsStartScreen(false);
  };

  const handleProcessingComplete = (result: { locationId: string; userId: string }) => {
    message.success(`Successfully updated location information for ${result.locationId}`);
    // Refresh the page to reflect the updated onboarding status
    window.location.reload();
  };

  const handleExcelDataConfirm = (extractedData: any) => {
    if (surveyData) {
      // Create a copy of the survey data
      const updatedData = { ...surveyData };

      // Add the extracted data to the survey data
      updatedData.extractedExcelData = extractedData.extractedExcelData;

      // Also store the original Excel data structure for display purposes
      updatedData.originalExcelData = extractedData.originalData;

      // If the extracted data includes a consumptionFileURL (S3 upload link),
      // add it to the survey data for processing
      if (extractedData.consumptionFileURL) {
        updatedData.consumptionFileURL = extractedData.consumptionFileURL;
      }

      // Set the combined data for processing
      setCombinedData(updatedData);

      // Hide the Excel data extractor and move to the processing step
      setShowExcelExtractor(false);
      setCurrentStep(1);

      message.success("Excel data extracted and added to the survey submission");
    } else {
      message.error("No survey data available");
    }
  };

  const handleExcelDataCancel = () => {
    // Hide the Excel data extractor
    setShowExcelExtractor(false);

    // Show a message that the Excel data extraction was skipped
    message.info("Excel data extraction skipped");

    // Still proceed with survey processing using just the survey data
    setCombinedData(surveyData);
    setCurrentStep(1);
  };

  const renderStepContent = () => {
    if (showExcelExtractor && surveyData) {
      return (
        <ExcelDataExtractor jsonData={surveyData} onConfirm={handleExcelDataConfirm} onCancel={handleExcelDataCancel} />
      );
    }

    switch (currentStep) {
      case 0:
        if (!survey) {
          return (
            <div className="loading-container">
              <Spin size="large" tip="Loading survey..." />
            </div>
          );
        }
        return <Survey model={survey} />;
      case 1:
        return (
          <SurveyResponseProcessor
            surveyResponse={{
              ...(combinedData || surveyData),
              // Add the location ID to the survey data - this signals to the processor
              // that we're updating an existing location, not creating a new one
              existing_location_id: locationId,
              // Add the user ID from the email verification step
              userId,
            }}
            onComplete={handleProcessingComplete}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Card className="onboarding-component">
      {isStartScreen ? (
        <Result
          status="info"
          title="Complete Onboarding"
          subTitle="To use Smart Building Energy Analytics, you need to complete the onboarding process."
          extra={
            <div className="onboarding-content">
              <Paragraph>
                Before you can analyze energy consumption data, we need some basic information about this location.
                Please complete a survey to provide details about the property, including:
              </Paragraph>
              <ul className="requirements-list">
                <li>
                  <span>Building size and layout</span>
                </li>
                <li>
                  <span>Occupancy patterns</span>
                </li>
                <li>
                  <span>Energy systems information</span>
                </li>
                <li>
                  <span>Billing periods</span>
                </li>
              </ul>
              <div className="action-button">
                <Button type="primary" size="large" onClick={handleStartSurvey} loading={loading}>
                  Start Onboarding
                </Button>
              </div>
            </div>
          }
        />
      ) : (
        <>
          <Title level={4}>Smart Building Energy Analytics Onboarding</Title>
          {!showExcelExtractor && (
            <Steps current={currentStep} style={{ marginBottom: 24 }}>
              <Step title="Survey" description="Complete property details" />
              <Step title="Process" description="Save property information" />
            </Steps>
          )}
          {renderStepContent()}
        </>
      )}
    </Card>
  );
};

export default OnboardingComponent;
