import React, { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Card, Row, Col, Typography, Button, Modal, Affix, Tooltip } from "antd";
import { FullscreenOutlined, PieChartOutlined, AreaChartOutlined, UpOutlined } from "@ant-design/icons";

import styles from "./SmartAIM.module.scss";
import SmartAIMHolisticsBoard from "./SmartAIMHolisticsBoard";

const { Title, Text } = Typography;

interface WidgetConfig {
  id: string;
  key: string;
  title: string;
}

interface DetailedViewConfig {
  visible: boolean;
  widgetId: string;
  title: string;
  embedId: string;
  filters: any;
}

type SmartAIMProps = {
  locationName: string;
};

const systemMetricsEmbedId = process.env.REACT_APP_HOLISTICS_SYSTEM_METRICS_EMBED_ID;
const systemMetricsDetailedViewEmbedId = process.env.REACT_APP_HOLISTICS_SYSTEM_METRICS_DETAILED_VIEW_EMBED_ID;
const latestBuildingDataEmbedId = process.env.REACT_APP_HOLISTICS_LATEST_BUILDING_DATA_EMBED_ID;
const latestBuildingDataDetailedViewEmbedId =
  process.env.REACT_APP_HOLISTICS_LATEST_BUILDING_DATA_DETAILED_VIEW_EMBED_ID;

const SmartAIM = ({ locationName }: SmartAIMProps) => {
  const { locationId } = useParams<{ locationId: string }>();
  const [detailedView, setDetailedView] = useState<DetailedViewConfig>({
    visible: false,
    widgetId: "",
    title: "",
    embedId: "",
    filters: null,
  });

  // Create refs for each widget card
  const widgetRefs = useRef<{ [key: string]: React.RefObject<HTMLDivElement> }>({});

  // Define the widgets
  const widgets: WidgetConfig[] = [
    { id: "widget-1", key: "currentStatistics", title: "Current Statistics" },
    { id: "widget-2", key: "sensorflowSystem", title: "SensorFlow System" },
  ];

  // Initialize refs for each widget
  widgets.forEach((widget) => {
    if (!widgetRefs.current[widget.id]) {
      widgetRefs.current[widget.id] = React.createRef<HTMLDivElement>();
    }
  });

  // Scroll to widget function
  const scrollToWidget = (widgetId: string) => {
    const ref = widgetRefs.current[widgetId];
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const openDetailedView = (widgetId: string, title: string, currentEmbedId: string, currentFilters: any) => {
    setDetailedView({
      visible: true,
      widgetId,
      title,
      embedId: currentEmbedId,
      filters: currentFilters,
    });
  };

  const closeDetailedView = () => {
    setDetailedView({
      ...detailedView,
      visible: false,
    });
  };

  const locationNameFilter = {
    f1: {
      default_condition: {
        operator: "is",
        values: [locationName],
        modifier: null,
      },
    },
  };

  const fullscreenIcon = <FullscreenOutlined />;

  // Render a widget row based on its configuration
  const renderWidget = (widget: WidgetConfig) => {
    let content = null;
    let currentEmbedId = "";
    let detailedViewEmbedId = "";
    let currentFilters: any = null;
    let widgetIcon = null;

    switch (widget.key) {
      case "currentStatistics":
        currentEmbedId = latestBuildingDataEmbedId || "";
        detailedViewEmbedId = latestBuildingDataDetailedViewEmbedId || "";
        currentFilters = locationNameFilter;
        widgetIcon = <PieChartOutlined />;
        content = (
          <div className={styles.cardContent}>
            <SmartAIMHolisticsBoard locationId={locationId} embedId={currentEmbedId} filters={currentFilters} />
          </div>
        );
        break;
      case "sensorflowSystem":
        currentEmbedId = systemMetricsEmbedId || "";
        detailedViewEmbedId = systemMetricsDetailedViewEmbedId || "";
        currentFilters = locationNameFilter;
        widgetIcon = <AreaChartOutlined />;
        content = (
          <div className={styles.cardContent}>
            <SmartAIMHolisticsBoard locationId={locationId} embedId={currentEmbedId} filters={currentFilters} />
          </div>
        );
        break;
      default:
        break;
    }

    return (
      <Row
        gutter={[24, 24]}
        className={styles.cardsContainer}
        key={widget.id}
        ref={widgetRefs.current[widget.id]}
        id={widget.id}
      >
        <Col xs={24}>
          <Card
            className={styles.dashboardCard}
            title={
              <div className={styles.cardTitle}>
                <span>
                  {widgetIcon} {widget.title}
                </span>

                <div className={styles.cardActions}>
                  <Button
                    type="text"
                    icon={fullscreenIcon}
                    onClick={() => openDetailedView(widget.id, widget.title, detailedViewEmbedId, currentFilters)}
                    className={styles.detailedViewButton}
                  >
                    See Detailed View
                  </Button>
                </div>
              </div>
            }
          >
            {content}
          </Card>
        </Col>
      </Row>
    );
  };

  // Function to scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Icons for navigation buttons
  const pieChartIcon = <PieChartOutlined />;
  const areaChartIcon = <AreaChartOutlined />;
  const upIcon = <UpOutlined />;

  return (
    <div className={styles.dashboardContainer}>
      <div className={styles.dashboardHeader}>
        <div className={styles.headerContent}>
          <div>
            <Title level={2}>SmartAIM</Title>
            <Text type="secondary">Advanced Infrastructure Monitoring - Analyze your building performance metrics</Text>
          </div>
        </div>

        <Modal
          title={detailedView.title}
          open={detailedView.visible}
          onCancel={closeDetailedView}
          footer={null}
          width="80%"
          className={styles.detailedViewModal}
          centered
        >
          <div className={styles.detailedViewContent}>
            <SmartAIMHolisticsBoard
              locationId={locationId}
              embedId={detailedView.embedId}
              filters={detailedView.filters}
            />
          </div>
        </Modal>
      </div>

      {/* Render all widgets */}
      {widgets.map((widget) => renderWidget(widget))}

      {/* Navigation buttons */}
      <Affix style={{ position: "fixed", bottom: 24, right: 24 }}>
        <div className={styles.floatButtonGroup}>
          {widgets.map((widget) => (
            <Tooltip key={widget.id} title={widget.title} placement="left">
              <Button
                onClick={() => scrollToWidget(widget.id)}
                className={styles.navButton}
                shape="circle"
                icon={widget.key === "currentStatistics" ? pieChartIcon : areaChartIcon}
              />
            </Tooltip>
          ))}
          <Tooltip title="Back to top" placement="left">
            <Button onClick={scrollToTop} className={styles.navButton} shape="circle" icon={upIcon} />
          </Tooltip>
        </div>
      </Affix>
    </div>
  );
};

export default SmartAIM;
