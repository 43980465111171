import logger from "../logger/logger";

/**
 * Formats a date as YYYY-MM-DD string for database
 */
export const formatDateForDB = (date: Date | string | undefined): string => {
  // Handle undefined or null values
  if (date === undefined || date === null) {
    logger.warn("Received undefined or null date, using current date as fallback");
    return new Date().toISOString().split("T")[0]; // Use current date as fallback
  }

  // If it's already a string, check if it's already in YYYY-MM-DD format
  if (typeof date === "string") {
    // If it's already in YYYY-MM-DD format, return it as is
    if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
      return date;
    }
    // Otherwise, try to parse it as a date and format it
    try {
      const parsedDate = new Date(date);
      if (!Number.isNaN(parsedDate.getTime())) {
        return parsedDate.toISOString().split("T")[0];
      }

      logger.warn(`Invalid date string: ${date}, using current date as fallback`);
      return new Date().toISOString().split("T")[0]; // Fallback to current date for invalid strings
    } catch (error) {
      logger.error(`Error parsing date string: ${date}`, error);
      return new Date().toISOString().split("T")[0]; // Fallback to current date on error
    }
  }

  // If it's a Date object, format it
  if (date instanceof Date) {
    if (!Number.isNaN(date.getTime())) {
      return date.toISOString().split("T")[0];
    }

    logger.warn(`Invalid Date object, using current date as fallback`);
    return new Date().toISOString().split("T")[0]; // Fallback to current date for invalid Date objects
  }

  // If it's neither a string nor a Date, log an error and return a fallback
  logger.error(`Invalid date value: ${date}, type: ${typeof date}`);
  return new Date().toISOString().split("T")[0]; // Fallback to current date
};

/**
 * Gets default consumption period dates (first and last day of current month)
 */
export const getDefaultConsumptionPeriodDates = () => {
  const today = new Date();
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  return {
    start: formatDateForDB(startOfMonth),
    end: formatDateForDB(endOfMonth),
  };
};

/**
 * Creates date objects for a specific month and year
 */
export const getDateRangeFromMonthYear = (month: string | number, year: string | number) => {
  try {
    const monthNum = parseInt(month.toString(), 10) - 1; // JS months are 0-indexed
    const yearNum = parseInt(year.toString(), 10);

    if (!Number.isNaN(monthNum) && !Number.isNaN(yearNum) && monthNum >= 0 && monthNum < 12) {
      // Create first and last day of month
      const startDate = new Date(Date.UTC(yearNum, monthNum, 1));
      const endDate = new Date(Date.UTC(yearNum, monthNum + 1, 0));

      return {
        start: formatDateForDB(startDate),
        end: formatDateForDB(endDate),
      };
    }

    logger.warn(`Invalid month/year values: month=${month}, year=${year}`);
    return getDefaultConsumptionPeriodDates();
  } catch (err) {
    logger.error(`Error creating dates from month/year: ${err}`);
    return getDefaultConsumptionPeriodDates();
  }
};

/**
 * Parse a value from a survey response field
 * Handles various formats and returns a safe number or null
 */
export const parseConsumptionValue = (value: any): number | null => {
  if (value === undefined || value === null || value === "") {
    return null;
  }

  try {
    // Ensure we have a string
    let valueStr = String(value).trim();
    // Remove commas and other non-numeric characters except decimal points
    valueStr = valueStr.replace(/[^\d.-]/g, "");
    const parsedValue = parseFloat(valueStr);

    if (Number.isNaN(parsedValue)) {
      logger.warn(`Failed to parse value: ${value} (processed as: ${valueStr})`);
      return null;
    }

    return parsedValue;
  } catch (error) {
    logger.error(`Error parsing value '${value}':`, error);
    return null;
  }
};

/**
 * Validate the required user fields in a survey response
 */
export const validateUserFields = (surveyResponse: any): void => {
  const { email, first_name: firstName, last_name: lastName } = surveyResponse;

  if (!email) {
    throw new Error("Email is required");
  }

  if (!firstName && !lastName) {
    throw new Error("At least one name field (first name or last name) is required");
  }
};

/**
 * Check if an error is related to a user already existing
 */
export const isUserExistsError = (error: Error): boolean => {
  const errorMessage = error.message;
  return errorMessage.includes("email") && errorMessage.includes("already exists");
};

/**
 * Check if an error is related to a location already existing
 */
export const isLocationExistsError = (error: Error): boolean => {
  const errorMessage = error.message;

  // Check different patterns of location existence errors
  return (
    errorMessage.match(/A location with the name "[^"]+" already exists/) !== null ||
    (errorMessage.includes("location") && errorMessage.includes("already exists")) ||
    (errorMessage.includes("Location") && errorMessage.includes("already exists")) ||
    errorMessage.includes("Failed to create location") ||
    (errorMessage.includes("Uniqueness violation") && errorMessage.includes("locations_name_k")) ||
    (errorMessage.includes("constraint-violation") && errorMessage.includes("locations"))
  );
};

/**
 * Simple metric type mapping (column name to metric type ID)
 */
export const metricTypeMapping = {
  electricity_consumption: 1,
  electricity_consumption_cost: 2,
  sub_metered_chiller_reading: 3,
  water_consumption: 4,
  water_consumption_cost: 5,
  gas_consumption: 6,
  gas_consumption_cost: 9,
  waste_consumption: 7,
  waste_consumption_cost: 8,
};
