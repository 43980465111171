import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Modal, Row, Select, message } from "antd";
import ActionButton from "components/ActionButton";
import EditableTable from "components/EditableTable/EditableTable";
import ConfirmDeleteModal, { ConfirmDeleteModalProps } from "components/Modal/ConfirmDeleteModal";
import showModal from "components/Modal/show";
import errorHandler from "errorHandler";
import {
  GetFloorsToLevelsQuery,
  GetGroupsForDropdownQuery,
  GetLevelsGroupQuery,
  Sensorflow_Smplrspace_Floors_To_Levels_Constraint,
  useDeleteFloorsToLevelsMutation,
  useGetFloorsToLevelsQuery,
  useGetLevelsGroupQuery,
  useSaveFloorsToLevelsMutation,
  useUpdateFloorsToLevelsMutation,
} from "pacts/app-webcore/hasura-webcore.graphql";
import * as React from "react";
import { useMemo, useState } from "react";

type FloorsToLevelsModalProps = {
  showFloorsToLevelsModal: boolean;
  hideFloorsToLevelsModal: any;
  groupNames: GetGroupsForDropdownQuery["positions"];
  smplrspaceId: string;
};

interface FloorsToLevelsPayload {
  groupName: string;
  positionId: string;
}

const FloorsToLevelsModal = (props: FloorsToLevelsModalProps) => {
  const { showFloorsToLevelsModal, hideFloorsToLevelsModal, groupNames, smplrspaceId } = props;
  const [floorsToLevels, setFloorsToLevels] =
    useState<GetFloorsToLevelsQuery["sensorflow_smplrspace_floors_to_levels"]>();
  const [floorGroup, setFloorGroup] = useState<GetLevelsGroupQuery["sensorflow_smplrspace_levels_group"]>();
  const [dataTable, setDataTable] = useState<any[]>([]);

  const [positionIdList, setPositionIdList] = useState<any[]>([]);
  const [addFloorsToLevels, setAddFloorsToLevels] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record: Record<string, any>) => record.key === editingKey;
  const [form] = Form.useForm();
  const [createForm] = Form.useForm();
  const [addGroupsWithoutLevels, setAddGroupsWithoutLevels] = useState<any[]>([]);

  const { data, loading, refetch } = useGetFloorsToLevelsQuery({
    variables: {
      positionIds: positionIdList,
    },
  });

  useMemo(() => {
    if (data) {
      setFloorsToLevels(data.sensorflow_smplrspace_floors_to_levels);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const { data: floorGroupData, loading: loadingFloorGroup } = useGetLevelsGroupQuery({
    variables: {
      spaceId: smplrspaceId,
    },
  });

  useMemo(() => {
    if (floorGroupData) {
      const tempData: any[] = [];
      floorGroupData.sensorflow_smplrspace_levels_group.map((lg) => {
        tempData.push({
          ...lg,
          key: lg.groupId,
        });
        return true;
      });
      setFloorGroup(tempData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floorGroupData]);

  const edit = (record: Record<string, any> & { key: React.Key }) => {
    form.setFieldsValue({
      groupName: undefined,
      ...record,
    });
    setEditingKey(record.key as string);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const [insertFloorsToLevels] = useSaveFloorsToLevelsMutation({
    onError: errorHandler.handleError,
  });

  const [removeFloorsToLevels] = useDeleteFloorsToLevelsMutation({
    onError: errorHandler.handleError,
  });

  const [editFloorsToLevels] = useUpdateFloorsToLevelsMutation({
    onError: errorHandler.handleError,
  });

  const saveFloorsToLevels = async () => {
    const response = await insertFloorsToLevels({
      variables: {
        mapFloorsToLevels: [
          {
            group_id: createForm.getFieldValue("levelsGroup"),
            position_id: createForm.getFieldValue("positionId"),
          },
        ],
        constraint: Sensorflow_Smplrspace_Floors_To_Levels_Constraint.SmplrspaceFloorsToLevelsPkey,
      },
    });

    if (!response) return;
    message.success("Successfully added building floor plan");
    setAddFloorsToLevels(false);
    createForm.setFieldsValue({
      positionId: undefined,
      levelsGroup: undefined,
    });
    setFloorsToLevels([]);
    refetch();
  };

  const deleteFloorsToLevels = async (positionIdInput: string) => {
    const response = await removeFloorsToLevels({
      variables: {
        filter: [
          {
            position_id: {
              _eq: positionIdInput,
            },
          },
        ],
      },
    });

    if (!response) return;
    if (response.data?.delete_sensorflow_smplrspace_floors_to_levels?.affected_rows === 1) {
      message.success("Building floor plan deleted successfully");
    } else {
      message.error("Failed to delete building floor plan");
    }
    setFloorsToLevels([]);
    refetch();
  };

  const updateFloorsToLevels = async (positionIdInput: string) => {
    const row = (await form.validateFields()) as FloorsToLevelsPayload;
    const response = await editFloorsToLevels({
      variables: {
        positionId: positionIdInput,
        groupId: row.groupName,
      },
    });
    if (!response) return;
    if (response.data?.update_sensorflow_smplrspace_floors_to_levels?.affected_rows === 1) {
      message.success("Building floor plan updated successfully");
    } else {
      message.error("Failed to update building floor plan");
    }
    setFloorsToLevels([]);
    refetch();
    cancel();
  };

  const columnConfig = [
    {
      title: "Building Floor",
      responsive: ["sm"],
      dataIndex: "positionName",
      key: "positionName",
    },
    {
      title: "Floor Plan",
      responsive: ["sm"],
      dataIndex: "groupName",
      key: "groupName",
      editable: true,
      customInput: (
        <Select
          loading={loadingFloorGroup}
          placeholder="Floor Plan"
          showSearch
          filterOption={(input, option) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          allowClear
        >
          <Select.Option value={undefined}>-</Select.Option>
          {floorGroup?.map((option) => (
            <Select.Option key={option.groupId} value={option.groupId}>
              {option.groupName}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Actions",
      width: 150,
      render: (record: Record<string, any> & { key: React.Key }) => {
        const editable = isEditing(record);
        return editable ? (
          <>
            <ActionButton
              type="save"
              onClick={() => {
                updateFloorsToLevels(record.positionId);
              }}
              className="fs-xl mr-m"
              customIcon={
                <CheckCircleOutlined
                  className="fs-xl text-primary"
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                />
              }
            />
            <ActionButton
              type="cancel"
              onClick={() => {
                cancel();
              }}
              className="fs-xl mr-m"
              customIcon={
                <CloseCircleOutlined
                  className="fs-xl text-danger"
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                />
              }
            />
          </>
        ) : (
          <>
            <ActionButton
              type="edit"
              onClick={() => {
                edit(record);
              }}
              className="fs-xl mr-m"
            />
            <ActionButton
              type="delete"
              onClick={() => {
                showModal<ConfirmDeleteModalProps>({
                  element: ConfirmDeleteModal,
                  config: {
                    popupTitle: "Delete building floor plan",
                    fieldPlaceholder: "Enter Position ID",
                    popupOkText: "Confirm",
                    onOk: () => {
                      deleteFloorsToLevels(record.key);
                    },
                    confirmText: record.positionId,
                    message: `Are you sure you want to delete building floor plan configuration? Group: ${record.groupName} | Position: ${record.positionName} - ${record.positionId}?`,
                    fieldLabel: "Key in Position ID to confirm",
                  },
                });
              }}
              className="fs-xl mr-m"
            />
          </>
        );
      },
    },
  ];

  useMemo(() => {
    if (groupNames.length !== 0) {
      const tempData: any[] = [];
      groupNames?.map((group) => {
        tempData.push(group.positionId);
        return true;
      });
      setPositionIdList(tempData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupNames, floorGroup]);

  useMemo(() => {
    const tempData: any[] = [];
    const groupsWithoutLevels: any[] = [];
    if (floorsToLevels) {
      groupNames?.map((g) => {
        const groupDetails = floorsToLevels?.find((s) => s.position_id === g.positionId);
        if (groupDetails) {
          tempData.push({
            positionName: g.positionName,
            groupName: groupDetails.levelsGroup?.groupName,
            levelIndex: groupDetails.levelsGroup?.levelIndex,
            key: groupDetails.position_id,
            positionId: groupDetails.position_id,
            groupId: groupDetails.group_id,
          });
        } else {
          groupsWithoutLevels.push({
            positionId: g.positionId,
            positionName: g.positionName,
          });
        }
        return true;
      });
    }
    setDataTable(tempData);
    setAddGroupsWithoutLevels(groupsWithoutLevels);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floorsToLevels, floorGroup]);

  return (
    <>
      <Modal
        title="Building Floor Plan"
        open={showFloorsToLevelsModal}
        onCancel={hideFloorsToLevelsModal}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        width={700}
      >
        {!addFloorsToLevels && (
          <>
            {addGroupsWithoutLevels.length > 0 && (
              <Button type="primary" className="mb-m" onClick={() => setAddFloorsToLevels((prev) => !prev)}>
                Add Building Floor Plan
              </Button>
            )}
          </>
        )}
        {addFloorsToLevels && addGroupsWithoutLevels.length > 0 ? (
          <div className="flex vertical space-between">
            <Form form={createForm}>
              <Row className="mb-m" gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item name="positionId" label="Group Name" rules={[{ required: true }]}>
                    <Select
                      style={{ width: 170 }}
                      placeholder="Building Floor"
                      showSearch
                      filterOption={(input, option) =>
                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(a, b) => Number(a.key) - Number(b.key)}
                      allowClear
                    >
                      <Select.Option value={undefined}>-</Select.Option>
                      {addGroupsWithoutLevels?.map((option) => (
                        <Select.Option key={option.positionName} value={option.positionId}>
                          Floor {option.positionName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="levelsGroup" label="Levels Group" rules={[{ required: true }]}>
                    <Select
                      style={{ width: 170 }}
                      placeholder="Floor Plan"
                      showSearch
                      filterOption={(input, option) =>
                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                    >
                      <Select.Option value={undefined}>-</Select.Option>
                      {floorGroup?.map((option) => (
                        <Select.Option key={option.groupId} value={option.groupId}>
                          {option.groupName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Row className="mb-none">
              <Button type="primary" className="mr-xs" onClick={saveFloorsToLevels}>
                Save
              </Button>
              <Button onClick={() => setAddFloorsToLevels(false)}>Cancel</Button>
            </Row>
          </div>
        ) : (
          <EditableTable
            form={form}
            columns={columnConfig}
            tableData={dataTable}
            editingKey={editingKey}
            setEditingKey={setEditingKey}
            loading={loading}
          />
        )}
      </Modal>
    </>
  );
};

export default FloorsToLevelsModal;
