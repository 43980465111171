import { Sf_Support_Active_Property_Alerts_Insert_Input } from "pacts/app-webcore/hasura-webcore.graphql";

// eslint-disable-next-line import/prefer-default-export
export const transformActivePropertyAlertInput = (
  locationId: string,
  activedClasses: number[],
  currentActivedClasses: number[]
): Sf_Support_Active_Property_Alerts_Insert_Input[] => {
  const inactiveClasses = currentActivedClasses
    .filter((ticketClassId) => !activedClasses.includes(ticketClassId))
    .map((ticketClassId) => ({
      locationId,
      ticketClassId,
      active: false,
    }));

  return inactiveClasses.concat(
    activedClasses.map((ticketClassId) => ({
      locationId,
      ticketClassId,
      active: true,
    }))
  );
};

export enum GatewayHardwareTypeMapping {
  "multi_as_single_channel" = "Multi-as-Single",
  "multi_channel" = "Multi-Channel",
  "single_channel" = "Single-Channel",
  "single_as_multi_channel" = "Single-as-Multi",
}
