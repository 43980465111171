import * as XLSX from "xlsx";
import logger from "../logger/logger";

/**
 * Interface for Excel data extraction request
 */
export interface ExcelExtractionRequest {
  /**
   * The content of the Excel file, either as a base64 encoded string or a URL
   */
  base64Content: string;

  /**
   * The name of the Excel file
   */
  fileName: string;

  /**
   * The type of the Excel file (e.g., 'xlsx', 'xls', 'csv')
   */
  fileType: string;
}

/**
 * Interface for Excel data extraction result
 */
export interface ExcelExtractionResult {
  /**
   * Column headers
   */
  columns: string[];

  /**
   * Data rows
   */
  rows: any[][];

  /**
   * Update a cell value
   * @param rowIndex Row index (0-based)
   * @param colIndex Column index (0-based)
   * @param value New value
   * @returns Updated result
   */
  updateCell(rowIndex: number, colIndex: number, value: any): ExcelExtractionResult;
}

/**
 * Service for extracting data from Excel files
 */
class ExcelService {
  /**
   * Extract data from an Excel file
   * @param excelData The Excel file data
   * @returns The extracted data as a JSON object
   * @throws Error if the file cannot be parsed or contains no data
   */
  async extractDataFromExcel(excelData: ExcelExtractionRequest): Promise<ExcelExtractionResult> {
    try {
      // Check if the content is a URL
      const isUrl =
        excelData.base64Content.startsWith("http://") ||
        excelData.base64Content.startsWith("https://") ||
        excelData.base64Content.startsWith("www.");

      if (isUrl) {
        // Handle URL-based Excel files
        logger.error("URL-based Excel files are not supported.");
        throw new Error("URL-based Excel files are not supported. Please provide a file upload instead.");
      }

      // Convert base64 to binary
      const binary = this.base64ToArrayBuffer(excelData.base64Content);

      // Parse the Excel file
      const workbook = XLSX.read(binary, { type: "array" });

      // Get the first sheet
      const firstSheetName = workbook.SheetNames[0];
      if (!firstSheetName) {
        logger.error("No sheets found in Excel file.");
        throw new Error("The Excel file does not contain any sheets.");
      }

      const worksheet = workbook.Sheets[firstSheetName];

      // Convert to JSON with raw: false to get formatted dates
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        raw: false,
        dateNF: "yyyy-mm-dd", // Format dates as YYYY-MM-DD
      });

      // Extract columns and rows
      if (!jsonData || jsonData.length === 0) {
        logger.error("No data found in Excel file.");
        throw new Error("The Excel file does not contain any data.");
      }

      // First row is the header
      const columns = jsonData[0] as string[];

      if (!columns || columns.length === 0) {
        logger.error("No column headers found in Excel file.");
        throw new Error("The Excel file does not contain column headers.");
      }

      // Rest are data rows
      const rows = jsonData.slice(1) as any[][];

      if (rows.length === 0) {
        logger.error("No data rows found in Excel file.");
        throw new Error("The Excel file does not contain any data rows.");
      }

      // Process and format the data
      const processedRows = rows.map((row) => row.map((cell) => this.formatCellValue(cell)));

      return this.createExtractionResult(columns, processedRows);
    } catch (error) {
      logger.error("Error processing Excel file:", error);

      // Rethrow the error with a more user-friendly message if it's not already an Error object
      if (error instanceof Error) {
        throw error;
      } else {
        throw new Error("Failed to process the Excel file. Please check the file format and try again.");
      }
    }
  }

  /**
   * Format a cell value
   * @param value The cell value
   * @returns Formatted value
   */
  private formatCellValue(value: any): any {
    if (value === undefined || value === null) {
      return "";
    }

    // Check if it's a date
    if (value instanceof Date) {
      return this.formatDate(value);
    }

    // Check if it's a date string (e.g., "2023-01-01")
    if (typeof value === "string" && value.match(/^\d{4}-\d{2}-\d{2}$/)) {
      return value; // Already in YYYY-MM-DD format
    }

    // Check if it's a date string in other formats
    if (typeof value === "string") {
      const timestamp = Date.parse(value);
      if (!Number.isNaN(timestamp)) {
        return this.formatDate(new Date(timestamp));
      }
    }

    return value;
  }

  /**
   * Format a date as YYYY-MM-DD
   * @param date The date to format
   * @returns Formatted date string
   */
  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  /**
   * Convert base64 string to ArrayBuffer
   * @param base64Input The base64 string
   * @returns ArrayBuffer
   */
  private base64ToArrayBuffer(base64Input: string): ArrayBuffer {
    try {
      // Remove data URL prefix if present
      let base64Content = base64Input;
      if (base64Content.includes("base64,")) {
        const [, content] = base64Content.split("base64,");
        base64Content = content;
      }

      // Decode base64 to binary string
      const binaryString = window.atob(base64Content);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);

      // Convert binary string to ArrayBuffer
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      return bytes.buffer;
    } catch (error) {
      logger.error("Error converting base64 to ArrayBuffer:", error);
      throw new Error("Invalid file format. The file could not be decoded.");
    }
  }

  /**
   * Create an ExcelExtractionResult object
   * @param columns Column headers
   * @param rows Data rows
   * @returns ExcelExtractionResult
   */
  private createExtractionResult(columns: string[], rows: any[][]): ExcelExtractionResult {
    const result: ExcelExtractionResult = {
      columns,
      rows,
      updateCell: (rowIndex: number, colIndex: number, value: any) => {
        // Create a deep copy of the rows
        const updatedRows = [...rows.map((row) => [...row])];

        // Update the cell value
        if (
          rowIndex >= 0 &&
          rowIndex < updatedRows.length &&
          colIndex >= 0 &&
          colIndex < updatedRows[rowIndex].length
        ) {
          updatedRows[rowIndex][colIndex] = value;
        }

        // Return a new result object
        return this.createExtractionResult(columns, updatedRows);
      },
    };

    return result;
  }
}

export default new ExcelService();
