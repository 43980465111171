import { SettingOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Breadcrumb, Modal, Select, Tabs, message } from "antd";
import Loading from "components/Loading";
import { WarningUseMobileOnly } from "components/WarningUseMobileOnly";
import { useErrorHandler } from "errorHandler";
import useBreakpoint from "hooks/use-breakpoint";
import { useFeatureFlag } from "hooks/use-feature-flag";
import useMenuNavigation from "hooks/use-menu-navigation";
import useRoleAndPermission from "hooks/use-role-and-permission";
import useSensorflowLocation from "hooks/use-sensorflow-location";
import {
  useGetDefaultHomepageQuery,
  useLocationMetadataLazyQuery,
  useLocationQuery,
  useUpdateDefaultHomepageMutation,
} from "pacts/app-webcore/hasura-webcore.graphql";
import { Permission } from "pacts/permission";
import InsightsDashboardList from "pages/Analytics/Analytics";
import GatewayList from "pages/Gateway/GatewayList/GatewayList";
import { Groups } from "pages/Groups";
import Infrastructures from "pages/Infrastructures/Infrastructures";
import InfrastructuresV2 from "pages/Infrastructures/InfrastructuresV2";
import { Jobs } from "pages/Jobs";
import ContractorKeyList from "pages/Key/ContractorKeyList/KeyList";
import CustomerKeyListPage from "pages/Key/CustomerKeyList/KeyList";
import KeyListPage from "pages/Key/KeyList/KeyList";
import KeyCategoryListPage from "pages/KeyCategory/KeyCategoryList";
import MapVisualization from "pages/MapVisualization/MapVisualization";
import { ContractorNodeListKey } from "pages/Node/ContractorNodeListKey";
import NodeList from "pages/Node/NodeList/NodeList";
import SmartAIM from "pages/SmartAIM/SmartAIM";
import SmartGRD from "pages/SmartGRD/SmartGRD";
import { SpareNodes } from "pages/SpareNodes";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { EFeatureFlags } from "utils/constants";
import EnergyConsumption from "../../EnergyConsumption/EnergyConsumption";
import KeyConfigurationList from "../../Key/KeyConfigurationList/KeyConfigurationList";
import SmartBEA from "../../SmartBEA/SmartBEAClientView/SmartBEA";
import "./LocationDetails.scss";

import { ActiveTab } from "./LocationDetails.d";

const keyListWrapper = (roleAndPermission: ReturnType<typeof useRoleAndPermission>) => {
  if (roleAndPermission.isContractor()) return <ContractorKeyList />;
  if (roleAndPermission.isCustomer()) return <CustomerKeyListPage />;
  return <KeyListPage />;
};

const canAccessNodeSpareSetList = (roleAndPermission: ReturnType<typeof useRoleAndPermission>) => {
  return roleAndPermission.isWS() || roleAndPermission.isContractor() || roleAndPermission.isPC();
};

const getDefaultActiveTab = (roleAndPermission: ReturnType<typeof useRoleAndPermission>) => {
  if (roleAndPermission.isContractor()) return ActiveTab.JOBS;
  return ActiveTab.KEYS;
};

const LocationDetails = () => {
  const history = useHistory();
  const errorHandler = useErrorHandler();
  const breakpoint = useBreakpoint();
  const { locationId, activeTab } = useParams<{ locationId: string; activeTab: string }>();
  const [locationName, setLocationName] = useState<string>();
  const [showEnergyConsumption, setShowEnergyConsumption] = useState<boolean>();
  const roleAndPermission = useRoleAndPermission();
  const menuNavigation = useMenuNavigation();
  const { updateCompressorLocationEnabled } = useFeatureFlag();
  const { location } = useSensorflowLocation();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [defaultHomepage, setDefaultHomepage] = useState<string>();
  const { user } = useAuth0();

  const rebuildLocationMenu = (id: string, name: string) => {
    if (id && name) menuNavigation.setupLocationMenu([{ id, locationName: name }]);
  };

  const defaultActiveTab = useMemo(() => getDefaultActiveTab(roleAndPermission), [roleAndPermission]);

  const { loading } = useLocationQuery({
    variables: { locationId },
    onCompleted: (data) => {
      setLocationName(data.location?.locationName);
      rebuildLocationMenu(locationId, data.location?.locationName!);
    },
    onError: errorHandler.handleErrorWithStatusCode,
  });

  useGetDefaultHomepageQuery({
    variables: {
      locationId,
      userId: user?.sub as string,
    },
    onCompleted: (data) => {
      setDefaultHomepage(data.sensorflow_location_to_user_mapping?.[0]?.default_homepage || "keys");
    },
  });

  const [locationMetadataLazyQuery] = useLocationMetadataLazyQuery({
    variables: { locationId },
    onCompleted: (data) => {
      setShowEnergyConsumption(!!data.locationMetadata?.showEnergyConsumption);
    },
    onError: errorHandler.handleErrorWithStatusCode,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (roleAndPermission.isCustomer()) locationMetadataLazyQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shouldShowWarningForContractorOnDesktop = useMemo<boolean>(() => {
    return roleAndPermission.isContractor() && !breakpoint.mobileAndTabletOnly;
  }, [roleAndPermission, breakpoint.mobileAndTabletOnly]);

  const handleTabChange = (activeKey: string) => {
    history.push(`/locations/${locationId}/${activeKey}`);
  };

  const [updateDefaultHomepage] = useUpdateDefaultHomepageMutation();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleSaveDefaultHomepage = async () => {
    const response = await updateDefaultHomepage({
      variables: {
        locationId,
        userId: user?.sub as string,
        defaultHomepage: defaultHomepage as string,
      },
    });
    if (!response) return;
    if (response.data?.update_sensorflow_location_to_user_mapping?.affected_rows === 1) {
      message.success("Default homepage updated successfully");
    } else {
      message.error("Failed to update default homepage");
    }
    setIsModalVisible(false);
    setDefaultHomepage(defaultHomepage);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDefaultHomepageChange = (value: string) => {
    setDefaultHomepage(value);
  };

  const shouldShowSettingsButton = !!locationId;

  const tabConfigs = [
    {
      name: "Keys",
      key: ActiveTab.KEYS,
      component: keyListWrapper(roleAndPermission),
      isVisible: !roleAndPermission.isContractor(),
    },
    {
      name: "Categories",
      key: ActiveTab.CATEGORIES,
      component: <KeyCategoryListPage locationName={locationName as string} />,
      isVisible: roleAndPermission.canPerform(Permission.LOCATION_CREATE),
    },
    {
      name: "Jobs",
      key: ActiveTab.JOBS,
      component: <Jobs />,
      isVisible: breakpoint.mobileAndTabletOnly && roleAndPermission.isContractor(),
    },

    {
      name: "Gateways",
      key: ActiveTab.GATEWAYS,
      component: shouldShowWarningForContractorOnDesktop ? (
        <WarningUseMobileOnly />
      ) : (
        <GatewayList locationName={locationName as string} />
      ),
      isVisible: !roleAndPermission.isCustomer(),
    },
    {
      name: "Configuration",
      key: ActiveTab.CONFIGURATION,
      component: <KeyConfigurationList />,
      isVisible: roleAndPermission.isCustomer(),
    },
    {
      name: "Nodes",
      key: ActiveTab.NODES,
      component: roleAndPermission.isContractor() ? <ContractorNodeListKey /> : <NodeList />,
      isVisible: roleAndPermission.canPerform(Permission.NODES_LIST) && !roleAndPermission.isContractor(),
    },
    {
      name: "Infrastructures",
      key: ActiveTab.INFRASTRUCTURES,
      component: shouldShowWarningForContractorOnDesktop ? (
        <WarningUseMobileOnly />
      ) : (
        <>
          {roleAndPermission.isPC() && updateCompressorLocationEnabled ? (
            <InfrastructuresV2 locationName={locationName} />
          ) : (
            <Infrastructures locationName={locationName as string} />
          )}
        </>
      ),
      isVisible: roleAndPermission.isPC(),
    },
    {
      name: "Energy Consumption",
      key: ActiveTab.ENERGY_CONSUMPTION,
      component: <EnergyConsumption />,
      isVisible: showEnergyConsumption,
    },
    {
      name: "Spare Nodes",
      key: ActiveTab.SPARE_NODES,
      component: <SpareNodes locationId={locationId} locationName={locationName} />,
      isVisible: canAccessNodeSpareSetList(roleAndPermission),
    },
    {
      name: "Groups",
      key: ActiveTab.GROUPS,
      component: <Groups locationId={locationId} />,
      isVisible: roleAndPermission.isPC(),
    },
    {
      name: "Analytics",
      key: ActiveTab.ANALYTICS,
      component: <InsightsDashboardList locationId={locationId} />,
      isVisible:
        (roleAndPermission.isPC() ||
          (roleAndPermission.isCustomer() && (location?.enabledFeatures ?? []).find((f) => f === "DomoEmbed"))) &&
        !breakpoint.mobileOnly,
    },
    {
      name: "Map",
      key: ActiveTab.MAP,
      component: <MapVisualization />,
      isVisible:
        roleAndPermission.isPC() ||
        (roleAndPermission.isCustomer() &&
          (location?.enabledFeatures ?? []).find((f) => f === EFeatureFlags.MapVisualization)),
    },
    {
      name: "SmartAIM",
      key: ActiveTab.SMARTAIM,
      component: <SmartAIM locationName={locationName as string} />,
      isVisible:
        roleAndPermission.isPC() ||
        (roleAndPermission.isCustomer() && (location?.enabledFeatures ?? []).find((f) => f === EFeatureFlags.SmartAIM)),
    },
    {
      name: "SmartBEA",
      key: ActiveTab.SMART_BEA,
      component: <SmartBEA locationId={locationId} />,
      isVisible:
        roleAndPermission.isPC() ||
        (roleAndPermission.isCustomer() && (location?.enabledFeatures ?? []).includes(EFeatureFlags.SmartBEA)),
    },
    {
      name: "SmartGRD",
      key: ActiveTab.SMARTGRD,
      component: <SmartGRD />,
      isVisible:
        roleAndPermission.isPC() ||
        (roleAndPermission.isCustomer() && (location?.enabledFeatures ?? []).find((f) => f === EFeatureFlags.SmartGRD)),
    },
  ];

  const tabs = tabConfigs.filter(({ isVisible }) => isVisible);

  return loading ? (
    <Loading />
  ) : (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Breadcrumb className="header-bread-crumb">
          <Breadcrumb.Item>
            <Link to="/locations">Location</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{locationName}</Breadcrumb.Item>
        </Breadcrumb>
        {shouldShowSettingsButton && roleAndPermission.isCustomer() && (
          <div
            onClick={showModal}
            className="settings-button"
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter") showModal();
            }}
            aria-label="Settings"
          >
            <SettingOutlined />
          </div>
        )}
      </div>

      <Modal
        title="Default Homepage"
        open={isModalVisible}
        onOk={handleSaveDefaultHomepage}
        onCancel={handleCancel}
        okText="Save"
      >
        <p>Select your default homepage for this location:</p>
        <Select style={{ width: "100%" }} value={defaultHomepage} onChange={handleDefaultHomepageChange}>
          {tabs.map((tab) => (
            <Select.Option key={tab.key} value={tab.key}>
              {tab.name}
            </Select.Option>
          ))}
        </Select>
      </Modal>

      <Tabs className="header-tab-bar" activeKey={activeTab ?? defaultActiveTab} onChange={handleTabChange}>
        {tabs.map((tab) => (
          <Tabs.TabPane tab={tab.name} key={tab.key}>
            {tab.component}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default LocationDetails;
