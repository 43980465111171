import { Form, Input, InputNumber, Table, TableProps } from "antd";
import { FormInstance } from "antd/es/form";
import React, { Dispatch, ReactNode, SetStateAction, useEffect, useState } from "react";

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text" | "checkbox";
  record: Record<string, any>;
  index: number;
  customInput?: ReactNode;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  customInput,
  ...restProps
}) => {
  const getInputNode = (type: string) => {
    switch (type) {
      case "number":
        return <InputNumber />;
      case "checkbox":
        return <Input type="checkbox" />;
      default:
        return <Input />;
    }
  };

  const inputNode = getInputNode(inputType);

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }}>
          {customInput || inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditableTable = ({
  form,
  columns,
  tableData,
  editingKey,
  setEditingKey,
  loading,
}: {
  form: FormInstance;
  columns: any;
  tableData: Record<string, any>[];
  editingKey: string;
  setEditingKey: Dispatch<SetStateAction<string>>;
  loading: boolean;
}) => {
  const [data, setData] = useState<Record<string, any>[]>(tableData);

  const isEditing = (record: Record<string, any>) => record.key === editingKey;

  useEffect(() => setData(tableData), [tableData]);

  const cancel = () => {
    setEditingKey("");
  };

  const mergedColumns: TableProps<Record<string, any>>["columns"] = columns.map((col: any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Record<string, any>) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        customInput: col.customInput,
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table<Record<string, any>>
        components={{
          body: { cell: EditableCell },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        scroll={{ x: 400, y: 300 }}
        pagination={{ onChange: cancel }}
        loading={loading}
      />
    </Form>
  );
};

export default EditableTable;
