/**
 * Utility functions for handling Excel files
 */

/**
 * Extract Excel content from a JSON object containing file data
 * @param jsonData The JSON object containing file data
 * @returns The extracted content (base64 or URL), file name, and file type
 */
const extractExcelBase64FromJson = (
  jsonData: any
): {
  base64Content: string;
  fileName: string;
  fileType: string;
} | null => {
  try {
    // Find a field that contains file data
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const fileDataFields = findFileDataFields(jsonData);

    if (fileDataFields.length === 0) {
      return null;
    }

    // Get the first file data entry
    const fileData = fileDataFields[0];

    // Check if it has the required properties
    if (!fileData.name || !fileData.type || !fileData.content) {
      return null;
    }

    // Check if the content is a URL
    const isUrl =
      fileData.content.startsWith("http://") ||
      fileData.content.startsWith("https://") ||
      fileData.content.startsWith("www.");

    let content;

    if (isUrl) {
      // If it's a URL, use it directly
      content = fileData.content;
    } else {
      // For the format: data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,UEsDBBQA...
      const base64Marker = "base64,";
      const base64Index = fileData.content.indexOf(base64Marker);

      if (base64Index !== -1) {
        // Extract just the base64 part
        content = fileData.content.substring(base64Index + base64Marker.length);
      } else {
        // If we couldn't extract base64 content, use the original content
        content = fileData.content;
      }
    }

    return {
      base64Content: content,
      fileName: fileData.name,
      fileType: fileData.type,
    };
  } catch (error) {
    // Log error to error state instead of console
    return null;
  }
};

/**
 * Find all fields in the JSON that contain file data
 * @param jsonData The JSON object to search
 * @returns Array of file data objects
 */
const findFileDataFields = (jsonData: any): Array<{ name: string; type: string; content: string }> => {
  const fileDataFields: Array<{ name: string; type: string; content: string }> = [];

  // First, specifically check for location_consumption field
  if (
    jsonData.location_consumption &&
    Array.isArray(jsonData.location_consumption) &&
    jsonData.location_consumption.length > 0
  ) {
    // Check each item in the array to see if it has the required properties
    jsonData.location_consumption.forEach((item: { name: string; type: string; content: string }) => {
      if (item.name && item.type && item.content) {
        // Check if it's an Excel file type
        const isExcelType =
          item.type.includes("excel") ||
          item.type.includes("spreadsheet") ||
          item.type.includes("xls") ||
          item.name.endsWith(".xlsx") ||
          item.name.endsWith(".xls");

        if (isExcelType) {
          fileDataFields.push(item);
        }
      }
    });
  }

  // If no files found in location_consumption, check other common field names
  if (fileDataFields.length === 0) {
    // Check for common field names that might contain file data
    const possibleFieldNames = ["hotel_data", "file_data", "excel_data", "data", "files", "attachments", "question8"];

    possibleFieldNames.forEach((fieldName) => {
      if (jsonData[fieldName] && Array.isArray(jsonData[fieldName]) && jsonData[fieldName].length > 0) {
        // Check each item in the array to see if it has the required properties
        jsonData[fieldName].forEach((item) => {
          if (item.name && item.type && item.content) {
            // Check if it's an Excel file type
            const isExcelType =
              item.type.includes("excel") ||
              item.type.includes("spreadsheet") ||
              item.type.includes("xls") ||
              item.name.endsWith(".xlsx") ||
              item.name.endsWith(".xls");

            if (isExcelType) {
              fileDataFields.push(item);
            }
          }
        });
      }
    });
  }

  // If we didn't find any file data in the common fields, try to search recursively
  if (fileDataFields.length === 0) {
    // Look for any object that has name, type, and content properties
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    searchObjectRecursively(jsonData, fileDataFields);
  }

  return fileDataFields;
};

/**
 * Recursively search an object for file data
 * @param obj The object to search
 * @param results Array to store found file data
 */
const searchObjectRecursively = (obj: any, results: Array<{ name: string; type: string; content: string }>): void => {
  if (!obj || typeof obj !== "object") {
    return;
  }

  // Check if this object has the required properties
  if (obj.name && obj.type && obj.content) {
    // Check if it's an Excel file type
    const isExcelType =
      obj.type.includes("excel") ||
      obj.type.includes("spreadsheet") ||
      obj.type.includes("xls") ||
      obj.name.endsWith(".xlsx") ||
      obj.name.endsWith(".xls");

    if (isExcelType) {
      results.push(obj);
      return;
    }
  }
  // Check all properties of this object
  Object.entries(obj).forEach(([, value]) => {
    // If it's an array, check each item
    if (Array.isArray(value)) {
      value.forEach((item) => {
        searchObjectRecursively(item, results);
      });
    }
    // If it's an object, search it recursively
    else if (value && typeof value === "object") {
      searchObjectRecursively(value, results);
    }
  });
};

export default extractExcelBase64FromJson;
