import { Row, message } from "antd";
import errorHandler from "errorHandler";
import { useGetHolisticsTokenQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import React, { useState } from "react";
import styles from "./SmartAIM.module.scss";

export type SmartAIMHolisticsBoardProps = {
  locationId: string;
  embedId: string;
  filters: any;
};

const SmartAIMHolisticsBoard = ({ locationId, embedId, filters }: SmartAIMHolisticsBoardProps) => {
  const [holisticsPayload, setHolisticsPayload] = useState<string>("");

  useGetHolisticsTokenQuery({
    variables: { embedId, locationId, filters: JSON.stringify(filters) },
    onCompleted: (holisticsData) => {
      if (!holisticsData.getHolisticsToken || holisticsData.getHolisticsToken === "") {
        message.error("Unable to generate dashboard token. Please check configuration.");
      }
      setHolisticsPayload(holisticsData.getHolisticsToken ?? "");
    },
    onError: errorHandler.handleError,
  });

  return (
    <div className={styles.holisticsBoardContainer}>
      <Row>
        <iframe
          title="holistics embed"
          src={`https://secure.holistics.io/embed/${embedId}?_token=${holisticsPayload}`}
          id="embedded-iframe"
          width="100%"
          height="1200px"
          style={{ minHeight: "1200px", border: "none" }}
          className={styles.holisticsIframe}
          allowFullScreen
        />
      </Row>
    </div>
  );
};

export default SmartAIMHolisticsBoard;
