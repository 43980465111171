import {
  Sensorflow_Locations_Insert_Input,
  Sensorflow_Organisations_Constraint,
  Sensorflow_Organisations_Update_Column,
  UserInput,
} from "pacts/app-webcore/hasura-webcore.graphql";
import { GATEWAY_DEFAULT_SETTINGS } from "utils/constants";
import { message } from "antd";
import logger from "../logger/logger";
import {
  getDefaultConsumptionPeriodDates,
  getDateRangeFromMonthYear,
  parseConsumptionValue,
  validateUserFields,
  metricTypeMapping,
} from "./surveyUtils";

// Type definitions
interface LocationData {
  organizationName: string;
  locationName: string;
  latitude: number;
  longitude: number;
  timezone: string;
  currency: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  totalSquareFootage: number;
  hospitalityType: string;
  totalGuestRooms: number;
  noOfStudios: number;
  noOf1Bedrooms: number;
  noOf2Bedrooms: number;
  noOf3Bedrooms: number;
  roomsFloorPercentage: number;
  commonAreasFloorPercentage: number;
  restaurantsFloorPercentage: number;
  spasFloorPercentage: number;
  backOfficesFloorPercentage: number;
  otherAreasFloorPercentage: number;
  hvacSystemType: string;
  energyInitiatives: string;
  electricityBillingPeriodStart: number | null;
  electricityBillingPeriodEnd: number | null;
  waterBillingPeriodStart: number | null;
  waterBillingPeriodEnd: number | null;
  gasBillingPeriodStart: number | null;
  gasBillingPeriodEnd: number | null;
  wasteBillingPeriodStart: number | null;
  wasteBillingPeriodEnd: number | null;
  hasEnergyInitiatives: boolean;
  hasOtherHVACSolutions: boolean;
  hvacConsumptionPercentage: number;
  hvacSolutions: string;
}

interface UserData {
  firstName: string;
  lastName: string;
  email: string;
  companyRole: string;
  phoneNumber: string;
}

// Service interface - this will be implemented by a React component using hooks
export interface SurveyProcessorService {
  createLocation: (locationInput: Sensorflow_Locations_Insert_Input) => Promise<any>;
  createUser: (email: string, userInput: UserInput) => Promise<any>;
  createLocationAddress: (addressInput: any) => Promise<any>;
  createPropertyInformation: (propertyInfoInput: any) => Promise<any>;
  createConsumptionRecords: (records: any[]) => Promise<any>;
  updateUserProfile?: (
    userId: string,
    profileData: { firstName: string; lastName: string; companyRole?: string; phoneNumber?: string }
  ) => Promise<any>;
  createSurveyResponse?: (surveyResponseInput: any) => Promise<any>;
  createLocationOccCustomerData?: (occCustomerData: {
    locationId: string;
    occupancy?: number;
    clientForecast?: number;
    periodStart: string;
    periodEnd: string;
  }) => Promise<any>;
  createBulkLocationOccCustomerData?: (
    records: Array<{
      locationId: string;
      occupancy?: number;
      clientForecast?: number;
      periodStart: string;
      periodEnd: string;
    }>
  ) => Promise<any>;
}

/**
 * Extract location data from survey response
 */
function extractLocationDataFromSurvey(surveyResponse: any): LocationData {
  const ensureString = (value: any): string => {
    if (Array.isArray(value)) {
      return value.join(", ");
    }
    return value || "";
  };

  // Helper function to parse values that might be undefined or empty
  const parseFloatOrNull = (value: any): number | null => {
    if (value === undefined || value === null || value === "") {
      return null;
    }
    const parsed = parseFloat(String(value));
    return Number.isNaN(parsed) ? null : parsed;
  };

  return {
    // Basic location information
    organizationName: surveyResponse.organization_name || surveyResponse.company_name || "",
    locationName: surveyResponse.location_name || surveyResponse.property_name || "",
    latitude: parseFloat(surveyResponse.latitude || "0"),
    longitude: parseFloat(surveyResponse.longitude || "0"),
    timezone: surveyResponse.timezone || "UTC",
    currency: surveyResponse.currency || "USD",

    // Address information
    address1: surveyResponse.address_1 || "",
    address2: surveyResponse.address_2 || "",
    city: surveyResponse.city || "",
    state: surveyResponse.state || "",
    zipCode: surveyResponse.zip_code || "",
    country: surveyResponse.country || "",

    // Property information
    totalSquareFootage: parseFloat(surveyResponse.total_square_footage || "0"),
    hospitalityType: surveyResponse.hospitality_type || "",
    totalGuestRooms: parseInt(surveyResponse.total_guest_rooms || "0", 10),
    noOfStudios: parseInt(surveyResponse.no_of_studios || "0", 10),
    noOf1Bedrooms: parseInt(surveyResponse.no_of_1_bedrooms || "0", 10),
    noOf2Bedrooms: parseInt(surveyResponse.no_of_2_bedrooms || "0", 10),
    noOf3Bedrooms: parseInt(surveyResponse.no_of_3_bedrooms || "0", 10),
    roomsFloorPercentage: parseFloat(surveyResponse.rooms_floor_percentage || "0"),
    commonAreasFloorPercentage: parseFloat(surveyResponse.common_areas_floor_percentage || "0"),
    restaurantsFloorPercentage: parseFloat(surveyResponse.restaurants_floor_percentage || "0"),
    spasFloorPercentage: parseFloat(surveyResponse.spas_floor_percentage || "0"),
    backOfficesFloorPercentage: parseFloat(surveyResponse.back_offices_floor_percentage || "0"),
    otherAreasFloorPercentage: parseFloat(surveyResponse.other_areas_floor_percentage || "0"),
    hvacSystemType: ensureString(surveyResponse.hvac_system_type),
    energyInitiatives: ensureString(surveyResponse.energy_initiatives),
    electricityBillingPeriodStart: parseFloatOrNull(surveyResponse.electricity_billing_period_start),
    electricityBillingPeriodEnd: parseFloatOrNull(surveyResponse.electricity_billing_period_end),
    waterBillingPeriodStart: parseFloatOrNull(surveyResponse.water_billing_period_start),
    waterBillingPeriodEnd: parseFloatOrNull(surveyResponse.water_billing_period_end),
    gasBillingPeriodStart: parseFloatOrNull(surveyResponse.gas_billing_period_start),
    gasBillingPeriodEnd: parseFloatOrNull(surveyResponse.gas_billing_period_end),
    wasteBillingPeriodStart: parseFloatOrNull(surveyResponse.waste_billing_period_start),
    wasteBillingPeriodEnd: parseFloatOrNull(surveyResponse.waste_billing_period_end),
    hasEnergyInitiatives: surveyResponse.has_energy_initiatives === true,
    hasOtherHVACSolutions: surveyResponse.has_other_hvac_solutions === true,
    hvacConsumptionPercentage: parseFloat(surveyResponse.hvac_consumption_percentage || "0"),
    hvacSolutions: ensureString(surveyResponse.hvac_solutions),
  };
}

/**
 * Extract user data from survey response
 */
function extractUserDataFromSurvey(surveyResponse: any): UserData {
  return {
    firstName: surveyResponse.first_name || "",
    lastName: surveyResponse.last_name || "",
    email: surveyResponse.email || "",
    companyRole: surveyResponse.company_role || "",
    phoneNumber: surveyResponse.phone_number || "",
  };
}

/**
 * Get role ID for user creation
 */
function determineRoleIdFromCompanyRole(): string {
  // Always return Hotel Viewer role ID
  return "35315961-d3b4-4941-817a-97e610062eed"; // Hotel Viewer
}

/**
 * Get organization ID from location ID
 */
async function getOrganisationIdFromLocationId(): Promise<string> {
  // leaving this here in case we need to use a different organization ID
  // default to Sensorflow organization ID
  return "45f85dbd-7c93-4dcf-adb2-e29c3b2abc96"; // Specific organization ID
}

/**
 * Process consumption records from Excel data
 */
async function processConsumptionRecords(
  surveyResponse: any,
  locationId: string,
  responseId: string | null,
  service: SurveyProcessorService
): Promise<void> {
  try {
    // Skip processing if no Excel data
    if (!surveyResponse.extractedExcelData || !Array.isArray(surveyResponse.extractedExcelData)) {
      logger.warn("No Excel data available for consumption processing");
      return;
    }

    // Filter records with month and year
    const validRecords = surveyResponse.extractedExcelData.filter((record: any) => {
      return record.month !== undefined && record.month !== null && record.year !== undefined && record.year !== null;
    });

    logger.info(`Found ${validRecords.length} records with valid month and year`);

    if (validRecords.length === 0) {
      logger.warn("No valid consumption records found");
      return;
    }
    // Process each valid record
    const consumptionRecords: Array<{
      locationId: string;
      metricTypeId: number;
      consumptionPeriodStart: Date;
      consumptionPeriodEnd: Date;
      sourceType: string;
      sourceId: string;
      updatedAt: string;
      actualValue: number | null;
      clientForecast: number | null;
    }> = [];

    validRecords.forEach((record: any) => {
      // Determine if this is actual or forecast data
      const dataType = record.data_type?.toLowerCase() || "";
      const isActual = dataType.includes("actual") || dataType === "act" || dataType === "a";
      const isForecast = dataType.includes("forecast") || dataType === "f";

      // Get date range for this record
      const { start, end } = getDateRangeFromMonthYear(record.month, record.year);

      // Process each metric type in the record
      Object.entries(metricTypeMapping).forEach(([metricName, metricTypeId]) => {
        // Skip if value is missing
        if (record[metricName] === undefined || record[metricName] === null || record[metricName] === "") {
          return;
        }

        // Parse the consumption value
        const value = parseConsumptionValue(record[metricName]);

        if (value === null) {
          logger.warn(`Null or invalid value for ${metricName}: ${record[metricName]}`);
          return;
        }

        if (value === 0) {
          logger.warn(`Zero value for ${metricName}: ${record[metricName]}`);
          // Continue processing zero values, don't skip them
        }

        // Create consumption record
        const consumptionRecord = {
          locationId,
          metricTypeId: Number(metricTypeId),
          consumptionPeriodStart: start,
          consumptionPeriodEnd: end,
          sourceType: "survey",
          sourceId: responseId ? responseId.toString() : "",
          updatedAt: new Date().toISOString(),
          actualValue: isActual ? value : null,
          clientForecast: isForecast ? value : null,
        };

        // If data type is not specified, use actual value by default
        if (!isActual && !isForecast) {
          consumptionRecord.actualValue = value;
          consumptionRecord.clientForecast = null;
        }

        // Convert string dates to Date objects for consumptionPeriodStart and consumptionPeriodEnd
        const consumptionRecordWithDateObjects = {
          ...consumptionRecord,
          consumptionPeriodStart: new Date(consumptionRecord.consumptionPeriodStart),
          consumptionPeriodEnd: new Date(consumptionRecord.consumptionPeriodEnd),
        };

        consumptionRecords.push(consumptionRecordWithDateObjects);
      });
    });

    if (consumptionRecords.length > 0) {
      // Send records to service
      await service.createConsumptionRecords(consumptionRecords);
    }
  } catch (error) {
    logger.error("Error processing consumption records:", error);
    // Don't throw - allow the process to continue
  }
}

/**
 * Process occupancy data from survey response
 */
async function processOccupancyData(
  surveyResponse: any,
  locationId: string,
  service: SurveyProcessorService
): Promise<void> {
  try {
    if (!surveyResponse.extractedExcelData || !Array.isArray(surveyResponse.extractedExcelData)) {
      logger.warn("No Excel data available for occupancy processing");
      return;
    }

    // Filter records with occupancy data
    const occupancyRecords = surveyResponse.extractedExcelData.filter((record: any) => {
      return record.occupancy !== undefined && record.occupancy !== null && record.occupancy !== "";
    });

    if (occupancyRecords.length === 0) {
      logger.warn("No occupancy records found in Excel data");

      // Try to use average_monthly_occupancy from survey response as fallback
      if (surveyResponse.average_monthly_occupancy) {
        const { start, end } = getDefaultConsumptionPeriodDates();
        const occupancyValue = parseConsumptionValue(surveyResponse.average_monthly_occupancy);

        if (occupancyValue !== null && occupancyValue > 0 && service.createLocationOccCustomerData) {
          const occupancyData = {
            locationId,
            occupancy: occupancyValue,
            periodStart: start,
            periodEnd: end,
          };

          await service.createLocationOccCustomerData(occupancyData);
        }
      }

      return;
    }

    // Process occupancy records
    const bulkOccupancyData = occupancyRecords.map((record: any) => {
      // Check data type
      const dataType = record.data_type?.toLowerCase() || "";
      const isActual = dataType.includes("actual") || dataType === "act" || dataType === "a";
      const isForecast = dataType.includes("forecast") || dataType === "f";

      // Get date range
      const { start, end } = getDateRangeFromMonthYear(record.month || 1, record.year || new Date().getFullYear());

      // Parse value
      const occupancyValue = parseConsumptionValue(record.occupancy);
      // Handle the null case appropriately - need to use undefined for GraphQL compatibility
      let occupancyForGraphQL;
      if (isActual) {
        occupancyForGraphQL = occupancyValue === null ? undefined : occupancyValue;
      } else {
        occupancyForGraphQL = undefined;
      }

      let forecastForGraphQL;
      if (isForecast) {
        forecastForGraphQL = occupancyValue === null ? undefined : occupancyValue;
      } else {
        forecastForGraphQL = undefined;
      }

      return {
        locationId,
        occupancy: occupancyForGraphQL,
        clientForecast: forecastForGraphQL,
        periodStart: start,
        periodEnd: end,
      };
    });

    if (bulkOccupancyData.length > 0 && service.createBulkLocationOccCustomerData) {
      await service.createBulkLocationOccCustomerData(bulkOccupancyData);
    } else if (bulkOccupancyData.length > 0 && service.createLocationOccCustomerData) {
      // Fall back to creating one by one
      await bulkOccupancyData.reduce(async (previousPromise: Promise<void>, record: any) => {
        await previousPromise;
        try {
          if (service.createLocationOccCustomerData) {
            await service.createLocationOccCustomerData(record);
          }
        } catch (error) {
          logger.error(`Error creating occupancy record: ${error}`);
        }
      }, Promise.resolve());
    }
  } catch (error) {
    logger.error("Error processing occupancy data:", error);
    // Don't throw - allow the process to continue
  }
}

/**
 * Process a survey response to create a new location and user
 * This function should be called from a React component that provides the service implementation
 */
async function processSurveyResponseAndCreateEntities(
  surveyResponse: any,
  service: SurveyProcessorService
): Promise<{ locationId: string; userId: string }> {
  try {
    if (!surveyResponse) {
      throw new Error("Survey response is required");
    }

    if (!service) {
      throw new Error("Service implementation is required");
    }

    // Extract location data from survey response
    const locationData = extractLocationDataFromSurvey(surveyResponse);

    let locationId: string;

    // Check if we should skip location creation (for existing locations)
    if (surveyResponse.skipLocationCreation && surveyResponse.existing_location_id) {
      // Use the existing location ID
      locationId = surveyResponse.existing_location_id;

      // Create or update location address for existing location if we have address data
      if (locationData.address1 || locationData.city || locationData.country) {
        try {
          const addressInput = {
            location_id: locationId,
            address1: locationData.address1,
            address2: locationData.address2,
            city: locationData.city,
            country: locationData.country,
            zipCode: locationData.zipCode ? parseInt(locationData.zipCode, 10) : null,
          };

          await service.createLocationAddress(addressInput);
        } catch (error) {
          logger.error("Failed to create location address:", error);
          // Continue with the process even if address creation fails
        }
      }
    } else {
      // Create location
      const locationInput: Sensorflow_Locations_Insert_Input = {
        organisation: {
          data: { name: locationData.organizationName },
          on_conflict: {
            constraint: Sensorflow_Organisations_Constraint.UniqueName,
            update_columns: [Sensorflow_Organisations_Update_Column.Name],
          },
        },
        locationName: locationData.locationName,
        currency: locationData.currency,
        timezone: locationData.timezone,
        origin_latitude: locationData.latitude,
        origin_longitude: locationData.longitude,
        otaMaxRetries: GATEWAY_DEFAULT_SETTINGS.OtaMaxRetries,
        otaWindowStartTime: GATEWAY_DEFAULT_SETTINGS.OtaWindowStartTime,
        otaWindowEndTime: GATEWAY_DEFAULT_SETTINGS.OtaWindowEndTime,
      };

      // Create location with error handling
      let locationResponse;
      try {
        locationResponse = await service.createLocation(locationInput);
        if (!locationResponse) {
          throw new Error("Location creation failed: Empty response");
        }
      } catch (error) {
        logger.error("Error creating location:", error);

        // Check if the error is related to a uniqueness constraint violation
        if (error instanceof Error) {
          const errorMessage = error.message;

          // If it's a uniqueness violation for location name, provide a more helpful error message
          if (
            errorMessage.includes("Uniqueness violation") &&
            (errorMessage.includes("locations_name_k") || errorMessage.includes("A location with the name"))
          ) {
            throw new Error(
              `A location with the name "${locationData.locationName}" already exists. Please use a different location name.`
            );
          }

          // If it already contains the location name and "already exists", just pass it through
          if (errorMessage.includes("A location with the name") && errorMessage.includes("already exists")) {
            throw error;
          }
        }

        // For other errors, create a generic error message
        throw new Error(`Failed to create location: ${error instanceof Error ? error.message : "Unknown error"}`);
      }

      locationId = locationResponse?.locationId;

      if (!locationId) {
        throw new Error("Failed to create location: No location ID returned");
      }

      // Create location address if we have address data
      if (locationData.address1 || locationData.city || locationData.country) {
        try {
          const addressInput = {
            location_id: locationId,
            address1: locationData.address1,
            address2: locationData.address2,
            city: locationData.city,
            country: locationData.country,
            zipCode: locationData.zipCode ? parseInt(locationData.zipCode, 10) : null,
          };

          await service.createLocationAddress(addressInput);
        } catch (error) {
          logger.error("Failed to create location address:", error);
          // Continue with the process even if address creation fails
        }
      }
    }

    // Create property information if we have property data
    if (
      !surveyResponse.skipPropertyInformation &&
      (locationData.totalSquareFootage || locationData.hospitalityType || locationData.totalGuestRooms)
    ) {
      try {
        // Note: Even though the extraction function already converts arrays to strings,
        // we're keeping these safety checks as an additional precaution
        const propertyInfoInput = {
          locationId,
          totalSquareFootage: Math.round(locationData.totalSquareFootage),
          hospitalityType: locationData.hospitalityType,
          totalGuestRooms: locationData.totalGuestRooms,
          noOfStudios: locationData.noOfStudios,
          noOf1Bedrooms: locationData.noOf1Bedrooms,
          noOf2Bedrooms: locationData.noOf2Bedrooms,
          noOf3Bedrooms: locationData.noOf3Bedrooms,
          roomsFloorPercentage: locationData.roomsFloorPercentage,
          commonAreasFloorPercentage: locationData.commonAreasFloorPercentage,
          restaurantsFloorPercentage: locationData.restaurantsFloorPercentage,
          spasFloorPercentage: locationData.spasFloorPercentage,
          backOfficesFloorPercentage: locationData.backOfficesFloorPercentage,
          otherAreasFloorPercentage: locationData.otherAreasFloorPercentage,
          hvacSystemType: Array.isArray(locationData.hvacSystemType)
            ? locationData.hvacSystemType.join(", ")
            : locationData.hvacSystemType || "",
          energyInitiatives: Array.isArray(locationData.energyInitiatives)
            ? locationData.energyInitiatives.join(", ")
            : locationData.energyInitiatives || "",
          electricityBillingPeriodStart: locationData.electricityBillingPeriodStart,
          electricityBillingPeriodEnd: locationData.electricityBillingPeriodEnd,
          waterBillingPeriodStart: locationData.waterBillingPeriodStart,
          waterBillingPeriodEnd: locationData.waterBillingPeriodEnd,
          gasBillingPeriodStart: locationData.gasBillingPeriodStart,
          gasBillingPeriodEnd: locationData.gasBillingPeriodEnd,
          wasteBillingPeriodStart: locationData.wasteBillingPeriodStart,
          wasteBillingPeriodEnd: locationData.wasteBillingPeriodEnd,
          hasEnergyInitiatives: locationData.hasEnergyInitiatives,
          hasOtherHVACSolutions: locationData.hasOtherHVACSolutions,
          hvacConsumptionPercentage: locationData.hvacConsumptionPercentage,
          hvacSolutions: Array.isArray(locationData.hvacSolutions)
            ? locationData.hvacSolutions.join(", ")
            : locationData.hvacSolutions || "",
        };

        await service.createPropertyInformation(propertyInfoInput);
      } catch (error) {
        logger.error("Failed to create property information:", error);
        // Continue with the process even if property info creation fails
      }
    } else if (surveyResponse.skipPropertyInformation) {
      logger.warn("Skipping property information creation as requested");
    }

    // Extract user data from survey response
    const userData = extractUserDataFromSurvey(surveyResponse);

    // Validate user data only if not working with an existing location
    // and if the skipUserValidation flag is not set
    if (!surveyResponse.skipLocationCreation && !surveyResponse.skipUserValidation) {
      validateUserFields(surveyResponse);
    }

    let userId: string;

    // Skip user creation if we're working with an existing location
    // or if there's a user_id already provided
    if (surveyResponse.skipLocationCreation || surveyResponse.user_id) {
      // Use the existing user ID if provided, otherwise a placeholder
      userId = surveyResponse.user_id || "existing-user";
    } else {
      // Create user and associate with the new location
      // Determine role ID based on company role or use a default role
      const roleId = determineRoleIdFromCompanyRole();

      // Get organization ID from the location
      let organisationId;
      try {
        organisationId = await getOrganisationIdFromLocationId();
        if (!organisationId) {
          throw new Error("Failed to get organization ID");
        }
      } catch (error) {
        logger.error("Error getting organization ID:", error);
        // Use the specific organization ID as fallback
        organisationId = "45f85dbd-7c93-4dcf-adb2-e29c3b2abc96"; // Specific organization ID
      }

      const userInput: UserInput = {
        name: `${userData.firstName} ${userData.lastName}`.trim(),
        roleId,
        organisationId,
        locationIds: [locationId],
        isManager: true,
        isContractor: false,
      };

      // Create user with error handling
      let userResponse;
      try {
        userResponse = await service.createUser(userData.email, userInput);
        if (!userResponse) {
          throw new Error("User creation failed: Empty response");
        }
      } catch (error) {
        logger.error("Error creating user:", error);
        throw new Error(`Failed to create user: ${error instanceof Error ? error.message : "Unknown error"}`);
      }

      if (!userResponse || !userResponse.userId) {
        throw new Error("Failed to create user: No user ID returned");
      }

      userId = userResponse.userId;

      // Update user profile with additional fields if the service supports it
      if (service.updateUserProfile && userData.firstName) {
        try {
          await service.updateUserProfile(userId, {
            firstName: userData.firstName,
            lastName: userData.lastName,
            companyRole: userData.companyRole,
            phoneNumber: userData.phoneNumber,
          });
        } catch (error) {
          logger.error("Failed to update user profile:", error);
          // Continue with the process even if profile update fails
        }
      }
    }

    // Store the survey response in the database if the service supports it
    let responseId = null;
    if (surveyResponse.responseId) {
      // If a response ID was passed directly, use it
      responseId = surveyResponse.responseId;
      logger.info("Using provided survey response ID:", responseId);
    } else if (service.createSurveyResponse && !surveyResponse.skipSurveyResponseCreation) {
      try {
        const surveyResponseResult = await service.createSurveyResponse({
          locationId,
          userId,
          submissionDate: new Date(),
          // Only store the original survey response data, not the extracted Excel data
          surveyResponse: {
            ...surveyResponse,
            // Remove the large Excel data objects from the survey response
            extractedExcelData: undefined,
            originalExcelData: undefined,
          },
          // Ensure consumptionFileURL is the S3 URL from the upload process
          consumptionFileURL: surveyResponse.consumptionFileURL || null,
        });

        // Store the response ID for use with consumption records
        responseId = surveyResponseResult?.responseId;
      } catch (error) {
        logger.error("Failed to store survey response:", error);
        // Continue with the process even if survey response storage fails
      }
    } else if (surveyResponse.skipSurveyResponseCreation) {
      logger.warn("Skipping survey response creation as requested");
    }

    // Process consumption and occupancy data
    await processConsumptionRecords(surveyResponse, locationId, responseId, service);
    await processOccupancyData(surveyResponse, locationId, service);

    message.success("Successfully created location, user, consumption data, and stored survey response");
    return { locationId, userId };
  } catch (error) {
    logger.error("Error processing survey response:", error);
    if (error instanceof Error) {
      logger.error("Error message:", error.message);
      logger.error("Error stack:", error.stack);
    }
    throw error;
  }
}

export default processSurveyResponseAndCreateEntities;
