import { Alert, Button, Card, DatePicker, Input, Space, Spin, Table, Typography, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import excelService, { ExcelExtractionResult } from "../../services/excel/excelService";
import logger from "../../services/logger/logger";
import s3Service from "../../services/s3/s3Service";
import extractExcelBase64FromJson from "../../utils/excelUtils";
import "./ExcelDataExtractor.scss";

const { Title, Text } = Typography;

interface ExcelDataExtractorProps {
  jsonData: any;
  onConfirm: (extractedData: any) => void;
  onCancel: () => void;
}

interface ExtractedData extends ExcelExtractionResult {
  consumptionFileURL?: string;
  s3Upload?: S3UploadResult | null;
}

interface S3UploadResult {
  success: boolean;
  url?: string;
  key?: string;
  error?: string;
}

const ExcelDataExtractor: React.FC<ExcelDataExtractorProps> = ({ jsonData, onConfirm, onCancel }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [extractedData, setExtractedData] = useState<ExtractedData | null>(null);
  const [editingCell, setEditingCell] = useState<{ rowIndex: number; colIndex: number } | null>(null);
  const [editValue, setEditValue] = useState<string>("");
  const [editDate, setEditDate] = useState<moment.Moment | null>(null);
  const [s3UploadResult, setS3UploadResult] = useState<S3UploadResult | null>(null);

  // Upload the file to S3
  const uploadFileToS3 = async (excelData: { base64Content: string; fileName: string; fileType: string }) => {
    if (!excelData || !excelData.base64Content) {
      logger.error("No valid file data to upload to S3");
      setS3UploadResult({
        success: false,
        error: "No valid file data to upload",
      });
      return;
    }

    try {
      // Upload the file to S3
      const uploadResult = await s3Service.uploadFile(excelData.base64Content, excelData.fileName, excelData.fileType);

      setS3UploadResult({
        success: true,
        url: uploadResult.Location,
        key: uploadResult.Key,
      });

      message.success("File uploaded to S3 successfully");
    } catch (err) {
      logger.error("Error uploading file to S3:", err);

      setS3UploadResult({
        success: false,
        error: err instanceof Error ? err.message : "Unknown error uploading to S3",
      });

      message.error("Failed to upload file to S3");
    }
  };

  const extractData = async () => {
    try {
      setLoading(true);
      setError(null);

      // Extract the base64 content from the JSON data
      const excelData = extractExcelBase64FromJson(jsonData);

      if (!excelData) {
        setError("No Excel file data found in the JSON");
        setLoading(false);
        return;
      }

      // Extract data from the Excel file
      const extractedResult = await excelService.extractDataFromExcel(excelData);

      // Validate that we have columns and rows
      if (
        !extractedResult.columns ||
        !extractedResult.rows ||
        extractedResult.columns.length === 0 ||
        extractedResult.rows.length === 0
      ) {
        setError("No valid data found in the Excel file");
        setLoading(false);
        return;
      }

      // Set the extracted data
      setExtractedData(extractedResult);

      // Upload the Excel file to S3
      await uploadFileToS3(excelData);
    } catch (err) {
      logger.error("Error extracting data:", err);
      setError(err instanceof Error ? err.message : "An unknown error occurred");
    } finally {
      setLoading(false);
    }
  };

  // Extract data automatically when component mounts
  useEffect(() => {
    extractData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Normalize column names to a standard format
  const normalizeColumnNames = (columns: string[]): string[] => {
    // Define specific mappings for the Excel columns
    const columnMappings: Record<string, string> = {
      // Essential columns
      type_of_data: "data_type",
      month: "month",
      year: "year",
      "month,_year": "date", // Add mapping for combined month,_year column

      // Consumption columns with exact mappings
      "electricity_consumption_(kwh)": "electricity_consumption",
      "electricity_consumption_cost_(usd)": "electricity_consumption_cost",
      "average_monthly_occupancy_(%)": "occupancy",
      average_monthly_occupancy: "occupancy",
      "sub-metered/chiller_reading_(kwh)": "sub_metered_chiller_reading",
      "sub_metered_rooms_(kwh)": "sub_metered_chiller_reading", // Add mapping for sub_metered_rooms
      "water_consumption_(m3)": "water_consumption",
      "water_consumption_cost_(usd)": "water_consumption_cost",
      "gas_consumption_(m3)": "gas_consumption",
      "gas_consumption_cost_(usd)": "gas_consumption_cost",
      "waste_consumption_(mt)": "waste_consumption",
      "waste_consumption_cost_(usd)": "waste_consumption_cost",
    };

    // Normalize each column name
    return columns.map((column) => {
      // Convert to lowercase and replace spaces with underscores
      const normalizedName = column.toLowerCase().replace(/\s+/g, "_").replace(/[/-]/g, "_");

      // Check if this column has a mapping
      if (columnMappings[normalizedName]) {
        return columnMappings[normalizedName];
      }

      // Special case for data_type variations
      if (
        normalizedName.includes("type_of_data") ||
        (normalizedName.includes("actual") && normalizedName.includes("forecast"))
      ) {
        return "data_type";
      }

      // No mapping found, return the normalized name
      return normalizedName;
    });
  };

  // Transform Excel data from columns and rows into an array of objects
  const transformExcelDataToObjects = (data: ExtractedData): any[] => {
    if (!data.columns || !data.rows || data.columns.length === 0 || data.rows.length === 0) {
      return [];
    }

    // Normalize column names
    const normalizedColumns = normalizeColumnNames(data.columns);

    // Convert each row into an object using the normalized column names as keys
    const transformedData = data.rows.map((row) => {
      const obj: Record<string, any> = {};

      // Map each column to its value using normalized column names
      normalizedColumns.forEach((column, index) => {
        let value = row[index] !== undefined ? row[index] : "";

        // For 'average_monthly_occupancy' column, keep both the original name and the normalized 'occupancy' name
        if (
          normalizedColumns[index] === "occupancy" &&
          data.columns[index].toLowerCase().includes("average_monthly_occupancy")
        ) {
          obj.average_monthly_occupancy = value;
        }

        // Handle combined month-year format like "Feb, 2023"
        if (typeof value === "string" && /^[A-Za-z]{3,}[\s,]+\d{4}$/.test(value)) {
          // Extract month and year
          const parts = value.split(/[\s,]+/);
          const monthName = parts[0].trim();
          const yearStr = parts[parts.length - 1].trim();
          const year = parseInt(yearStr, 10);

          if (!Number.isNaN(year)) {
            // Convert month name to number
            const monthNames = [
              "january",
              "february",
              "march",
              "april",
              "may",
              "june",
              "july",
              "august",
              "september",
              "october",
              "november",
              "december",
            ];
            const monthAbbreviations = [
              "jan",
              "feb",
              "mar",
              "apr",
              "may",
              "jun",
              "jul",
              "aug",
              "sep",
              "oct",
              "nov",
              "dec",
            ];

            let monthIndex = -1;
            const lowerMonthName = monthName.toLowerCase();

            // Try full month names
            monthIndex = monthNames.findIndex((m) => lowerMonthName.includes(m));

            // If not found, try abbreviations
            if (monthIndex === -1) {
              monthIndex = monthAbbreviations.findIndex((m) => lowerMonthName.includes(m));
            }

            if (monthIndex !== -1) {
              const month = monthIndex + 1; // Convert to 1-based month

              // Add month and year fields - IMPORTANT: Set both month and year
              obj.month = month;
              obj.year = year;

              // Also store the original value
              obj[column] = value;

              // Skip the rest of the processing for this column
              return;
            }
          }
        }

        // Special handling for month and year to ensure they're numbers
        if (column === "month" || column === "year") {
          // Try to convert to a number if it's not already
          if (typeof value === "string") {
            // Handle month names (January, Feb, etc.)
            if (column === "month" && Number.isNaN(parseInt(value, 10))) {
              const monthNames = [
                "january",
                "february",
                "march",
                "april",
                "may",
                "june",
                "july",
                "august",
                "september",
                "october",
                "november",
                "december",
              ];
              const monthIndex = monthNames.findIndex((m) => value.toLowerCase().includes(m));
              if (monthIndex !== -1) {
                value = monthIndex + 1; // Convert to 1-based month number
              }
            }

            const numValue = parseInt(value, 10);
            if (!Number.isNaN(numValue)) {
              value = numValue;
            }
          }
        }

        // Special handling for date column to extract month and year
        if (column === "date" && value) {
          try {
            // Try to parse the date - handle both ISO format (2023-08-01) and other formats
            let dateValue;

            if (typeof value === "string") {
              // Check if it's in ISO format (YYYY-MM-DD)
              if (/^\d{4}-\d{2}-\d{2}$/.test(value)) {
                dateValue = new Date(value);
              }
              // Check if it's in format like "Aug, 2023" or "August 2023"
              else if (/^[A-Za-z]{3,}[\s,]+\d{4}$/.test(value)) {
                const parts = value.split(/[\s,]+/);
                const monthName = parts[0].trim();
                const yearStr = parts[parts.length - 1].trim();

                const monthNames = [
                  "january",
                  "february",
                  "march",
                  "april",
                  "may",
                  "june",
                  "july",
                  "august",
                  "september",
                  "october",
                  "november",
                  "december",
                ];
                const monthAbbreviations = [
                  "jan",
                  "feb",
                  "mar",
                  "apr",
                  "may",
                  "jun",
                  "jul",
                  "aug",
                  "sep",
                  "oct",
                  "nov",
                  "dec",
                ];

                let monthIndex = -1;
                const lowerMonthName = monthName.toLowerCase();

                // Try full month names
                monthIndex = monthNames.findIndex((m) => lowerMonthName.includes(m));

                // If not found, try abbreviations
                if (monthIndex === -1) {
                  monthIndex = monthAbbreviations.findIndex((m) => lowerMonthName.includes(m));
                }

                if (monthIndex !== -1 && !Number.isNaN(parseInt(yearStr, 10))) {
                  dateValue = new Date(parseInt(yearStr, 10), monthIndex, 1);
                }
              }
              // Try generic date parsing as fallback
              else {
                dateValue = new Date(value);
              }
            } else {
              // If it's already a Date object or a number
              dateValue = new Date(value);
            }

            if (dateValue && !Number.isNaN(dateValue.getTime())) {
              // If we have a valid date, extract month and year
              const month = dateValue.getMonth() + 1; // Convert to 1-based month
              const year = dateValue.getFullYear();

              // Set month and year in the object
              obj.month = month;
              obj.year = year;
            }
          } catch (parseError) {
            // Failed to parse date
          }
        }

        // Special handling for data_type to standardize values
        if (column === "data_type" && typeof value === "string") {
          const lowerValue = value.toLowerCase();
          if (lowerValue.includes("actual")) {
            value = "actual";
          } else if (
            lowerValue.includes("forecast") ||
            lowerValue.includes("budget") ||
            lowerValue.includes("projected")
          ) {
            value = "forecast";
          }
        }

        // Special handling for consumption values to ensure they're numbers
        if (column.includes("consumption") || column.includes("cost") || column === "sub_metered_chiller_reading") {
          // Try to convert to a number if it's not already
          if (typeof value === "string") {
            // Remove any non-numeric characters except decimal point
            const cleanValue = value.replace(/[^0-9.]/g, "");
            const numValue = parseFloat(cleanValue);
            if (!Number.isNaN(numValue)) {
              value = numValue;
            } else {
              // If we can't parse it as a number, set to empty string
              value = "";
            }
          }
        }

        obj[column] = value;
      });

      return obj;
    });

    return transformedData;
  };

  const handleConfirm = () => {
    if (extractedData) {
      // Transform the data from columns and rows into an array of objects
      const transformedData = transformExcelDataToObjects(extractedData);

      // Post-process the transformed data to fix any remaining date issues
      const processedData = transformedData.map((record) => {
        // Create a copy of the record to avoid modifying the original
        const processedRecord = { ...record };

        // Ensure month is a number between 1-12
        if (processedRecord.month !== undefined) {
          processedRecord.month = Number(processedRecord.month);
          if (processedRecord.month < 1 || processedRecord.month > 12) {
            processedRecord.month = new Date().getMonth() + 1;
          }
        }

        // Ensure year is a number between 1900-2100
        if (processedRecord.year !== undefined) {
          processedRecord.year = Number(processedRecord.year);
          if (processedRecord.year < 1900 || processedRecord.year > 2100) {
            processedRecord.year = new Date().getFullYear();
          }
        }

        // Check for electricity consumption values, water consumption, etc.
        // Log any null/undefined consumption value fields
        const consumptionFields = [
          "electricity_consumption",
          "water_consumption",
          "gas_consumption",
          "waste_consumption",
          "sub_metered_chiller_reading",
        ];

        let hasConsumptionData = false;
        consumptionFields.forEach((field) => {
          if (
            processedRecord[field] !== undefined &&
            processedRecord[field] !== null &&
            processedRecord[field] !== ""
          ) {
            hasConsumptionData = true;
            // Convert to numeric value if it's not already
            if (typeof processedRecord[field] === "string") {
              processedRecord[field] = parseFloat(processedRecord[field]);
            }
          }
        });

        // If we don't have any consumption data fields, log a warning
        if (!hasConsumptionData) {
          logger.warn("No consumption data fields found in record:", processedRecord);
        }

        // Ensure occupancy values are properly formatted as numbers
        if (
          processedRecord.occupancy !== undefined &&
          processedRecord.occupancy !== null &&
          processedRecord.occupancy !== ""
        ) {
          if (typeof processedRecord.occupancy === "string") {
            // Remove any non-numeric characters except decimal point and convert to number
            // First check if it's a percentage and handle it specially
            let cleanValue = processedRecord.occupancy;
            if (cleanValue.includes("%")) {
              cleanValue = cleanValue.replace(/%/g, "").trim();
            }
            // Then remove any remaining non-numeric characters
            cleanValue = cleanValue.replace(/[^0-9.]/g, "");
            const numValue = parseFloat(cleanValue);
            if (!Number.isNaN(numValue)) {
              processedRecord.occupancy = numValue;
            }
          }

          // Ensure value is between 0-100 for percentage
          if (typeof processedRecord.occupancy === "number") {
            // If it's over 100, it might be expressed as a decimal (e.g. 0.85 instead of 85%)
            if (processedRecord.occupancy > 0 && processedRecord.occupancy < 1) {
              processedRecord.occupancy *= 100;
            }
          }
        }

        return processedRecord;
      });

      // Create the data structure to return - we're NOT calculating an average anymore
      const result = {
        extractedExcelData: processedData, // Keep all individual records with their month/year data
        originalData: extractedData,
        consumptionFileURL: s3UploadResult?.url,
        occupancy_data: true, // Flag to indicate occupancy data is present
      };

      // Call the onConfirm callback with the processed data
      onConfirm(result);
    }
  };

  // Check if a value is a date string in YYYY-MM-DD format
  const isDateString = (value: string): boolean => {
    return /^\d{4}-\d{2}-\d{2}$/.test(value);
  };

  const startEditing = (rowIndex: number, colIndex: number, value: string) => {
    setEditingCell({ rowIndex, colIndex });
    setEditValue(value);

    // If it's a date, also set the date state for the DatePicker
    if (isDateString(value)) {
      setEditDate(moment(value, "YYYY-MM-DD"));
    } else {
      setEditDate(null);
    }
  };

  const saveEdit = () => {
    if (extractedData && editingCell) {
      const { rowIndex, colIndex } = editingCell;

      // If we're editing a date, use the formatted date value
      let valueToSave = editValue;
      if (editDate) {
        valueToSave = editDate.format("YYYY-MM-DD");
      }

      const updatedData = extractedData.updateCell(rowIndex, colIndex, valueToSave);
      setExtractedData(updatedData);
      setEditingCell(null);
    }
  };

  const cancelEdit = () => {
    setEditingCell(null);
    setEditDate(null);
  };

  const handleDateChange = (date: any) => {
    // Convert to local moment object
    if (date) {
      const localDate = moment(date.format("YYYY-MM-DD"));
      setEditDate(localDate);
    } else {
      setEditDate(null);
    }
  };

  // Generate table columns from the extracted data
  const generateColumns = () => {
    if (!extractedData || !extractedData.columns) {
      return [];
    }

    return extractedData.columns.map((column, index) => ({
      title: column,
      dataIndex: index.toString(),
      key: index.toString(),
      render: (text: string, record: any, rowIndex: number) => {
        const isEditing = editingCell && editingCell.rowIndex === rowIndex && editingCell.colIndex === index;

        // Check if this cell contains a date
        const isDate = isDateString(text);

        if (isEditing) {
          return (
            <Space>
              {isDate ? (
                <DatePicker
                  value={editDate as any}
                  onChange={handleDateChange}
                  format="YYYY-MM-DD"
                  style={{ width: "100%" }}
                />
              ) : (
                <Input
                  value={editValue}
                  onChange={(e) => setEditValue(e.target.value)}
                  onPressEnter={saveEdit}
                  autoFocus
                />
              )}
              <Button size="small" type="primary" onClick={saveEdit}>
                Save
              </Button>
              <Button size="small" onClick={cancelEdit}>
                Cancel
              </Button>
            </Space>
          );
        }

        return (
          <div
            className={`editable-cell ${isDate ? "date-cell" : ""}`}
            onClick={() => startEditing(rowIndex, index, text)}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                startEditing(rowIndex, index, text);
              }
            }}
            role="button"
            tabIndex={0}
            aria-label={`Edit ${column} value: ${text}`}
          >
            {text}
          </div>
        );
      },
    }));
  };

  // Generate table data from the extracted data
  const generateTableData = () => {
    if (!extractedData || !extractedData.rows) {
      return [];
    }

    return extractedData.rows.map((row, rowIndex) => {
      const rowData: Record<string, string> = { key: rowIndex.toString() };

      row.forEach((cell, cellIndex) => {
        rowData[cellIndex.toString()] = cell;
      });

      return rowData;
    });
  };

  return (
    <div className="excel-data-extractor">
      {loading && (
        <div className="loading-container">
          <Spin size="large" />
          <Text>Extracting data from Excel file...</Text>
        </div>
      )}

      {error && <Alert message="Error" description={error} type="error" showIcon />}

      {extractedData && (
        <div className="extracted-data-container">
          <Card className="extraction-card" title={<Title level={4}>Extracted Data</Title>} style={{ width: "100%" }}>
            {s3UploadResult && s3UploadResult.success && (
              <Alert
                message="File Uploaded Successfully"
                description={
                  <Text>
                    The Excel file has been uploaded to S3.{" "}
                    <a href={s3UploadResult.url} target="_blank" rel="noopener noreferrer">
                      View File
                    </a>
                  </Text>
                }
                type="success"
                showIcon
                style={{ marginBottom: 16 }}
              />
            )}

            {s3UploadResult && !s3UploadResult.success && (
              <Alert
                message="File Upload Failed"
                description={s3UploadResult.error}
                type="error"
                showIcon
                style={{ marginBottom: 16 }}
              />
            )}

            <div className="table-container" style={{ width: "100%" }}>
              <Table
                columns={generateColumns()}
                dataSource={generateTableData()}
                pagination={{ pageSize: 10 }}
                bordered
                size="middle"
                scroll={{ x: "max-content" }}
                style={{ width: "100%" }}
              />
            </div>
          </Card>

          <div className="action-buttons">
            <Button onClick={onCancel}>Cancel</Button>
            <Button type="primary" onClick={handleConfirm}>
              Confirm
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExcelDataExtractor;
