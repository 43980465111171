import * as React from "react";
import { useMemo, useState } from "react";
import { Button, Col, Form, Input, Modal, Row, Select, message } from "antd";
import {
  GetLevelsGroupQuery,
  Sensorflow_Smplrspace_Levels_Group_Constraint,
  useDeleteLevelsGroupMutation,
  useGetLevelsGroupQuery,
  useSaveLevelsGroupMutation,
  useUpdateLevelsGroupMutation,
} from "pacts/app-webcore/hasura-webcore.graphql";
import ActionButton from "components/ActionButton";
import errorHandler from "errorHandler";
import ConfirmDeleteModal, { ConfirmDeleteModalProps } from "components/Modal/ConfirmDeleteModal";
import showModal from "components/Modal/show";
import EditableTable from "components/EditableTable/EditableTable";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

type LevelsGroupModalProps = {
  showLevelsGroupModal: boolean;
  hideLevelsGroupModal: any;
  smplrspaceId: string;
};

interface LevelsGroupPayload {
  groupId: string;
  groupName: string;
  levelIndex: number[];
}

const LevelsGroupModal = (props: LevelsGroupModalProps) => {
  const { showLevelsGroupModal, hideLevelsGroupModal, smplrspaceId } = props;
  const [floorGroup, setFloorGroup] = useState<GetLevelsGroupQuery["sensorflow_smplrspace_levels_group"]>();
  const [addLevelsGroup, setAddLevelsGroup] = useState(false);
  const [levelIndexList, setLevelIndexList] = useState("");
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record: Record<string, any>) => record.key === editingKey;
  const [form] = Form.useForm();
  const [createForm] = Form.useForm();

  const { data, loading, refetch } = useGetLevelsGroupQuery({
    variables: {
      spaceId: smplrspaceId,
    },
  });

  useMemo(() => {
    if (data) {
      const tempData: any[] = [];
      data.sensorflow_smplrspace_levels_group.map((lg) => {
        tempData.push({
          ...lg,
          key: lg.groupId,
        });
        return true;
      });
      setFloorGroup(tempData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const edit = (record: Record<string, any> & { key: React.Key }) => {
    form.setFieldsValue({
      groupName: "",
      levelIndex: "",
      ...record,
    });
    setEditingKey(record.key as string);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const [insertLevelsGroup] = useSaveLevelsGroupMutation({
    onError: errorHandler.handleError,
  });

  const [removeLevelsGroup] = useDeleteLevelsGroupMutation({
    onError: errorHandler.handleError,
  });

  const [editLevelsGroup] = useUpdateLevelsGroupMutation({
    onError: errorHandler.handleError,
  });

  const saveLevelsGroup = async () => {
    const response = await insertLevelsGroup({
      variables: {
        mapLevelsGroup: [
          {
            spaceId: smplrspaceId,
            levelIndex: levelIndexList.split(",").map(Number),
            groupName: createForm.getFieldValue("groupName"),
          },
        ],
        constraint: Sensorflow_Smplrspace_Levels_Group_Constraint.MapFloorPlanPkey,
      },
    });

    if (!response) return;
    message.success("Successfully added Floor Plan");
    setFloorGroup([]);
    refetch();
    setAddLevelsGroup(false);
    createForm.setFieldsValue({
      groupName: "",
      levelIndex: [],
    });
  };

  const deleteLevelsGroup = async (groupIdInput: string) => {
    const response = await removeLevelsGroup({
      variables: {
        filter: [
          {
            groupId: {
              _eq: groupIdInput,
            },
          },
        ],
      },
    });

    if (!response) return;
    if (response.data?.delete_sensorflow_smplrspace_levels_group?.affected_rows === 1) {
      message.success("Floor Plan deleted successfully");
    } else {
      message.error("Failed to delete floor plan");
    }
    setFloorGroup([]);
    refetch();
  };

  const updateLevelsGroup = async (groupIdInput: string) => {
    const row = (await form.validateFields()) as LevelsGroupPayload;
    const response = await editLevelsGroup({
      variables: {
        groupName: row.groupName,
        levelIndex: row.levelIndex,
        groupId: groupIdInput,
      },
    });
    if (!response) return;
    if (response.data?.update_sensorflow_smplrspace_levels_group?.affected_rows === 1) {
      message.success("Floor Plan updated successfully");
    } else {
      message.error("Failed to update Floor Plan");
    }
    setFloorGroup([]);
    refetch();
    cancel();
  };

  const columnConfig = [
    {
      title: "Floor Plan Name",
      responsive: ["sm"],
      dataIndex: "groupName",
      key: "groupName",
      editable: true,
    },
    {
      title: "Floor Plan",
      responsive: ["sm"],
      dataIndex: "levelIndex",
      key: "levelIndex",
      editable: true,
      render: (levelIndex: any[]) => levelIndex.join(", "),
      customInput: <Select mode="tags" onChange={(e) => setLevelIndexList(e.join(","))} tokenSeparators={[","]} />,
    },
    {
      title: "Actions",
      width: 150,
      render: (record: Record<string, any> & { key: React.Key }) => {
        const editable = isEditing(record);
        return editable ? (
          <>
            <ActionButton
              type="save"
              onClick={() => {
                updateLevelsGroup(record.groupId);
              }}
              className="fs-xl mr-m"
              customIcon={
                <CheckCircleOutlined
                  className="fs-xl text-primary"
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                />
              }
            />
            <ActionButton
              type="cancel"
              onClick={() => {
                cancel();
              }}
              className="fs-xl mr-m"
              customIcon={
                <CloseCircleOutlined
                  className="fs-xl text-danger"
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                />
              }
            />
          </>
        ) : (
          <>
            <ActionButton
              type="edit"
              onClick={() => {
                edit(record);
              }}
              className="fs-xl mr-m"
            />
            <ActionButton
              type="delete"
              onClick={() => {
                showModal<ConfirmDeleteModalProps>({
                  element: ConfirmDeleteModal,
                  config: {
                    popupTitle: "Delete Floor Plan",
                    fieldPlaceholder: "Enter Group ID",
                    popupOkText: "Confirm",
                    onOk: () => {
                      deleteLevelsGroup(record.key);
                    },
                    confirmText: record.groupId,
                    message: `Are you sure you want to delete ${record.groupName} | ${record.groupId}?`,
                    fieldLabel: "Key in Group ID to confirm",
                  },
                });
              }}
              className="fs-xl mr-m"
            />
          </>
        );
      },
    },
  ];

  return (
    <Modal
      title="Floor Plan"
      open={showLevelsGroupModal}
      cancelButtonProps={{ style: { display: "none" } }}
      onCancel={hideLevelsGroupModal}
      okButtonProps={{ style: { display: "none" } }}
      width={700}
    >
      {!addLevelsGroup && (
        <Button type="primary" className="mb-m" onClick={() => setAddLevelsGroup((prev) => !prev)}>
          Add Floor Plan
        </Button>
      )}
      <>
        {addLevelsGroup ? (
          <div className="flex vertical space-between">
            <Form form={createForm}>
              <Row className="mb-m" gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item name="groupName" label="Group Name" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="levelIndex" label="Level Index" rules={[{ required: true }]}>
                    <Select mode="tags" onChange={(e) => setLevelIndexList(e.join(","))} tokenSeparators={[","]} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Row className="mb-none">
              <Button type="primary" className="mr-xs" onClick={saveLevelsGroup}>
                Save
              </Button>
              <Button onClick={() => setAddLevelsGroup(false)}>Cancel</Button>
            </Row>
          </div>
        ) : (
          <EditableTable
            form={form}
            columns={columnConfig}
            tableData={floorGroup}
            editingKey={editingKey}
            setEditingKey={setEditingKey}
            loading={loading}
          />
        )}
      </>
    </Modal>
  );
};

export default LevelsGroupModal;
