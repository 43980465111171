import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Typography,
} from "antd";
import errorHandler from "errorHandler";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  CreateBulkLocationOccCustomerDataMutationVariables,
  GetLocationOccCustomerDataQueryVariables,
  useCreateBulkLocationOccCustomerDataMutation,
  useGetLocationOccCustomerDataQuery,
} from "../../../pacts/app-webcore/hasura-webcore.graphql";
import "./SmartBEA.scss";

const { Text } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

interface OccupancyDataComponentProps {
  locationId: string;
}

export const OccupancyDataComponent: React.FC<OccupancyDataComponentProps> = ({ locationId }) => {
  const [occupancyMonth, setOccupancyMonth] = useState<moment.Moment>(moment());
  const [occupancyViewMode, setOccupancyViewMode] = useState<string>("calendar");
  const [occupancyData, setOccupancyData] = useState<any>(null);
  const [occupancyForm] = Form.useForm();
  const [occupancyBatchData, setOccupancyBatchData] = useState<any[]>([]);
  const [occupancyDateRange, setOccupancyDateRange] = useState<[moment.Moment, moment.Moment]>([
    moment().startOf("month"),
    moment().endOf("month"),
  ]);
  const [isOccupancyModalVisible, setIsOccupancyModalVisible] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>("input");
  // Track currently focused input to support global paste
  const [focusedInput, setFocusedInput] = useState<{ fieldType: string; recordKey: string } | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  // Create ref for the table container at component level
  const tableRef = useRef<HTMLDivElement>(null);

  // GraphQL queries and mutations
  const {
    data: occData,
    loading: occLoading,
    refetch: refetchOccupancyData,
  } = useGetLocationOccCustomerDataQuery({
    variables: {
      locationId,
      periodStart: occupancyDateRange[0].format("YYYY-MM-DD"),
      periodEnd: occupancyDateRange[1].format("YYYY-MM-DD"),
    } as GetLocationOccCustomerDataQueryVariables,
  });

  const [createBulkOccupancyData] = useCreateBulkLocationOccCustomerDataMutation();

  // Separate the processing logic for better modularity
  const processClipboardData = (clipboardData: string, recordKey: string, fieldType: string) => {
    // Handle tab-delimited, CSV, and newline-separated data
    // This makes it more compatible with Excel and other spreadsheet applications
    const rows = clipboardData.split(/\n|\r/).filter((line) => line.trim() !== "");

    if (rows.length === 0) return;

    // Check if we have a multi-column paste from Excel
    // (typically has tabs or commas between columns)
    const hasDelimiters = rows[0].includes("\t") || rows[0].includes(",");

    // If single cell value, apply it directly
    if (rows.length === 1 && !hasDelimiters) {
      occupancyForm.setFieldValue(`${fieldType}-${recordKey}`, rows[0].trim());
      return;
    }

    // For multi-row data, find the starting point
    const currentIndex = occupancyBatchData.findIndex((record) => record.key === recordKey);
    if (currentIndex === -1) return;

    // Create a map of updates for form fields
    const fieldUpdates: { [key: string]: string } = {};
    const occupancyCol = 0;
    const forecastCol = 1;

    // If the data has both occupancy and forecast in tab/comma-delimited format
    if (hasDelimiters) {
      // Determine delimiter
      const delimiter = rows[0].includes("\t") ? "\t" : ",";

      // Process spreadsheet-like data with multiple columns
      rows.forEach((row, rowIndex) => {
        const columns = row.split(delimiter).map((cell) => cell.trim());

        // If we have at least one column of data
        if (columns.length > 0 && currentIndex + rowIndex < occupancyBatchData.length) {
          const targetRecord = occupancyBatchData[currentIndex + rowIndex];

          // If first column has a value, use it for occupancy
          if (columns[occupancyCol] !== undefined && columns[occupancyCol] !== "") {
            fieldUpdates[`occupancy-${targetRecord.key}`] = columns[occupancyCol];
          }

          // If second column has a value, use it for forecast
          if (columns.length > 1 && columns[forecastCol] !== undefined && columns[forecastCol] !== "") {
            fieldUpdates[`clientForecast-${targetRecord.key}`] = columns[forecastCol];
          }
        }
      });
    } else {
      // Simply apply values to consecutive rows of the current column
      rows.forEach((value, index) => {
        if (currentIndex + index < occupancyBatchData.length) {
          const targetRecord = occupancyBatchData[currentIndex + index];
          fieldUpdates[`${fieldType}-${targetRecord.key}`] = value.trim();
        }
      });
    }

    // Update the form with all values at once
    if (Object.keys(fieldUpdates).length > 0) {
      // We need to set fields individually to avoid form validation issues
      Object.entries(fieldUpdates).forEach(([name, value]) => {
        occupancyForm.setFields([
          {
            name,
            value,
            errors: [],
          },
        ]);
      });

      message.success(`Pasted data into ${Object.keys(fieldUpdates).length} cells`);
    }
  };

  // Track focus/blur of inputs to know where to paste
  const handleInputFocus = (fieldType: string, recordKey: string) => {
    setFocusedInput({ fieldType, recordKey });
  };

  const handleInputBlur = () => {
    // Small delay to allow click events to complete before clearing focus
    setTimeout(() => {
      setFocusedInput(null);
    }, 100);
  };

  // Add this new function to handle direct paste from clipboard
  const handleTablePaste = (e: React.ClipboardEvent | ClipboardEvent) => {
    // If no particular input is focused, don't try to paste
    if (!focusedInput && (e as ClipboardEvent) instanceof ClipboardEvent) {
      const activeElement = document.activeElement as HTMLElement;

      // Find which input is currently focused
      if (activeElement && activeElement.tagName === "INPUT") {
        const inputId = activeElement.getAttribute("id");
        if (inputId && inputId.includes("-")) {
          const [fieldType, recordKey] = inputId.split("-");
          if (fieldType && recordKey) {
            // Found an input with proper ID format
            try {
              // Using a try/catch to handle any potential clipboard access issues
              const clipboardData = (e as ClipboardEvent).clipboardData?.getData("text") || "";
              processClipboardData(clipboardData, recordKey, fieldType);
              e.preventDefault();
            } catch (err) {
              // Error handling for clipboard access
            }
            return;
          }
        }
      }
      return; // No focused input with proper ID found
    }

    // If we have a focused input from our state tracking
    if (focusedInput) {
      const { fieldType, recordKey } = focusedInput;
      try {
        // Try to safely get clipboard data with proper null checks
        let clipboardText = "";
        const reactEvent = e as React.ClipboardEvent;
        const domEvent = e as ClipboardEvent;

        if (reactEvent.clipboardData) {
          clipboardText = reactEvent.clipboardData.getData("text");
        } else if (domEvent.clipboardData) {
          clipboardText = domEvent.clipboardData.getData("text") || "";
        }

        if (clipboardText) {
          processClipboardData(clipboardText, recordKey, fieldType);
          e.preventDefault();
        }
      } catch (err) {
        // Error handling for clipboard access
      }
    }
  };

  const handleInputPaste = (e: React.ClipboardEvent, recordKey: string, fieldType: string) => {
    e.preventDefault();
    const clipboardData = e.clipboardData.getData("text");
    processClipboardData(clipboardData, recordKey, fieldType);
  };

  const generateOccupancyBatchData = () => {
    const results: any[] = [];

    if (occupancyViewMode === "calendar") {
      // For calendar view, generate the entire month
      const daysInMonth = occupancyMonth.daysInMonth();
      for (let i = 1; i <= daysInMonth; i++) {
        const currentDate = moment(occupancyMonth).date(i);
        results.push({
          key: currentDate.format("YYYY-MM-DD"),
          periodStart: currentDate.format("YYYY-MM-DD"),
          periodEnd: currentDate.format("YYYY-MM-DD"),
          occupancy: null,
          clientForecast: null,
        });
      }
    } else if (occupancyViewMode === "weekly") {
      // For weekly view, generate the 4-5 weeks in the month
      const firstDay = moment(occupancyMonth).startOf("month");
      const lastDay = moment(occupancyMonth).endOf("month");
      const currentWeekStart = moment(firstDay).startOf("week");

      while (currentWeekStart.isSameOrBefore(lastDay)) {
        const weekEnd = moment(currentWeekStart).endOf("week");
        results.push({
          key: `${currentWeekStart.format("YYYY-MM-DD")}-${weekEnd.format("YYYY-MM-DD")}`,
          periodStart: currentWeekStart.format("YYYY-MM-DD"),
          periodEnd: weekEnd.format("YYYY-MM-DD"),
          occupancy: null,
          clientForecast: null,
        });
        currentWeekStart.add(1, "week");
      }
    } else if (occupancyViewMode === "monthly") {
      // For monthly view, just one entry for the month
      results.push({
        key: occupancyMonth.format("YYYY-MM"),
        periodStart: moment(occupancyMonth).startOf("month").format("YYYY-MM-DD"),
        periodEnd: moment(occupancyMonth).endOf("month").format("YYYY-MM-DD"),
        occupancy: null,
        clientForecast: null,
      });
    }

    // Merge with existing data if available
    if (occupancyData?.sensorflow_location_occ_customer_data) {
      const existingData = occupancyData.sensorflow_location_occ_customer_data;

      for (let i = 0; i < results.length; i++) {
        const record = results[i];
        const matchingRecord = existingData.find(
          (item: any) =>
            moment(item.periodStart).isSameOrBefore(moment(record.periodEnd)) &&
            moment(item.periodEnd).isSameOrAfter(moment(record.periodStart))
        );

        if (matchingRecord) {
          results[i] = {
            ...record,
            id: matchingRecord.id,
            occupancy: matchingRecord.occupancy,
            clientForecast: matchingRecord.clientForecast,
          };
        }
      }
    }

    setOccupancyBatchData(results);
  };

  // Add paste event listener when the component mounts
  useEffect(() => {
    // Add global paste handler to document to catch paste events anywhere
    const handleGlobalPaste = (e: ClipboardEvent) => {
      if (isOccupancyModalVisible) {
        handleTablePaste(e);
      }
    };

    document.addEventListener("paste", handleGlobalPaste);

    return () => {
      document.removeEventListener("paste", handleGlobalPaste);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOccupancyModalVisible, focusedInput]); // Re-attach when modal visibility or focus changes

  useEffect(() => {
    if (occData) {
      setOccupancyData(occData);
    }
  }, [occData]);

  useEffect(() => {
    generateOccupancyBatchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [occupancyMonth, occupancyViewMode]);

  const handleOccupancySubmit = () => {
    setIsSaving(true);

    occupancyForm
      .validateFields()
      .then((values) => {
        // Create valid records array based on the existing data structure
        const validRecords: any[] = [];

        occupancyBatchData.forEach((batch) => {
          const record: any = {
            locationId,
            periodStart: batch.periodStart,
            periodEnd: batch.periodEnd,
          };

          let hasData = false;

          // Process occupancy values
          if (values[`occupancy-${batch.key}`]) {
            const occupancyValue = parseFloat(values[`occupancy-${batch.key}`]);
            if (!Number.isNaN(occupancyValue) && occupancyValue >= 0 && occupancyValue <= 100) {
              record.occupancy = occupancyValue;
              hasData = true;
            }
          }

          // Process client forecast values
          if (values[`clientForecast-${batch.key}`]) {
            const forecastValue = parseFloat(values[`clientForecast-${batch.key}`]);
            if (!Number.isNaN(forecastValue) && forecastValue >= 0 && forecastValue <= 100) {
              record.clientForecast = forecastValue;
              hasData = true;
            }
          }

          if (hasData) {
            validRecords.push(record);
          }
        });

        if (validRecords.length === 0) {
          message.warning({
            content: "No valid data to save. Please enter at least one valid occupancy or forecast value (0-100).",
            duration: 5,
          });
          setIsSaving(false);
          return;
        }

        // Validate that each record has the required fields with correct types
        const validateRecord = (record: any) => {
          if (!record.locationId || typeof record.locationId !== "string") {
            return false;
          }
          if (!record.periodStart || typeof record.periodStart !== "string") {
            return false;
          }
          if (!record.periodEnd || typeof record.periodEnd !== "string") {
            return false;
          }
          // occupancy and clientForecast can be null but must be numbers if present
          if (record.occupancy !== undefined && record.occupancy !== null && typeof record.occupancy !== "number") {
            return false;
          }
          if (
            record.clientForecast !== undefined &&
            record.clientForecast !== null &&
            typeof record.clientForecast !== "number"
          ) {
            return false;
          }
          return true;
        };

        const allRecordsValid = validRecords.every(validateRecord);
        if (!allRecordsValid) {
          message.error("Some records have invalid data. Please check your inputs.");
          setIsSaving(false);
          return;
        }

        const mutationVariables = {
          records: validRecords,
        } as CreateBulkLocationOccCustomerDataMutationVariables;

        createBulkOccupancyData({
          variables: mutationVariables,
        })
          .then((result) => {
            const affectedRows = result.data?.insert_sensorflow_location_occ_customer_data?.affected_rows || 0;
            if (affectedRows > 0) {
              message.success(`Saved ${affectedRows} record(s) successfully.`);
              setIsOccupancyModalVisible(false);
              refetchOccupancyData();
            } else {
              message.warning("Failed to save records. No records were affected.");
              setIsSaving(false);
            }
          })
          .catch((error) => {
            // Extract more detailed information from GraphQL errors
            let errorMessage = error.message;
            if (error.graphQLErrors && error.graphQLErrors.length > 0) {
              const details = error.graphQLErrors.map((e: any) => e.message).join("; ");
              errorMessage = `GraphQL Error: ${details}`;
            }

            if (typeof errorHandler === "object" && "handleError" in errorHandler) {
              // @ts-ignore
              errorHandler.handleError(error);
            }

            message.error(`Failed to save records: ${errorMessage}`);
            setIsSaving(false);
          });
      })
      .catch(() => {
        message.error("Please correct the errors in the form before submitting.");
        setIsSaving(false);
      });
  };

  const handleOccupancyDateRangeChange = (dates: any) => {
    if (dates && dates.length === 2) {
      setOccupancyDateRange([dates[0], dates[1]]);
    }
  };

  // Add a handler function to clear all form fields
  const handleClearAll = () => {
    // Create empty form values object
    const emptyFields: { [key: string]: string } = {};

    // Set all fields to empty string
    occupancyBatchData.forEach((record) => {
      emptyFields[`occupancy-${record.key}`] = "";
      emptyFields[`clientForecast-${record.key}`] = "";
    });

    // Reset all form fields
    occupancyForm.setFields(
      Object.keys(emptyFields).map((name) => ({
        name,
        value: "",
        errors: [],
      }))
    );

    message.success("All values cleared");
  };

  const renderInputView = () => {
    const tableColumns = [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        width: "30%",
        render: (_: any, record: any) => {
          if (occupancyViewMode === "calendar") {
            return moment(record.periodStart).format("ddd, MMM D, YYYY");
          }
          if (occupancyViewMode === "weekly") {
            return `${moment(record.periodStart).format("MMM D")} - ${moment(record.periodEnd).format("MMM D, YYYY")}`;
          }
          return occupancyMonth.format("MMMM YYYY");
        },
      },
      {
        title: "Occupancy (%)",
        dataIndex: "occupancy",
        key: "occupancy",
        width: "35%",
        render: (_: any, record: any) => (
          <Form.Item
            name={`occupancy-${record.key}`}
            initialValue={record.occupancy !== null ? record.occupancy.toString() : ""}
            style={{ margin: 0 }}
            rules={[
              {
                validator: (_rule, value) => {
                  if (!value) return Promise.resolve();
                  const num = parseFloat(value);
                  if (Number.isNaN(num)) return Promise.reject(new Error("Must be a number"));
                  if (num < 0 || num > 100) return Promise.reject(new Error("Must be 0-100"));
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              id={`occupancy-${record.key}`}
              type="text" // Use text instead of number for better paste behavior
              min="0"
              max="100"
              placeholder="Enter occupancy %"
              suffix="%"
              onFocus={() => handleInputFocus("occupancy", record.key)}
              onBlur={handleInputBlur}
              onPaste={(e) => handleInputPaste(e, record.key, "occupancy")}
            />
          </Form.Item>
        ),
      },
      {
        title: "Client Forecast (%)",
        dataIndex: "clientForecast",
        key: "clientForecast",
        width: "35%",
        render: (_: any, record: any) => (
          <Form.Item
            name={`clientForecast-${record.key}`}
            initialValue={record.clientForecast !== null ? record.clientForecast.toString() : ""}
            style={{ margin: 0 }}
            rules={[
              {
                validator: (_rule, value) => {
                  if (!value) return Promise.resolve();
                  const num = parseFloat(value);
                  if (Number.isNaN(num)) return Promise.reject(new Error("Must be a number"));
                  if (num < 0 || num > 100) return Promise.reject(new Error("Must be 0-100"));
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              id={`clientForecast-${record.key}`}
              type="text" // Use text instead of number for better paste behavior
              min="0"
              max="100"
              placeholder="Enter forecast %"
              suffix="%"
              onFocus={() => handleInputFocus("clientForecast", record.key)}
              onBlur={handleInputBlur}
              onPaste={(e) => handleInputPaste(e, record.key, "clientForecast")}
            />
          </Form.Item>
        ),
      },
    ];

    return (
      <Form form={occupancyForm} layout="vertical" className="occupancy-form">
        <div className="table-info-message" style={{ marginBottom: 16 }}>
          <Text type="secondary">
            You can copy-paste values from Excel directly into the table. Multi-line paste will fill consecutive rows.
            For best results, select a cell and use Ctrl+V (Cmd+V on Mac) to paste data.
          </Text>
        </div>

        <div ref={tableRef} className="paste-capture-container" style={{ outline: "none" }}>
          <Table
            columns={tableColumns}
            dataSource={occupancyBatchData}
            rowKey="key"
            pagination={false}
            bordered
            size="middle"
            scroll={{ y: 500 }}
          />
        </div>

        <Row justify="end" style={{ marginTop: 16 }}>
          <Space>
            <Button onClick={() => setIsOccupancyModalVisible(false)}>Cancel</Button>
            <Popconfirm
              title="Are you sure you want to clear all values?"
              onConfirm={handleClearAll}
              okText="Yes"
              cancelText="No"
            >
              <Button>Clear All</Button>
            </Popconfirm>
            <Button type="primary" loading={isSaving} onClick={handleOccupancySubmit}>
              {isSaving ? "Saving..." : "Save"}
            </Button>
          </Space>
        </Row>
      </Form>
    );
  };

  const renderCalendarView = () => {
    const daysInMonth = occupancyMonth.daysInMonth();
    const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
    const firstDayOfMonth = moment(occupancyMonth).startOf("month").day(); // 0 for Sunday

    // Calculate number of blank cells before the first day
    const blanks = Array.from({ length: firstDayOfMonth }, (_, i) => i);

    // Create rows for each week
    const rows: JSX.Element[] = [];
    let cells: JSX.Element[] = [];

    // Add blank cells for first week
    blanks.forEach((_, i) => {
      cells.push(<Col key={`blank-${i}`} span={3} className="calendar-blank-day" />);
    });

    // Add day cells
    days.forEach((day) => {
      const date = moment(occupancyMonth).date(day);
      const dateStr = date.format("YYYY-MM-DD");
      const record = occupancyBatchData.find((item) => item.periodStart === dateStr);

      cells.push(
        <Col key={day} span={3} className="calendar-day">
          <div className="day-number">{day}</div>
          <div className="day-content">
            {record && (
              <div className="occupancy-display">
                <div>
                  <span className="label">Occ:</span>
                  <span className="value">{record.occupancy !== null ? `${record.occupancy}%` : "-"}</span>
                </div>
                <div>
                  <span className="label">Fcst:</span>
                  <span className="value">{record.clientForecast !== null ? `${record.clientForecast}%` : "-"}</span>
                </div>
              </div>
            )}
          </div>
        </Col>
      );

      // Start a new row every 7 cells (1 week)
      if ((firstDayOfMonth + day) % 7 === 0 || day === daysInMonth) {
        rows.push(<Row key={`week-${rows.length}`}>{cells}</Row>);
        cells = [];
      }
    });

    return (
      <div className="calendar-container">
        <Row className="calendar-header">
          <Col span={3}>Sun</Col>
          <Col span={3}>Mon</Col>
          <Col span={3}>Tue</Col>
          <Col span={3}>Wed</Col>
          <Col span={3}>Thu</Col>
          <Col span={3}>Fri</Col>
          <Col span={3}>Sat</Col>
        </Row>
        {rows}
      </div>
    );
  };

  const renderWeeklyView = () => {
    return (
      <div className="weekly-view">
        <Table
          dataSource={occupancyBatchData}
          rowKey="key"
          bordered
          pagination={false}
          columns={[
            {
              title: "Week",
              dataIndex: "periodStart",
              key: "week",
              render: (start, record: any) =>
                `${moment(start).format("MMM D")} - ${moment(record.periodEnd).format("MMM D, YYYY")}`,
            },
            {
              title: "Occupancy",
              dataIndex: "occupancy",
              key: "occupancy",
              render: (val) => (val !== null ? `${val}%` : "-"),
            },
            {
              title: "Client Forecast",
              dataIndex: "clientForecast",
              key: "clientForecast",
              render: (val) => (val !== null ? `${val}%` : "-"),
            },
          ]}
        />
      </div>
    );
  };

  const renderMonthlyView = () => {
    return (
      <div className="monthly-view">
        <Table
          dataSource={occupancyBatchData}
          rowKey="key"
          bordered
          pagination={false}
          columns={[
            {
              title: "Month",
              dataIndex: "periodStart",
              key: "month",
              render: () => occupancyMonth.format("MMMM YYYY"),
            },
            {
              title: "Occupancy",
              dataIndex: "occupancy",
              key: "occupancy",
              render: (val) => (val !== null ? `${val}%` : "-"),
            },
            {
              title: "Client Forecast",
              dataIndex: "clientForecast",
              key: "clientForecast",
              render: (val) => (val !== null ? `${val}%` : "-"),
            },
          ]}
        />
      </div>
    );
  };

  const renderOccupancyTab = () => {
    // Format the occupancy data for display
    const existingOccupancyData = occupancyData?.sensorflow_location_occ_customer_data || [];

    const columns = [
      {
        title: "Period Start",
        dataIndex: "periodStart",
        key: "periodStart",
        render: (text: string) => moment(text).format("MMM D, YYYY"),
      },
      {
        title: "Period End",
        dataIndex: "periodEnd",
        key: "periodEnd",
        render: (text: string) => moment(text).format("MMM D, YYYY"),
      },
      {
        title: "Occupancy %",
        dataIndex: "occupancy",
        key: "occupancy",
        render: (value: number) => (value !== null ? `${value}%` : "-"),
      },
      {
        title: "Client Forecast",
        dataIndex: "clientForecast",
        key: "clientForecast",
        render: (value: number) => (value !== null ? `${value}%` : "-"),
      },
    ];

    return (
      <div className="occupancy-tab">
        <div className="occupancy-header">
          <div className="date-filter">
            <Space>
              <RangePicker
                value={occupancyDateRange as any}
                onChange={handleOccupancyDateRangeChange}
                allowClear={false}
              />
            </Space>
          </div>
          <Button
            type="primary"
            onClick={() => {
              generateOccupancyBatchData();
              setIsOccupancyModalVisible(true);
            }}
            data-testid="add-edit-occupancy-data"
          >
            Add/Edit Occupancy Data
          </Button>
        </div>

        <Table
          dataSource={existingOccupancyData}
          columns={columns}
          rowKey="id"
          loading={occLoading}
          pagination={{ pageSize: 10 }}
        />

        <Modal
          title="Manage Occupancy Data"
          visible={isOccupancyModalVisible}
          onCancel={() => setIsOccupancyModalVisible(false)}
          footer={null}
          width={1000}
        >
          <div className="occupancy-input-container">
            <div className="mode-selector">
              <Space direction="vertical" style={{ marginBottom: 16 }}>
                <Text>View Mode:</Text>
                <Select value={occupancyViewMode} onChange={setOccupancyViewMode} style={{ width: 150 }}>
                  <Option value="calendar">Calendar</Option>
                  <Option value="weekly">Weekly</Option>
                  <Option value="monthly">Monthly</Option>
                </Select>
              </Space>
              <Space direction="vertical">
                <Text>Select Month:</Text>
                <DatePicker
                  picker="month"
                  value={occupancyMonth as any}
                  onChange={(date) => date && setOccupancyMonth(date)}
                  style={{ width: "100%" }}
                />
              </Space>
            </div>

            <Tabs activeKey={activeTab} onChange={setActiveTab} centered>
              <TabPane tab="Data View" key="view">
                {occupancyViewMode === "calendar" && renderCalendarView()}
                {occupancyViewMode === "weekly" && renderWeeklyView()}
                {occupancyViewMode === "monthly" && renderMonthlyView()}
              </TabPane>
              <TabPane tab="Data Input" key="input">
                {renderInputView()}
              </TabPane>
            </Tabs>
          </div>
        </Modal>
      </div>
    );
  };

  return <div className="occupancy-data-component">{renderOccupancyTab()}</div>;
};

export default OccupancyDataComponent;
