import { Card, Spin, Tabs, Typography } from "antd";
import React from "react";
import useSurveyResponseCheck from "../../../hooks/useSurveyResponseCheck";
import ConsumptionData from "./ConsumptionDataComponent";
import OccupancyData from "./OccupancyDataComponent";
import OnboardingComponent from "./OnboardingComponent";
import "./SmartBEA.scss";

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

interface SmartBEAProps {
  locationId: string;
}

const SmartBEA: React.FC<SmartBEAProps> = ({ locationId }) => {
  const { isOnboarded, loading } = useSurveyResponseCheck(locationId);

  const renderContent = () => {
    if (loading) {
      return (
        <div className="loading-container">
          <Spin size="large" tip="Loading onboarding status..." />
        </div>
      );
    }

    if (!isOnboarded) {
      return (
        <Tabs defaultActiveKey="onboarding">
          <TabPane tab="Onboarding" key="onboarding">
            <OnboardingComponent locationId={locationId} />
          </TabPane>
        </Tabs>
      );
    }

    return (
      <Tabs defaultActiveKey="consumption">
        <TabPane tab="Dashboard" key="dashboard">
          <div className="dashboard-content">
            <p>Dashboard view coming soon</p>
          </div>
        </TabPane>

        <TabPane tab="Consumption Data" key="consumption">
          <ConsumptionData locationId={locationId} />
        </TabPane>

        <TabPane tab="Occupancy Data" key="occupancy">
          <OccupancyData locationId={locationId} />
        </TabPane>
      </Tabs>
    );
  };

  return (
    <div className="smart-bea-container">
      <Card>
        <Typography>
          <Title level={3}>Smart Building Energy Analytics</Title>
          <Paragraph>
            View and manage consumption data for this location. You can add new records or edit existing actual values
            and client forecasts.
          </Paragraph>
        </Typography>

        {renderContent()}
      </Card>
    </div>
  );
};

export default SmartBEA;
