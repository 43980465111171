import { useEffect, useState } from "react";
import { useGetSurveyResponsesLazyQuery } from "pacts/app-webcore/hasura-webcore.graphql";

/**
 * Hook to check if a location has any survey responses
 * Used to determine if a location is onboarded
 */
const useSurveyResponseCheck = (locationId: string) => {
  const [isOnboarded, setIsOnboarded] = useState<boolean | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [getSurveyResponses, { loading: queryLoading }] = useGetSurveyResponsesLazyQuery({
    variables: {
      locationId,
      limit: 1, // We only need to know if there's at least one response
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const hasResponses = data?.sensorflow_survey_responses && data.sensorflow_survey_responses.length > 0;
      setIsOnboarded(hasResponses);
      setLoading(false);
    },
    onError: () => {
      setIsOnboarded(false);
      setLoading(false);
    },
  });

  useEffect(() => {
    if (locationId) {
      getSurveyResponses();
    }
  }, [locationId, getSurveyResponses]);

  return {
    isOnboarded,
    loading: loading || queryLoading,
  };
};

export default useSurveyResponseCheck;
