import { Button, Col, Row } from "antd";
import Can from "components/Can/Can";
import ModalWrapper from "components/Modal/Wrapper";
import useModalWrapperTrigger from "hooks/use-modal-wrapper-trigger";
import { useLocationDomoEmbedsQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import { Permission } from "pacts/permission";
import React from "react";
import { DomoEmbed } from "./Analytics.d";
import InsightsDashboardList from "./InsightsDashboardList/InsightsDashboardList";
import ManageEmbeddingsModal from "./ManageEmbeddingsModal/ManageEmbeddingsModal";
import ManageLocationDashboardsModal from "./ManageLocationDashboardsModal/ManageLocationDashboardsModal";

type AnalyticsProps = {
  locationId: string;
};

const Analytics: React.FC<AnalyticsProps> = ({ locationId }) => {
  const { data, loading, refetch } = useLocationDomoEmbedsQuery({
    variables: { where: { location_id: { _eq: locationId } } },
  });

  const [manageLocationDashboardsModalRef, openManageLocationDashboardsModal] = useModalWrapperTrigger();
  const [manageEmbeddingsModalRef, openManageEmbeddingsModalRef] = useModalWrapperTrigger();

  if (loading) return <p>Loading...</p>;

  const locationDomoEmbeds = data?.locationDomoEmbeds.map(({ domo_embed }: { domo_embed: DomoEmbed }) => domo_embed);
  return (
    <>
      <Row justify="end">
        <Can
          requiredPermission={Permission.LOCATION_EDIT}
          yes={
            <>
              <Col>
                <Button type="primary" onClick={openManageEmbeddingsModalRef} className="mr-s">
                  Manage Embeddings
                </Button>
              </Col>
              <Col>
                <Button type="primary" onClick={openManageLocationDashboardsModal}>
                  Manage Location Dashboards
                </Button>
              </Col>
            </>
          }
        />
      </Row>
      <InsightsDashboardList locationId={locationId} items={locationDomoEmbeds || []} />
      <ModalWrapper
        onCancelled={refetch}
        ref={manageLocationDashboardsModalRef}
        modal={ManageLocationDashboardsModal}
        props={{ locationId, locationDomoEmbeds, refetch }}
      />
      <ModalWrapper onCancelled={refetch} ref={manageEmbeddingsModalRef} modal={ManageEmbeddingsModal} />
    </>
  );
};

export default Analytics;
