import { Alert, Button, Col, Form, Input, message, Modal, Row } from "antd";
import { FormInstance } from "antd/es/form";
import errorHandler from "errorHandler";
import React from "react";
import logger from "services/logger/logger";
import { useUpdatePropertyBillingPeriodsMutation } from "../../../pacts/app-webcore/hasura-webcore.graphql";

// Define the interface for property billing periods
export interface BillingPeriodFormData {
  electricityBillingPeriodStart: number | null;
  electricityBillingPeriodEnd: number | null;
  waterBillingPeriodStart: number | null;
  waterBillingPeriodEnd: number | null;
  gasBillingPeriodStart: number | null;
  gasBillingPeriodEnd: number | null;
  wasteBillingPeriodStart: number | null;
  wasteBillingPeriodEnd: number | null;
}

interface BillingPeriodsModalProps {
  visible: boolean;
  onClose: () => void;
  onSuccess: (data: BillingPeriodFormData) => void;
  form: FormInstance;
  initialValues?: BillingPeriodFormData;
  locationId: string;
}

const BillingPeriodsModal: React.FC<BillingPeriodsModalProps> = ({
  visible,
  onClose,
  onSuccess,
  form,
  initialValues,
  locationId,
}) => {
  const [updatePropertyBillingPeriods] = useUpdatePropertyBillingPeriodsMutation();

  React.useEffect(() => {
    if (visible && initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [visible, initialValues, form]);

  const handleSubmit = async (values: BillingPeriodFormData) => {
    try {
      // Show loading message
      const loadingMessage = message.loading("Updating billing periods settings...", 0);

      // Call the mutation to update billing periods in the database
      await updatePropertyBillingPeriods({
        variables: {
          locationId,
          electricityBillingPeriodStart: values.electricityBillingPeriodStart,
          electricityBillingPeriodEnd: values.electricityBillingPeriodEnd,
          waterBillingPeriodStart: values.waterBillingPeriodStart,
          waterBillingPeriodEnd: values.waterBillingPeriodEnd,
          gasBillingPeriodStart: values.gasBillingPeriodStart,
          gasBillingPeriodEnd: values.gasBillingPeriodEnd,
          wasteBillingPeriodStart: values.wasteBillingPeriodStart,
          wasteBillingPeriodEnd: values.wasteBillingPeriodEnd,
        },
      });

      // Close loading message
      loadingMessage();

      // Show success message
      message.success("Billing periods settings updated successfully");

      // Call the onSuccess callback with the form values
      onSuccess(values);
    } catch (error: any) {
      if (typeof errorHandler === "object" && "handleError" in errorHandler) {
        // @ts-ignore
        errorHandler.handleError(error);
      } else {
        logger.error("Error updating billing periods settings:", error);
      }
      message.error("Error updating billing periods settings");
    }
  };

  return (
    <Modal title="Billing Periods Settings" open={visible} onCancel={onClose} footer={null} width={800}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Alert
          message="Default Billing Cycle Configuration"
          description="Configure default billing periods for each utility type. These settings will be used when automatically calculating billing periods for new records."
          type="info"
          showIcon
          style={{ marginBottom: 24 }}
        />

        <div style={{ marginBottom: 32 }}>
          <h3 style={{ marginBottom: 16 }}>Electricity Billing Cycle</h3>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="electricityBillingPeriodStart"
                label="Billing Start Day"
                tooltip="The day of the month when the billing period starts (1-31)"
              >
                <Input type="number" min={1} max={31} placeholder="e.g., 15" suffix="Day of Month" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="electricityBillingPeriodEnd"
                label="Billing End Day"
                tooltip="The day of the month when the billing period ends (1-31)"
              >
                <Input type="number" min={1} max={31} placeholder="e.g., 14" suffix="Day of Month" />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div style={{ marginBottom: 32 }}>
          <h3 style={{ marginBottom: 16 }}>Water Billing Cycle</h3>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="waterBillingPeriodStart"
                label="Billing Start Day"
                tooltip="The day of the month when the billing period starts (1-31)"
              >
                <Input type="number" min={1} max={31} placeholder="e.g., 1" suffix="Day of Month" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="waterBillingPeriodEnd"
                label="Billing End Day"
                tooltip="The day of the month when the billing period ends (1-31)"
              >
                <Input type="number" min={1} max={31} placeholder="e.g., 31" suffix="Day of Month" />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div style={{ marginBottom: 32 }}>
          <h3 style={{ marginBottom: 16 }}>Gas Billing Cycle</h3>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="gasBillingPeriodStart"
                label="Billing Start Day"
                tooltip="The day of the month when the billing period starts (1-31)"
              >
                <Input type="number" min={1} max={31} placeholder="e.g., 10" suffix="Day of Month" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="gasBillingPeriodEnd"
                label="Billing End Day"
                tooltip="The day of the month when the billing period ends (1-31)"
              >
                <Input type="number" min={1} max={31} placeholder="e.g., 9" suffix="Day of Month" />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div style={{ marginBottom: 32 }}>
          <h3 style={{ marginBottom: 16 }}>Waste Billing Cycle</h3>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="wasteBillingPeriodStart"
                label="Billing Start Day"
                tooltip="The day of the month when the billing period starts (1-31)"
              >
                <Input type="number" min={1} max={31} placeholder="e.g., 1" suffix="Day of Month" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="wasteBillingPeriodEnd"
                label="Billing End Day"
                tooltip="The day of the month when the billing period ends (1-31)"
              >
                <Input type="number" min={1} max={31} placeholder="e.g., 31" suffix="Day of Month" />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div style={{ display: "flex", justifyContent: "flex-end", gap: "12px", marginTop: "24px" }}>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" htmlType="submit">
            Save Settings
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default BillingPeriodsModal;
