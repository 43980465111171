import { message, Space, Switch, Typography } from "antd";
import classnames from "classnames";
import { InformationIcon } from "components/Icons";
import Table from "components/Table/Table";
import useSensorflowLocation from "hooks/use-sensorflow-location";
import isNil from "lodash/isNil";
import moment from "moment";
import { useCancelPrecoolCommandMutation } from "pacts/app-webcore/hasura-webcore.graphql";
import { PositionFunction } from "pages/Groups/Components/GroupModal";
import React, { useCallback, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { PositionType } from "types/types";
import useBreakpoint from "../../../hooks/use-breakpoint";
import usePrecoolCountDown from "../hooks/use-precool-countdown";
import { formatTemp, getAutomationModeText, getUnitDisplayText, NotAvailable, NotUpdated } from "../key.helper";
import { AutomationMode, EFeatureName } from "../types";
import "./comfortplus.scss";
import ComfortPlusKeyDetailModal, { EReservationStatus } from "./ComfortPlusDetailModal/ComfortPlusDetailModal";
import { Key } from "./KeyList.d";
import { getACView, getDoorStatusIcon, getIsDeviatedFromDefaultIcon, getOccupancyStateIcon } from "./KeyList.helper";
import KeyMobileItem from "./KeyMobileItem";

export const isComfortPlusActivation = (reservation: Key["reservation"]) => {
  return (
    reservation?.[0] &&
    reservation[0].comfortplusActivations?.length > 0 &&
    moment(reservation[0].checkOutAt).isAfter(moment()) &&
    reservation[0].status !== EReservationStatus.CANCELLED
  );
};

const ComforPlusInfoBtn = (props: { reservation: Key["reservation"]; keyName: string }) => {
  const [openComfortPlusKeyDetailModal, setOpenComfortPlusKeyDetailModal] = useState<boolean>(false);
  const { reservation, keyName } = props;

  return (
    <>
      <span>
        <InformationIcon onClick={() => setOpenComfortPlusKeyDetailModal(true)} />
      </span>
      <ComfortPlusKeyDetailModal
        keyName={keyName}
        reservation={reservation}
        setOpenComfortPlusKeyDetailModal={(value) => setOpenComfortPlusKeyDetailModal(value)}
        openComfortPlusKeyDetailModal={openComfortPlusKeyDetailModal}
      />
    </>
  );
};

const KeyListTable = (props: any) => {
  const { locationId } = useParams() as { locationId: string };
  const screen = useBreakpoint();
  const { showDoorColumn, keysLoading } = props;
  const { location } = useSensorflowLocation();

  const { currentTime, getRemainingTime } = usePrecoolCountDown();

  const [cancelPrecool] = useCancelPrecoolCommandMutation({
    onCompleted: (data) => {
      if (data.cancelPrecoolCommand) {
        message.success("Cancel precool command successfully");
      }
    },
  });

  const getPositionLink = (record: Key) => {
    if (record.type === "room") {
      return <Link to={`/locations/${locationId}/keys/${record.parentId}`}>{record.positionName}</Link>;
    }
    return <Link to={`/locations/${locationId}/keys/${record.positionId}`}>{record.positionName}</Link>;
  };

  const getRecordForDisplay = (record: Key): Key | null => {
    if (record.type === "key" && record.rooms.length === 1) {
      return record.rooms[0] as any as Key;
    }
    if (record.type === "room") {
      return record;
    }
    return null;
  };

  const handleOnCancel = useCallback(
    async (e: React.MouseEvent<HTMLSpanElement>, positionId: string) => {
      e.preventDefault();
      await cancelPrecool({ variables: { roomIds: [positionId] } });
    },
    [cancelPrecool]
  );

  const COLUMN_CONFIG = [
    {
      title: "Key/Room",
      isVisible: true,
      render: (record: Key) => {
        return getPositionLink(record);
      },
      isShowOnMobile: false,
      ellipsis: true,
      width: 200,
      fixed: "left",
    },
    {
      title: "Occupancy",
      render: (record: Key) => {
        const key = getRecordForDisplay(record);
        if (key) {
          if (!key.hasOccupancy) {
            return <NotAvailable />;
          }
          return key.hasOccupancy ? getOccupancyStateIcon(key.occupancyState) : "";
        }
      },
      isVisible: true,
      isShowOnMobile: false,
      align: "center",
      width: 130,
    },
    {
      title: "Door",
      render: (record: Key) => {
        const key = getRecordForDisplay(record);
        if (key) {
          return key.hasDoor ? getDoorStatusIcon(key.doorStatus) : "";
        }
      },
      isVisible: showDoorColumn,
      isShowOnMobile: false,
      align: "center",
      width: 80,
    },
    {
      title: "HVAC Settings",
      render: (record: Key) => {
        const key = getRecordForDisplay(record);
        if (key) {
          return getACView(
            key,
            key.acPrecoolStatus,
            getRemainingTime(key.acPrecoolExpiredTime, key.acPrecoolStatus, currentTime, null),
            (e) => handleOnCancel(e, key.positionId)
          );
        }
      },
      isVisible: true,
      isShowOnMobile: false,
      width: 180,
    },
    {
      title: "Ambient Temperature",
      render: (record: Key) => {
        const key = getRecordForDisplay(record);
        if (key) {
          if (!key.hasHVAC) {
            return <NotAvailable />;
          }
          if (!isNil(key.ambientTemperature) && key.hasHVAC) {
            return formatTemp(key.ambientTemperature);
          }
          return <NotUpdated />;
        }
      },
      isVisible: true,
      isShowOnMobile: false,
      width: 130,
    },
    {
      title: "Ambient Humidity",
      render: (record: Key) => {
        const key = getRecordForDisplay(record);
        if (key) {
          if (!key.hasHVAC) {
            return <NotAvailable />;
          }
          if (!isNil(key.ambientHumidity)) {
            return getUnitDisplayText(key.ambientHumidity, "% Rh");
          }
          return <NotUpdated />;
        }
      },
      isVisible: true,
      isShowOnMobile: false,
      width: 130,
    },
    {
      title: "Automation Mode",
      render: (record: Key) => {
        const key = getRecordForDisplay(record);
        if (key) {
          return (
            <span className={classnames({ "text-volcano": key.automationMode === AutomationMode.Disabled })}>
              {!isNil(key.automationMode) ? (
                getAutomationModeText(key.automationMode!)
              ) : (
                <span className="text-warning">Not Updated</span>
              )}
            </span>
          );
        }
      },
      isVisible: true,
      isShowOnMobile: false,
      width: 160,
    },
    {
      render: (record: Key) => {
        const { reservation, type } = record;
        if (type === "room") return;
        return (
          <>
            <Space>
              <Switch
                checked={!!isComfortPlusActivation(reservation)}
                onChange={(checked) => props?.onComfortPLusChange(checked, record)}
              />
              <Typography.Text>{isComfortPlusActivation(reservation) ? "Active" : "Inactive"}</Typography.Text>
              {isComfortPlusActivation(reservation) && (
                <ComforPlusInfoBtn reservation={reservation} keyName={record.positionName} />
              )}
            </Space>
          </>
        );
      },
      isVisible: !!(location?.enabledFeatures ?? []).find((f) => f === EFeatureName.ComfortPlus),
      isShowOnMobile: false,
      align: "left",
      width: 250,
      title: "ComfortPlus",
    },
    {
      title: "Group",
      render: (record: Key) => {
        const { parentPosition } = record;
        if (
          parentPosition &&
          parentPosition.positionFunction === PositionFunction.GROUP &&
          (parentPosition.positionType === PositionType.FLOOR ||
            parentPosition.positionType === PositionType.GROUP ||
            parentPosition.positionType === PositionType.ZONE)
        ) {
          return parentPosition.positionName;
        }
      },
      isVisible: true,
      isShowOnMobile: false,
      width: 150,
      ellipsis: true,
    },
    {
      title: "Key Category",
      render: (record: Key) => {
        return record.keyCategoryPositionMapping?.categoryName;
      },
      isVisible: true,
      isShowOnMobile: false,
      width: 150,
      ellipsis: true,
    },
    {
      title: "Configuration",
      render: (record: Key) => {
        const key = getRecordForDisplay(record);
        if (key) {
          return key.isDefaultConfig === true ? getIsDeviatedFromDefaultIcon(key.isDefaultConfig) : "";
        }
      },
      isVisible: true,
      isShowOnMobile: false,
      align: "center",
      width: 130,
    },
    {
      render: (record: Key) => {
        const key = getRecordForDisplay(record);
        return (
          <KeyMobileItem
            locationId={locationId}
            record={record}
            onCancel={(e) => handleOnCancel(e, key?.positionId || "")}
          />
        );
      },
      isVisible: true,
      isShowOnMobile: true,
    },
  ];

  const columns = COLUMN_CONFIG.filter(
    ({ isVisible, isShowOnMobile }) => isVisible && (screen.desktopUp ? !isShowOnMobile : isShowOnMobile)
  );

  return (
    <Table
      {...props}
      scroll={{ x: screen.desktopUp ? 1500 : true }}
      rowClassName={(record: Key) => {
        const classes = record.type === "room" ? ["room-row"] : ["key-row"];
        if (record.type === "room" && record.roomCount === 1) {
          classes.push("d-none");
        }
        if (record.type === "key" && record.roomCount <= 1) {
          classes.push("hide-expand-icon");
        }
        return classes;
      }}
      loading={keysLoading}
      columns={columns}
      dataItemName="keys"
      rowKey="positionId"
    />
  );
};

export default KeyListTable;
