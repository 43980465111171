import { UploadOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Collapse, Descriptions, Input, message, Modal, Spin, Typography, Upload } from "antd";
import useSurveyResponseProcessor from "hooks/use-survey-response-processor";
import React, { useEffect, useState } from "react";
import { isLocationExistsError, isUserExistsError, validateUserFields } from "../../services/survey/surveyUtils";
import AddressMapConfirmation from "../AddressMapConfirmation";
import "./SurveyResponseProcessor.scss";

const { Title, Text } = Typography;
const { Panel } = Collapse;

interface SurveyResponseProcessorProps {
  surveyResponse?: any;
  onComplete?: (result: { locationId: string; userId: string }) => void;
}

/**
 * Component to process a survey response and create a location and user
 * This component can either:
 * 1. Process a provided survey response object directly
 * 2. Allow the user to upload a survey response file
 */
const SurveyResponseProcessor: React.FC<SurveyResponseProcessorProps> = ({ surveyResponse = null, onComplete }) => {
  const [processed, setProcessed] = useState(false);
  const [processingError, setProcessingError] = useState<Error | null>(null);
  const [userExists, setUserExists] = useState(false);
  const [coordinates, setCoordinates] = useState<{ lat: number; lng: number } | null>(null);
  const [showMap, setShowMap] = useState(false);
  const [localSurveyData, setLocalSurveyData] = useState<any>(surveyResponse || {});
  const [showLocationNameModal, setShowLocationNameModal] = useState(false);
  const [newLocationName, setNewLocationName] = useState("");
  const [isExistingLocation, setIsExistingLocation] = useState(false);
  const [existingLocationName, setExistingLocationName] = useState("");

  // Initialize state with survey response data
  useEffect(() => {
    if (surveyResponse) {
      setLocalSurveyData(surveyResponse);
      // Check if we're processing an existing location
      if (surveyResponse.existing_location_id) {
        setIsExistingLocation(true);
      }
    }
  }, [surveyResponse]);

  const { processSurveyResponse, result: hookResult, loading } = useSurveyResponseProcessor();

  // Extract values from result
  const locationId = hookResult?.locationId;
  const userId = hookResult?.userId;
  const userExistsFromHook = hookResult?.userExists;

  // Function to handle process errors
  const handleProcessError = (processError: Error) => {
    setProcessingError(processError);

    // Use the shared utility functions for error checking
    if (isUserExistsError(processError)) {
      setUserExists(true);
      return;
    }

    if (isLocationExistsError(processError)) {
      const match = processError.message.match(/existing name: (.*)/i);
      if (match && match[1]) {
        setExistingLocationName(match[1]);
      } else {
        // If we can't extract the name, use a default approach
        const currentLocationName = localSurveyData.location_name || "Location";
        const timestamp = new Date().toISOString().slice(0, 10).replace(/-/g, "");
        setNewLocationName(`${currentLocationName}_${timestamp}`);
      }

      setShowLocationNameModal(true);
    }
  };

  // React to changes in the hook result
  useEffect(() => {
    if (hookResult?.locationId) {
      setProcessed(true);

      // For existing locations, we may not get a userId back
      if (isExistingLocation || hookResult?.userId) {
        if (onComplete) {
          onComplete({
            locationId: hookResult.locationId,
            // For existing locations, pass a placeholder user ID
            userId: hookResult.userId || "existing-user",
          });
        }
      }
    } else if (hookResult?.userExists) {
      // Handle user exists error
      setUserExists(true);
    } else if (hookResult?.error) {
      // We only need to set the error here, not display a message
      // The error message will be displayed by the Alert component in the render
      setProcessingError(hookResult.error);

      // Don't call handleProcessError here to avoid duplicate error handling
      // handleProcessError is only for errors that occur during direct process calls
    }
  }, [hookResult, onComplete, isExistingLocation]);

  // Handle file upload
  const handleUpload = (info: any) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);

      // Parse the file content as JSON
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const content = e.target?.result as string;
          const parsedContent = JSON.parse(content);
          setLocalSurveyData(parsedContent);
        } catch (parseError) {
          message.error("Failed to parse file content as JSON");
        }
      };
      reader.readAsText(info.file.originFileObj);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  // Add the missing handleBeforeUpload function
  const handleBeforeUpload = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const jsonData = JSON.parse(e.target?.result as string);
        setLocalSurveyData(jsonData);
      } catch (hookError) {
        message.error("Failed to parse JSON file");
      }
    };
    reader.readAsText(file);
    return false; // Prevent automatic upload
  };

  const handleCoordinatesConfirmed = (newCoordinates: { lat: number; lng: number }) => {
    setCoordinates(newCoordinates);
    // Update the local survey data with the confirmed coordinates
    setLocalSurveyData((prev: Record<string, any>) => ({
      ...prev,
      latitude: newCoordinates.lat.toString(),
      longitude: newCoordinates.lng.toString(),
    }));
  };

  // Helper function to process with specific data
  const processWithData = async (data: any) => {
    try {
      // Clear any previous errors and states
      setProcessingError(null);
      setUserExists(false);

      // Use the hook's processSurveyResponse function which already has access to the service
      await processSurveyResponse(data);
    } catch (processError) {
      if (processError instanceof Error) {
        // Use the handleProcessError function to handle the error consistently
        handleProcessError(processError);
      } else {
        // For non-Error objects, create a proper Error
        const unknownError = new Error("An unknown error occurred while processing the survey");
        handleProcessError(unknownError);
      }
    }
  };

  // Handle location name change and resubmit
  const handleLocationNameChange = () => {
    if (!newLocationName.trim()) {
      setProcessingError(new Error("Please enter a valid location name"));
      return;
    }

    // Update the survey response with the new location name
    const updatedSurveyResponse = {
      ...localSurveyData,
      location_name: newLocationName,
    };

    // Close the modal first
    setShowLocationNameModal(false);

    // Clear any previous errors
    setProcessingError(null);

    // Process the survey with the updated location name directly
    // This avoids the race condition with the state update
    message.info("Retrying with the new location name...");

    // Use the updated data directly instead of waiting for state update
    setTimeout(() => {
      processWithData(updatedSurveyResponse);
    }, 500);

    // Also update the state for future reference
    setLocalSurveyData(updatedSurveyResponse);
  };

  // Update the handleProcess function
  const handleProcess = async () => {
    try {
      // Clear any previous errors
      setProcessingError(null);
      setUserExists(false);

      // For existing locations, we don't need user information
      if (!isExistingLocation) {
        try {
          // Use the shared validation utility
          validateUserFields(localSurveyData);
        } catch (error) {
          setProcessingError(error instanceof Error ? error : new Error("Invalid survey data"));
          return;
        }
      }

      // If we have address information but no coordinates, show the map for confirmation
      if (
        !showMap && // Only check this if we're not already showing the map
        localSurveyData.address_1 &&
        !localSurveyData.latitude &&
        !localSurveyData.longitude &&
        !coordinates
      ) {
        setShowMap(true);
        return;
      }

      // If we're showing the map and have coordinates, process the survey
      if (showMap && coordinates) {
        // We already have coordinates from the map, proceed with processing
      } else if (showMap && !coordinates) {
        // We're showing the map but don't have coordinates yet
        setProcessingError(new Error("Please confirm coordinates on the map before proceeding"));
        return;
      }

      // Use the helper function with the current state
      await processWithData(localSurveyData);
    } catch (handlerError) {
      if (handlerError instanceof Error) {
        // Use the handleProcessError function to handle the error consistently
        handleProcessError(handlerError);
      } else {
        // For non-Error objects, create a proper Error
        const unknownError = new Error("An unknown error occurred while processing the survey");
        handleProcessError(unknownError);
      }
    }
  };

  // Extract address information for the map
  const addressForMap = {
    address1: localSurveyData.address_1 || "",
    address2: localSurveyData.address_2 || "",
    city: localSurveyData.city || "",
    state: localSurveyData.state || "",
    zipCode: localSurveyData.zip_code || "",
    country: localSurveyData.country || "",
  };

  // Check if we have initial coordinates from the survey
  const initialMapCoordinates =
    localSurveyData.latitude && localSurveyData.longitude
      ? {
          lat: parseFloat(localSurveyData.latitude),
          lng: parseFloat(localSurveyData.longitude),
        }
      : null;

  // Determine if we should show a user exists error - only relevant for new locations
  const showUserExistsError = !isExistingLocation && (userExists || userExistsFromHook);

  // Get the email from the survey response for error message
  const userEmail = localSurveyData?.email || "";

  // Add a useEffect to set the newLocationName when existingLocationName changes
  useEffect(() => {
    if (existingLocationName) {
      // Generate a unique name based on the existing name
      const timestamp = new Date().toISOString().slice(0, 10).replace(/-/g, "");
      setNewLocationName(`${existingLocationName}_${timestamp}`);
    }
  }, [existingLocationName]);

  // Function to reset the processor state
  const resetProcessor = () => {
    setProcessed(false);
    setProcessingError(null);
    setUserExists(false);
    setLocalSurveyData({});
    setShowMap(false);
    setCoordinates(null);
    setShowLocationNameModal(false);
    setNewLocationName("");
    setExistingLocationName("");
    setIsExistingLocation(false);
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <Spin size="large" />
        <div style={{ marginTop: 16 }}>
          <Text>Processing survey response...</Text>
        </div>
      </div>
    );
  }

  return (
    <div className="survey-response-processor" style={{ width: "100%" }}>
      {!localSurveyData || Object.keys(localSurveyData).length === 0 ? (
        <Card style={{ width: "100%" }}>
          <Title level={4}>Upload Survey Response</Title>
          <Text>
            Please upload a survey response JSON file to process. The file should contain location and user information.
          </Text>
          <div style={{ marginTop: 16 }}>
            <Upload
              accept=".json"
              beforeUpload={handleBeforeUpload}
              onChange={handleUpload}
              showUploadList={false}
              maxCount={1}
            >
              <Button icon={React.createElement(UploadOutlined)} type="primary">
                Upload JSON File
              </Button>
            </Upload>
          </div>
          <div style={{ marginTop: 8 }}>
            <Text type="secondary">
              The file should be in JSON format and include fields like name, email, location information, and property
              details.
            </Text>
          </div>
        </Card>
      ) : (
        <>
          {processingError && (
            <Alert
              message="Processing Error"
              description={processingError.message}
              type="error"
              showIcon
              style={{ marginBottom: 16 }}
            />
          )}

          {showUserExistsError && !loading && (
            <Alert
              type="error"
              message="User Already Exists"
              description={
                <div>
                  <p>
                    A user with the email <strong>{userEmail}</strong> already exists in the system.
                  </p>
                  <p>
                    Cannot create a new location for an existing user. Please use a different email address or update
                    the existing user&apos;s information through the <a href="/manage-users">user management page</a>.
                  </p>
                </div>
              }
              style={{ marginBottom: 16 }}
            />
          )}

          {processed && locationId && (isExistingLocation || userId) && !loading && (
            <Alert
              type="success"
              message="Survey Processed Successfully"
              description={
                <div>
                  {isExistingLocation ? (
                    <>
                      <p>
                        Location with ID: <code>{locationId}</code> has been updated.
                      </p>
                      <p>Processing results:</p>
                      <ul>
                        <li>Updated property information</li>
                        <li>Processed consumption data (if provided)</li>
                        <li>Processed occupancy data (if provided)</li>
                        <li>Stored survey response for future reference</li>
                      </ul>
                    </>
                  ) : (
                    <>
                      <p>
                        Location created with ID: <code>{locationId}</code>
                      </p>
                      <p>
                        User created with ID: <code>{userId}</code>
                      </p>
                      <p>Processing results:</p>
                      <ul>
                        <li>Created new location with property information</li>
                        <li>Created new user and associated with the location</li>
                        <li>Processed consumption data (if provided)</li>
                        <li>Processed occupancy data (if provided)</li>
                        <li>Stored survey response for future reference</li>
                      </ul>
                    </>
                  )}
                  <div style={{ marginTop: 16, textAlign: "center" }}>
                    <Button onClick={resetProcessor} type="primary">
                      Process Another Survey
                    </Button>
                  </div>
                </div>
              }
              style={{ marginBottom: 16 }}
            />
          )}

          {showMap && !processed && !loading && (
            <Card style={{ width: "100%", marginBottom: 16 }}>
              <div className="map-confirmation-container">
                <Title level={4}>Confirm Location on Map</Title>
                <Text type="secondary">
                  Please confirm the location coordinates derived from the address. You can drag the marker to adjust
                  the exact location.
                </Text>

                <div style={{ marginTop: 16, marginBottom: 24 }}>
                  <AddressMapConfirmation
                    address={addressForMap}
                    onCoordinatesConfirmed={handleCoordinatesConfirmed}
                    initialCoordinates={initialMapCoordinates}
                  />
                </div>

                <div style={{ textAlign: "center", marginTop: 24 }}>
                  <Button type="primary" size="large" onClick={handleProcess} disabled={!coordinates}>
                    Confirm Location and Process Survey
                  </Button>
                </div>
              </div>
            </Card>
          )}

          {!processed && !loading && !showMap && Object.keys(localSurveyData).length > 0 && (
            <Card style={{ width: "100%", marginBottom: 16 }}>
              <div className="survey-summary-container">
                <Title level={4}>{isExistingLocation ? "Location Update Summary" : "Survey Response Summary"}</Title>
                <Text type="secondary">Please review the information below before processing</Text>

                <Collapse defaultActiveKey={["1", "2", "3", "4"]} style={{ marginTop: 16, width: "100%" }}>
                  {/* Only show User Information panel for new locations */}
                  {!isExistingLocation && (
                    <Panel header="User Information" key="1">
                      <Descriptions bordered column={1} style={{ width: "100%" }}>
                        <Descriptions.Item label="Name">
                          {localSurveyData.first_name} {localSurveyData.last_name}
                        </Descriptions.Item>
                        <Descriptions.Item label="Email">{localSurveyData.email || "N/A"}</Descriptions.Item>
                        <Descriptions.Item label="Role">{localSurveyData.company_role || "N/A"}</Descriptions.Item>
                        <Descriptions.Item label="Phone">{localSurveyData.phone_number || "N/A"}</Descriptions.Item>
                      </Descriptions>
                    </Panel>
                  )}

                  <Panel header="Property Information" key={isExistingLocation ? "1" : "2"}>
                    <Descriptions bordered column={1} style={{ width: "100%" }}>
                      <Descriptions.Item label="Property Name">
                        {localSurveyData.location_name || "N/A"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Organization">
                        {localSurveyData.organization_name || localSurveyData.location_name || "N/A"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Address">
                        {(localSurveyData.address_1 && (
                          <>
                            {localSurveyData.address_1}
                            <br />
                            {localSurveyData.address_2 && (
                              <>
                                {localSurveyData.address_2}
                                <br />
                              </>
                            )}
                            {localSurveyData.city}, {localSurveyData.state} {localSurveyData.zip_code}
                            <br />
                            {localSurveyData.country}
                          </>
                        )) ||
                          "N/A"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Coordinates">
                        {localSurveyData.latitude && localSurveyData.longitude
                          ? `${localSurveyData.latitude}, ${localSurveyData.longitude}`
                          : "Will be derived from address"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Property Type">
                        {localSurveyData.hospitality_type || "N/A"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Total Square Footage">
                        {localSurveyData.total_square_footage || "N/A"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Total Guest Rooms">
                        {localSurveyData.total_guest_rooms || "N/A"}
                      </Descriptions.Item>
                    </Descriptions>
                  </Panel>

                  <Panel header="Room Information" key={isExistingLocation ? "2" : "3"}>
                    <Descriptions bordered column={1} style={{ width: "100%" }}>
                      <Descriptions.Item label="Studio Rooms">{localSurveyData.no_of_studios || "0"}</Descriptions.Item>
                      <Descriptions.Item label="1 Bedroom Rooms">
                        {localSurveyData.no_of_1_bedrooms || "0"}
                      </Descriptions.Item>
                      <Descriptions.Item label="2 Bedroom Rooms">
                        {localSurveyData.no_of_2_bedrooms || "0"}
                      </Descriptions.Item>
                      <Descriptions.Item label="3 Bedroom Rooms">
                        {localSurveyData.no_of_3_bedrooms || "0"}
                      </Descriptions.Item>
                    </Descriptions>
                  </Panel>

                  <Panel header="HVAC & Energy Information" key={isExistingLocation ? "3" : "4"}>
                    <Descriptions bordered column={1} style={{ width: "100%" }}>
                      <Descriptions.Item label="HVAC System Type">
                        {localSurveyData.hvac_system_type || "N/A"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Energy Initiatives">
                        {localSurveyData.energy_initiatives || "N/A"}
                      </Descriptions.Item>
                    </Descriptions>
                  </Panel>
                </Collapse>

                <div className="action-buttons" style={{ textAlign: "center", marginTop: 24 }}>
                  <Button type="primary" size="large" onClick={handleProcess} disabled={showUserExistsError}>
                    {isExistingLocation ? "Update Location & Process Data" : "Create Location & Process Survey"}
                  </Button>
                  <div style={{ marginTop: 8 }}>
                    <Text type="secondary">
                      {isExistingLocation
                        ? "This will update the property information and process consumption data for the existing location"
                        : "This will create a new location and user based on the survey data and process any consumption data"}
                    </Text>
                  </div>
                </div>
              </div>
            </Card>
          )}
        </>
      )}

      {/* Location name already exists modal */}
      <Modal
        title="Location Name Already Exists"
        open={showLocationNameModal}
        onOk={handleLocationNameChange}
        onCancel={() => setShowLocationNameModal(false)}
      >
        <p>
          {existingLocationName
            ? `A location with the name "${existingLocationName}" already exists.`
            : "A location with this name already exists."}
          Please provide a different name for this location:
        </p>
        <Input
          value={newLocationName}
          onChange={(e) => setNewLocationName(e.target.value)}
          placeholder="Enter a new location name"
        />
      </Modal>
    </div>
  );
};

export default SurveyResponseProcessor;
