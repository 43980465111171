export const json = {
  title: "SmartBEA Onboarding",
  description:
    "In this onboarding process we will collect information about your property. This will be used to provide you with an Insights Dashboard that will help you understand your utility consumption better. Helping your building become more productive, energy efficient and sustainable.",
  logo: "https://api.surveyjs.io/private/Surveys/files?name=8368054e-198e-4448-8565-7eef6960f98e",
  logoPosition: "right",
  completedHtml: "<h3>Thanks! We will set up your dashboard and email you once it's ready!</h3>",
  pages: [
    {
      name: "page1",
      elements: [
        {
          type: "multipletext",
          name: "name",
          title: "Who are you?",
          description: "First Name",
          isRequired: true,
          items: [
            {
              name: "text1",
              title: "First Name",
            },
            {
              name: "text2",
              title: "Last Name",
            },
          ],
        },
      ],
    },
    {
      name: "page2",
      elements: [
        {
          type: "text",
          name: "company_role",
          title: "What's your role?",
          isRequired: true,
        },
      ],
    },
    {
      name: "page3",
      elements: [
        {
          type: "text",
          name: "phone_number",
          title: "Phone number\n",
          description: "Don't worry we won't share this with anyone else.",
          isRequired: true,
          inputType: "tel",
        },
      ],
    },
    {
      name: "page4",
      elements: [
        {
          type: "text",
          name: "email",
          title: "Email address",
          description: "We need this for account verification and to keep you updated with any new features.",
          isRequired: true,
          inputType: "email",
        },
      ],
    },
    {
      name: "page5",
      elements: [
        {
          type: "text",
          name: "location_name",
          title: "Property Name",
          description: "What would we search for to find your property on Google?",
          isRequired: true,
        },
      ],
    },
    {
      name: "page6",
      title: "\n",
      description:
        "What is your property building address? We need to know exactly where you are to ensure we pull in the correct data for your insights.",
      elements: [
        {
          type: "text",
          name: "address_1",
          title: "Address line 1",
          isRequired: true,
        },
        {
          type: "text",
          name: "address_2",
          title: "Address line 2",
        },
        {
          type: "text",
          name: "city",
          title: "City/ Town",
          isRequired: true,
        },
        {
          type: "text",
          name: "state",
          title: "State/ Region/ Province",
          isRequired: true,
        },
        {
          type: "text",
          name: "zip_code",
          title: "ZIP/ Post code",
          isRequired: true,
        },
        {
          type: "text",
          name: "country",
          title: "Country",
          isRequired: true,
        },
      ],
    },
    {
      name: "page7",
      elements: [
        {
          type: "text",
          name: "total_square_footage",
          title: "What is the total SQFT of your property (buildings only)? ",
          description: "We use this to calculate your energy intensity and compare against other properties.",
          isRequired: true,
        },
      ],
    },
    {
      name: "page8",
      elements: [
        {
          type: "file",
          name: "location_consumption",
          title: "Information about your property consumption",
          description:
            "We'll need you to download this spreadsheet https://tinyurl.com/23pccv6a and then upload the completed file so that we can set the platform up for you. ",
          isRequired: true,
        },
      ],
    },
    {
      name: "page25",
      elements: [
        {
          type: "text",
          name: "electricity_billing_period_start",
          title: "Electricity Billing Period Start (Day of the Month)",
          description: "e.g 01",
          isRequired: true,
          inputType: "number",
          min: 1,
          max: 31,
        },
        {
          type: "text",
          name: "electricity_billing_period_end",
          title: "Electricity Billing Period End (Day of the Month)",
          description: "e.g 30",
          isRequired: true,
          inputType: "number",
          min: 1,
          max: 31,
        },
        {
          type: "text",
          name: "water_billing_period_start",
          title: "Water Billing Period Start (Day of the Month)",
          description: "e.g 01",
          isRequired: true,
          inputType: "number",
          min: 1,
          max: 31,
        },
        {
          type: "text",
          name: "water_billing_period_end",
          title: "Water Billing Period End (Day of the Month)",
          description: "e.g 30",
          isRequired: true,
          inputType: "number",
          min: 1,
          max: 31,
        },
        {
          type: "text",
          name: "gas_billing_period_start",
          title: "Gas Billing Period Start (Day of the Month)",
          description: "e.g 01",
          isRequired: true,
          inputType: "number",
          min: 1,
          max: 31,
        },
        {
          type: "text",
          name: "gas_billing_period_end",
          title: "Gas Billing Period End (Day of the Month)",
          description: "e.g 30",
          isRequired: true,
          inputType: "number",
          min: 1,
          max: 31,
        },
        {
          type: "text",
          name: "waste_billing_period_start",
          title: "Waste Billing Period Start (Day of the Month)",
          description: "e.g 01",
          isRequired: true,
          inputType: "number",
          min: 1,
          max: 31,
        },
        {
          type: "text",
          name: "waste_billing_period_end",
          title: "Waste Billing Period End (Day of the Month)",
          description: "e.g 30",
          isRequired: true,
          inputType: "number",
          min: 1,
          max: 31,
        },
      ],
    },
    {
      name: "page9",
      elements: [
        {
          type: "dropdown",
          name: "hospitality_type",
          title: "What type of hospitality property do you have?",
          isRequired: true,
          choices: ["Hotel", "Resort", "Co-Living", "Serviced Apartments", "Villas", "Hostel"],
          showOtherItem: true,
        },
      ],
    },
    {
      name: "page10",
      elements: [
        {
          type: "text",
          name: "total_guest_rooms",
          title: "Total number of guest rooms",
          description:
            "Up to 80% of a property's consumption comes from guest rooms, so we need to understand how your property is set up.",
          isRequired: true,
        },
      ],
    },
    {
      name: "page11",
      elements: [
        {
          type: "text",
          name: "no_of_studios",
          title: "Number of Studio Rooms",
          description:
            "These would be your room categories that are single, open plan rooms with no separate bedrooms.",
          isRequired: true,
        },
      ],
    },
    {
      name: "page12",
      elements: [
        {
          type: "text",
          name: "no_of_1_bedrooms",
          title: "Number of 1 Bedroom Rooms",
          isRequired: true,
        },
      ],
    },
    {
      name: "page13",
      elements: [
        {
          type: "text",
          name: "no_of_2_bedrooms",
          title: "Number of 2 Bedroom Rooms",
          isRequired: true,
        },
      ],
    },
    {
      name: "page14",
      elements: [
        {
          type: "text",
          name: "no_of_3_bedrooms",
          title: "Number of 3 Bedroom Rooms",
          isRequired: true,
        },
      ],
    },
    {
      name: "page15",
      elements: [
        {
          type: "text",
          name: "rooms_floor_percentage",
          title: "Percentage of floor space covered by rooms",
          description:
            "This helps us understand your building layout so we can model things more accurately. If you don't know, this, you can skip to the next question.",
        },
      ],
    },
    {
      name: "page16",
      elements: [
        {
          type: "text",
          name: "common_areas_floor_percentage",
          title: "Common Areas Floor Percentage",
          description:
            "This includes spaces like lobbies, corridors, and lift lobbies. It does not include restaurants, bars, spa areas, or parking. If you're not sure, you can skip to the next question.",
        },
      ],
    },
    {
      name: "page17",
      elements: [
        {
          type: "text",
          name: "restaurants_floor_percentage",
          title: "Restaurants Floor Percentage",
          description:
            "This includes all dining outlets, bars, lounges, and any food and beverage service areas within the property. If you don't know, this, you can skip to the next question.",
        },
      ],
    },
    {
      name: "page18",
      elements: [
        {
          type: "text",
          name: "spas_floor_percentage",
          title: "Spa Areas Floor Percentage",
          description:
            "This includes the spa, treatment rooms, relaxation lounges, and wellness facilities (excluding the gym if it's separate). If you don't know, this, you can skip to the next question.",
        },
      ],
    },
    {
      name: "page19",
      elements: [
        {
          type: "text",
          name: "back_offices_floor_percentage",
          title: "Back Offices Floor Percentage",
          description:
            'This includes offices for management and staff, storage rooms, and any non-guest-facing operational areas. If you don\'t know, this, you can click "OK."',
        },
      ],
    },
    {
      name: "page20",
      elements: [
        {
          type: "text",
          name: "other_areas_floor_percentage",
          title: "Other Areas Floor Percentage",
          description:
            "This could include gyms, function rooms, meeting rooms, parking, or any other spaces not covered above. If you don't know, this, you can skip to the next question.",
        },
      ],
    },
    {
      name: "page21",
      elements: [
        {
          type: "tagbox",
          name: "hvac_system_type",
          title: "What's HVAC System does your property use?",
          description:
            "Different types of AC systems consume energy at different rates. By knowing what's installed in your property, we're better able to forecast demand.",
          isRequired: true,
          showCommentArea: true,
          choices: ["Split Unit", "VRV/ VRF", "Chiller", "Boiler", "Chiller/ Boiler"],
        },
      ],
    },
    {
      name: "page22",
      elements: [
        {
          type: "boolean",
          name: "has_energy_initiatives",
          title: "Any other energy reduction or sustainability initiatives on the property? ",
          description:
            "HVAC management is only one part of your sustainability plans, please let us know about the other things you do to be more green!",
          isRequired: true,
        },
        {
          type: "comment",
          name: "energy_initiatives",
          title: "If yes, please specify.",
        },
      ],
    },
  ],
  partialSendEnabled: true,
  navigateToUrlOnCondition: [{}],
  showProgressBar: true,
};

export default json;
