import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

interface AddressEntryProps {
  onCoordinatesChange: (coordinates: { lat: number; lng: number } | null) => void;
  initialCoordinates?: { lat: number; lng: number } | null;
}

let geocoder: google.maps.Geocoder;

const AddressEntry: React.FC<AddressEntryProps> = ({ onCoordinatesChange, initialCoordinates = null }) => {
  const [coordinates, setCoordinates] = useState<{ lat: number; lng: number } | null>(initialCoordinates);
  const [initialAddress, setInitialAddress] = useState<string | null>(null);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"] as const,
  });

  useEffect(() => {
    if (initialCoordinates) {
      setCoordinates(initialCoordinates);
      geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: initialCoordinates }, (results, status) => {
        if (status === "OK" && results?.[0]) {
          setInitialAddress(results[0].formatted_address);
        }
      });
    }
  }, [initialCoordinates]);

  const handleSelect = async (value: any) => {
    const place = value;
    geocoder = new google.maps.Geocoder();

    geocoder.geocode({ address: place.label }, (results, status) => {
      if (status === "OK" && results?.[0]) {
        const { location } = results[0].geometry;
        const newCoordinates = {
          lat: location.lat(),
          lng: location.lng(),
        };
        setCoordinates(newCoordinates);
        onCoordinatesChange(newCoordinates);
      }
    });
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="mb-lg">
      <GooglePlacesAutocomplete
        selectProps={{
          onChange: handleSelect,
          placeholder: "Enter an address",
          value: initialAddress ? { label: initialAddress, value: initialAddress } : null,
        }}
      />
      {coordinates && (
        <div style={{ height: "400px", width: "100%", marginTop: "20px" }}>
          <GoogleMap center={coordinates} zoom={15} mapContainerStyle={{ height: "100%", width: "100%" }}>
            <Marker
              position={coordinates}
              draggable
              onDragEnd={(e) => {
                const newCoordinates = {
                  lat: e.latLng?.lat() ?? 0,
                  lng: e.latLng?.lng() ?? 0,
                };
                setCoordinates(newCoordinates);
                onCoordinatesChange(newCoordinates);
              }}
            />
          </GoogleMap>
        </div>
      )}
    </div>
  );
};

export default AddressEntry;
