import React from "react";
import { Link } from "react-router-dom";

import Table from "components/Table/Table";
import { SettingFilled } from "@ant-design/icons";
import { hasSmartREM } from "utils/locationMetadata";
import { useGetDefaultHomepageQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import { useAuth0 } from "@auth0/auth0-react";
import { Location } from "./LocationList.d";

// eslint-disable-next-line react/prop-types
const DefaultHomepageFetcher = ({ locationId, userId, children }) => {
  const { data, loading, error } = useGetDefaultHomepageQuery({
    variables: {
      locationId,
      userId: userId as string,
    },
  });

  if (loading) return <span>Loading...</span>;
  if (error) return <span>Error</span>;

  const defaultHomepage = data?.sensorflow_location_to_user_mapping?.[0]?.default_homepage || "keys";

  return children(defaultHomepage);
};

const LocationListTable = (props: any) => {
  const { user } = useAuth0();

  const COLUMN_CONFIG = [
    {
      title: "Location Name",
      render: (record: Location) => (
        <DefaultHomepageFetcher locationId={record.id} userId={user?.sub}>
          {(defaultHomepage: any) => (
            <div className="d-flex justify-content-between">
              {hasSmartREM(record.locationMetadata?.subscription) ? (
                <>
                  <Link to={`locations/${record.id}/${defaultHomepage}`}>{record.locationName}</Link>
                  <Link to={`locations/${record.id}/configuration`}>
                    <SettingFilled />
                  </Link>
                </>
              ) : (
                <span>{record.locationName}</span>
              )}
            </div>
          )}
        </DefaultHomepageFetcher>
      ),
    },
  ];

  return <Table {...props} columns={COLUMN_CONFIG} dataItemName="locations" rowKey="id" />;
};

export default LocationListTable;
