import { GetFloorsToLevelsQuery, GetGroupsForDropdownQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import { Sensor } from "./sensors";

interface BuildingInfo {
  groupId: string;
  levelGroupIndex: number[];
  levelIndex: number | null;
  positionId: string;
  positionName: number;
}

export const getFloorConfiguration = (groupNames: GetGroupsForDropdownQuery["positions"], selectedGroup: string) => {
  const tempData: string[] = [];
  const findGroupnameSelected = groupNames.find((g) => g.positionId === selectedGroup);
  if (findGroupnameSelected) tempData.push(findGroupnameSelected.positionId);

  const groupNameAsNumber = Number(findGroupnameSelected?.positionName);
  const topFloorNumber = groupNameAsNumber + 1;
  const findTopFloor = groupNames.find((g) => g.positionName === `${topFloorNumber}`);
  if (findTopFloor) tempData.push(findTopFloor.positionId);

  const bottomFloorNumber = groupNameAsNumber - 1;
  const findBottomFloor = groupNames.find((g) => g.positionName === `${bottomFloorNumber}`);
  if (findBottomFloor) tempData.push(findBottomFloor.positionId);

  return tempData;
};

/**
 * Building floor plan as mapped in the space mapper
 */
const getBuildingFloorPlanDetails = (
  keyMapping?: any,
  floorsToLevels?: GetFloorsToLevelsQuery["sensorflow_smplrspace_floors_to_levels"],
  groupNames?: GetGroupsForDropdownQuery["positions"]
) => {
  const tempData: BuildingInfo[] = [];
  floorsToLevels?.map((floor) => {
    const buildingFloorPlan = keyMapping.find((m: any) => m.parentPositionId === floor.position_id);
    const group = groupNames?.find((g: any) => g.positionId === floor.position_id);
    const uniqueEntry = tempData.find((tmp: any) => tmp.positionId === floor.position_id);
    if (buildingFloorPlan && !uniqueEntry && group && floor.levelsGroup.levelIndex) {
      tempData.push({
        positionId: buildingFloorPlan.parentPositionId,
        levelIndex: buildingFloorPlan.position[0].levelIndex,
        groupId: floor.group_id,
        levelGroupIndex: floor.levelsGroup.levelIndex,
        positionName: Number(group.positionName),
      });
    }
    return true;
  });
  tempData.sort((a, b) => a.positionName - b.positionName);
  return tempData;
};

/**
 * This will check which floors have the same grouping for the floor plan
 * This will also update the level index of the floor
 */
const updateLevelIndex = (buildingFloorPlan: BuildingInfo[]): BuildingInfo[] => {
  const tempData: BuildingInfo[] = [];

  if (!buildingFloorPlan || buildingFloorPlan.length === 0) return tempData;

  const { groupId, levelGroupIndex } = buildingFloorPlan[0];

  buildingFloorPlan.forEach((floor, index) => {
    if (floor.groupId === groupId && levelGroupIndex[index] !== undefined) {
      tempData.push({
        ...floor,
        levelIndex: levelGroupIndex[index],
      });
    } else {
      tempData.push({ ...floor });
    }
  });

  return tempData;
};

export const configureFloorsToLevels = async (
  floorsToLevels?: GetFloorsToLevelsQuery["sensorflow_smplrspace_floors_to_levels"],
  groupNames?: GetGroupsForDropdownQuery["positions"],
  keyMapping?: any,
  sensors?: Sensor[],
  locationId?: any
) => {
  const updateKeyMapping: any[] = [];
  const updateSensors: Sensor[] = [];
  const buildingFloorPlan: BuildingInfo[] = getBuildingFloorPlanDetails(keyMapping, floorsToLevels, groupNames);
  const configuredLevelIndex = updateLevelIndex(buildingFloorPlan);
  keyMapping?.map((k: any) => {
    const updatedLevelIndex = configuredLevelIndex.find((level) => level.positionId === k.parentPositionId);
    updateKeyMapping.push({
      ...k,
      position: k.position?.map((pos: any) => ({
        ...pos,
        levelIndex: updatedLevelIndex?.levelIndex,
      })),
    });
    return true;
  });
  sensors?.map((sensor: any) => {
    const updatedLevelIndex = configuredLevelIndex.find((level) => level.positionId === sensor.parentPositionId);
    if (!updatedLevelIndex) return;
    updateSensors.push({
      ...sensor,
      position: {
        ...sensor.position,
        levelIndex: updatedLevelIndex?.levelIndex,
      },
    });
    return true;
  });
  // catch gateway without groups
  sensors?.map((sensor: any) => {
    const isExisting = updateSensors.find((u: any) => u.id === sensor.id);
    if (sensor.parentPositionId === locationId && !isExisting) updateSensors.push({ ...sensor });
    return true;
  });
  return {
    updateKeyMapping,
    updateSensors,
  };
};

/**
 * Gets levels used in rendering map
 */
export const findIncludedIndex = (keyMapping?: any) => {
  const includedSpaceIndex: any[] = [];
  keyMapping?.map((k: any) => {
    const isExisting = includedSpaceIndex.includes(k.position[0].levelIndex);
    if (!isExisting) {
      includedSpaceIndex.push(k.position[0].levelIndex);
    }
    return true;
  });
  return includedSpaceIndex;
};
