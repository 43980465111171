import { Alert, Button, Col, DatePicker, Form, Input, message, Modal, Row, Select, Space, Table } from "antd";
import { RoleContext } from "contexts/role-context";
import errorHandler from "errorHandler";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import logger from "services/logger/logger";
import {
  CreateConsumptionRecordsMutationVariables,
  SensorflowLocationConsumptionsQueryVariables,
  useCreateConsumptionRecordsMutation,
  useDeleteLocationConsumptionByPkMutation,
  useGetPropertiesInformationQuery,
  useSensorflowLocationConsumptionsQuery,
  useSensorflowMetricTypesQuery,
  useUpdateLocationConsumptionWithSourceMutation,
  useUpdatePropertyBillingPeriodsMutation,
} from "../../../pacts/app-webcore/hasura-webcore.graphql";
import BillingPeriodsModal, { BillingPeriodFormData } from "./BillingPeriodsModal";
import "./SmartBEA.scss";

const { Option } = Select;
const { RangePicker } = DatePicker;

// Add editable row styles
const EditableRowStyle = `
  .editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
  }

  .editable-row:hover {
    background-color: #fafafa;
  }
  
  .editable-row-editing {
    background-color: #e6f7ff !important;
    border: 1px solid #1890ff;
  }

  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
  }
`;

interface ConsumptionDataComponentProps {
  locationId: string;
}

interface ConsumptionRecord {
  key: string;
  locationId: string;
  consumptionPeriodStart: string;
  consumptionPeriodEnd: string;
  metricTypeId: number;
  metricName: string;
  actualValue: number | null;
  clientForecast: number | null;
  sensorflowForecast: number | null;
  sourceType: string;
  sourceId: string;
  updatedAt: string;
  isEditing?: boolean;
  actualCost?: number | null;
  clientCostForecast?: number | null;
}

interface MetricType {
  metricTypeId: number;
  metricName: string;
}

interface QueryResult {
  sensorflow_location_consumptions: Array<{
    locationId: string;
    metricTypeId: number;
    consumptionPeriodStart: string;
    consumptionPeriodEnd: string;
    actualValue: number;
    clientForecast: number;
    sensorflowForecast: number;
    sourceType: string;
    sourceId: string;
    updatedAt: string;
  }>;
  sensorflow_location_consumptions_aggregate?: {
    aggregate?: {
      count: number;
    };
  };
}

interface AntdTablePagination {
  current: number;
  pageSize: number;
  total: number;
}

interface ColumnType {
  title: string;
  dataIndex?: string;
  key: string;
  editable?: boolean;
  children?: ColumnType[];
  render?: (value: any, record: ConsumptionRecord) => React.ReactNode;
  sorter?: (a: ConsumptionRecord, b: ConsumptionRecord) => number;
  sortDirections?: Array<"ascend" | "descend">;
  width?: number;
}

interface PropertyInformation {
  electricityBillingPeriodStart: number | null;
  electricityBillingPeriodEnd: number | null;
  gasBillingPeriodStart: number | null;
  gasBillingPeriodEnd: number | null;
  waterBillingPeriodStart: number | null;
  waterBillingPeriodEnd: number | null;
  wasteBillingPeriodStart: number | null;
  wasteBillingPeriodEnd: number | null;
}

export const ConsumptionDataComponent: React.FC<ConsumptionDataComponentProps> = ({
  locationId,
}): React.ReactElement => {
  const role = useContext(RoleContext);
  const [addForm] = Form.useForm();
  const [editForm] = Form.useForm();
  const [bulkEditForm] = Form.useForm();
  const [billingPeriodsForm] = Form.useForm();
  const [data, setData] = useState<ConsumptionRecord[]>([]);
  const [filteredData, setFilteredData] = useState<ConsumptionRecord[]>([]);
  const [metricTypes, setMetricTypes] = useState<MetricType[]>([]);
  const [pagination, setPagination] = useState<AntdTablePagination>({
    current: 1,
    pageSize: 30,
    total: 0,
  });
  const [dateRange, setDateRange] = useState<[moment.Moment, moment.Moment]>([
    moment().subtract(12, "months"),
    moment().add(12, "months"),
  ]);
  const [selectedUtilityFilter, setSelectedUtilityFilter] = useState<number | null>(1);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
  const [currentRecord, setCurrentRecord] = useState<ConsumptionRecord | null>(null);
  const [isBulkEditModalVisible, setIsBulkEditModalVisible] = useState<boolean>(false);
  const [selectedMetricType, setSelectedMetricType] = useState<number | null>(null);
  const [isSelectedDateFuture, setIsSelectedDateFuture] = useState<boolean>(false);
  const [selectedMonth, setSelectedMonth] = useState<moment.Moment | null>(null);
  const [propertyInformation, setPropertyInformation] = useState<PropertyInformation | null>(null);
  const [selectedRows, setSelectedRows] = useState<ConsumptionRecord[]>([]);
  const [selectionType] = useState<"checkbox" | "radio">("checkbox");
  const [isBillingPeriodsModalVisible, setIsBillingPeriodsModalVisible] = useState<boolean>(false);

  // Move filterData function up to solve the "used before it was defined" error
  const filterData = (dataToFilter: ConsumptionRecord[], utilityTypeId: number | null) => {
    if (!utilityTypeId) {
      setFilteredData(dataToFilter);
      // Reset pagination to first page and update total count
      setPagination((prev) => ({ ...prev, current: 1, total: dataToFilter.length }));
      return;
    }

    const filtered = dataToFilter.filter((record) => record.metricTypeId === utilityTypeId);

    setFilteredData(filtered);
    // Reset pagination to first page and update total count
    setPagination((prev) => ({ ...prev, current: 1, total: filtered.length }));
  };

  // GraphQL queries and mutations
  const {
    data: queryData,
    loading: dataLoading,
    refetch,
  } = useSensorflowLocationConsumptionsQuery({
    variables: {
      locationId,
      startDate: dateRange[0].format("YYYY-MM-DD"),
      endDate: dateRange[1].format("YYYY-MM-DD"),
    } as SensorflowLocationConsumptionsQueryVariables,
    fetchPolicy: "network-only", // Don't use cache when dates change
  });

  const { data: metricTypesData, loading: typesLoading, refetch: refetchMetricTypes } = useSensorflowMetricTypesQuery();

  // Add query for the properties information
  const { data: propertiesData, loading: propertiesLoading } = useGetPropertiesInformationQuery({
    variables: { locationId },
    fetchPolicy: "network-only",
  });

  const loading = dataLoading || typesLoading || propertiesLoading;

  const [updateConsumption] = useUpdateLocationConsumptionWithSourceMutation();
  const [createConsumptionRecord] = useCreateConsumptionRecordsMutation();
  const [deleteConsumptionRecord] = useDeleteLocationConsumptionByPkMutation();
  const [updatePropertyBillingPeriods] = useUpdatePropertyBillingPeriodsMutation();

  // Define formatQueryData function after metricTypesData is defined
  const formatQueryData = (queryResult: QueryResult): ConsumptionRecord[] => {
    if (!queryResult?.sensorflow_location_consumptions || metricTypesData?.sensorflow_metric_types.length === 0)
      return [];

    // Load cost data from localStorage
    let costsMap: Record<string, { actualCost: number | null; clientCostForecast: number | null }> = {};
    try {
      const storedCosts = localStorage.getItem(`consumption-costs-${locationId}`);
      if (storedCosts) {
        costsMap = JSON.parse(storedCosts);
      }
    } catch (e) {
      logger.error("Error loading costs from localStorage:", e);
    }

    // Map of consumption metric types to their corresponding cost metric types
    const metricTypePairs: Record<number, number> = {
      1: 2, // electricity_consumption -> electricity_consumption_cost
      4: 5, // water_consumption -> water_consumption_cost
      6: 9, // gas_consumption -> gas_consumption_cost
      7: 8, // waste_consumption -> waste_consumption_cost
      // 3: null  // sub_metered_chiller_reading has no cost pair
    };

    // First pass - organize consumption data by location, date, and metric type
    const recordsByKey: Record<string, ConsumptionRecord> = {};

    // Process all records and organize them
    queryResult.sensorflow_location_consumptions.forEach((item) => {
      // Use the original metric types data rather than the filtered metricTypes
      const metricType = metricTypesData?.sensorflow_metric_types.find((mt) => mt.metricTypeId === item.metricTypeId);
      if (!metricType) {
        logger.warn(`Unknown metric type id: ${item.metricTypeId}`);
        return;
      }

      const recordKey = `${item.locationId}-${item.metricTypeId}-${item.consumptionPeriodStart}-${item.consumptionPeriodEnd}`;

      // Get cost data from localStorage if available
      const storedCosts = costsMap[recordKey] || { actualCost: null, clientCostForecast: null };

      // Create record with basic data
      const record = {
        key: recordKey,
        ...item,
        metricName: metricType.metricName,
        actualCost: storedCosts.actualCost,
        clientCostForecast: storedCosts.clientCostForecast,
      };

      recordsByKey[recordKey] = record;
    });

    // Second pass - check all consumption records and find their cost pairs
    Object.keys(recordsByKey).forEach((key) => {
      const record = recordsByKey[key];

      // If this is a consumption metric type with a corresponding cost type
      if (metricTypePairs[record.metricTypeId] && !record.actualCost && !record.clientCostForecast) {
        const costMetricTypeId = metricTypePairs[record.metricTypeId];
        const costRecordKey = `${record.locationId}-${costMetricTypeId}-${record.consumptionPeriodStart}-${record.consumptionPeriodEnd}`;

        // If there's cost data stored for the corresponding cost metric type
        if (costsMap[costRecordKey]) {
          record.actualCost = costsMap[costRecordKey].actualCost;
          record.clientCostForecast = costsMap[costRecordKey].clientCostForecast;
        }

        // If there's a cost metric type in our data
        const costMetricRecord = Object.values(recordsByKey).find(
          (r) =>
            r.metricTypeId === costMetricTypeId &&
            r.consumptionPeriodStart === record.consumptionPeriodStart &&
            r.consumptionPeriodEnd === record.consumptionPeriodEnd
        );

        if (costMetricRecord) {
          // If we don't have stored costs but we have a cost metric record,
          // associate the costs
          if (
            !record.actualCost &&
            !record.clientCostForecast &&
            (costMetricRecord.actualValue !== null || costMetricRecord.clientForecast !== null)
          ) {
            // Use the cost metric's actual value as the cost
            record.actualCost = costMetricRecord.actualValue;
            record.clientCostForecast = costMetricRecord.clientForecast;

            // Store these associated costs
            costsMap[record.key] = {
              actualCost: record.actualCost,
              clientCostForecast: record.clientCostForecast,
            };
          }
        }
      }
    });

    // Save back updated cost map
    localStorage.setItem(`consumption-costs-${locationId}`, JSON.stringify(costsMap));

    return Object.values(recordsByKey);
  };

  useEffect(() => {
    if (metricTypesData?.sensorflow_metric_types) {
      // Filter out cost metric types (those that end with "_cost")
      const filteredMetricTypes = metricTypesData.sensorflow_metric_types.filter(
        (type) => !type.metricName.includes("_cost")
      );
      setMetricTypes(filteredMetricTypes);
    } else if (!typesLoading && !metricTypesData?.sensorflow_metric_types) {
      // If we're not loading and we don't have metric types, try to refetch once
      logger.warn("No metric types loaded, attempting to refetch");
      refetchMetricTypes();
    }
  }, [metricTypesData, typesLoading, refetchMetricTypes]);

  useEffect(() => {
    if (queryData && metricTypes.length > 0) {
      const formattedData = formatQueryData(queryData as QueryResult);
      setData(formattedData);
      filterData(formattedData, selectedUtilityFilter);
      // Estimate the total count from available data if aggregate is not available
      const estimatedCount = formattedData.length;
      setPagination((prev) => ({ ...prev, total: estimatedCount }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData, metricTypes]);

  // Filter data when utility filter changes
  useEffect(() => {
    filterData(data, selectedUtilityFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUtilityFilter]);

  // Add useEffect for date range changes
  useEffect(() => {
    // Refetch data when date range changes
    refetch({
      locationId,
      startDate: dateRange[0].format("YYYY-MM-DD"),
      endDate: dateRange[1].format("YYYY-MM-DD"),
    });
  }, [dateRange, locationId, refetch]);

  // Store cost data in localStorage for persistence between sessions
  useEffect(() => {
    // Load cost data from localStorage when component mounts
    const loadCostsFromStorage = (): Record<
      string,
      { actualCost: number | null; clientCostForecast: number | null }
    > => {
      try {
        const storedCosts = localStorage.getItem(`consumption-costs-${locationId}`);
        if (storedCosts) {
          return JSON.parse(storedCosts);
        }
      } catch (e) {
        logger.error("Error loading costs from localStorage:", e);
      }
      return {};
    };

    // When data changes and has cost values, update filtered data and localStorage
    if (data.length > 0) {
      const costsMap = loadCostsFromStorage();

      // Update data with costs from localStorage
      const updatedData = data.map((record) => {
        const recordKey = record.key;
        if (costsMap[recordKey]) {
          return {
            ...record,
            actualCost: costsMap[recordKey].actualCost,
            clientCostForecast: costsMap[recordKey].clientCostForecast,
          };
        }
        return record;
      });

      setData(updatedData);
      filterData(updatedData, selectedUtilityFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length, locationId]);

  // Add useEffect to parse property information when it's loaded
  useEffect(() => {
    if (
      propertiesData?.sensorflow_properties_information &&
      propertiesData.sensorflow_properties_information.length > 0
    ) {
      const propertyInfo = propertiesData.sensorflow_properties_information[0];

      // Handle numeric values directly - no need to extract days from dates
      setPropertyInformation({
        electricityBillingPeriodStart: propertyInfo.electricityBillingPeriodStart || null,
        electricityBillingPeriodEnd: propertyInfo.electricityBillingPeriodEnd || null,
        gasBillingPeriodStart: propertyInfo.gasBillingPeriodStart || null,
        gasBillingPeriodEnd: propertyInfo.gasBillingPeriodEnd || null,
        waterBillingPeriodStart: propertyInfo.waterBillingPeriodStart || null,
        waterBillingPeriodEnd: propertyInfo.waterBillingPeriodEnd || null,
        wasteBillingPeriodStart: propertyInfo.wasteBillingPeriodStart || null,
        wasteBillingPeriodEnd: propertyInfo.wasteBillingPeriodEnd || null,
      });
    }
  }, [propertiesData]);

  // Move getCostMetricTypeId function up before it's used
  // Define the mapping between consumption metric types and cost metric types
  const getCorrespondingCostMetricType = (metricTypeId: number): number | null => {
    // This function maps consumption metric types to their cost counterparts
    switch (metricTypeId) {
      case 1: // electricity consumption
        return 2; // electricity cost
      case 4: // water consumption
        return 5; // water cost
      case 6: // gas consumption
        return 8; // gas cost
      case 7: // waste consumption
        return 9; // waste cost
      default:
        return null;
    }
  };

  const getCostMetricTypeId = (metricTypeId: number): number | null => {
    return getCorrespondingCostMetricType(metricTypeId);
  };

  const edit = (record: ConsumptionRecord) => {
    setCurrentRecord(record);
    editForm.resetFields();
    editForm.setFieldsValue({
      actualValue: record.actualValue,
      clientForecast: record.clientForecast,
      actualCost: record.actualCost,
      clientCostForecast: record.clientCostForecast,
    });
    setIsEditModalVisible(true);
  };

  const cancel = () => {
    setIsEditModalVisible(false);
    setCurrentRecord(null);
  };

  const save = async () => {
    if (!currentRecord) return;

    try {
      const row = await editForm.validateFields();
      const { key } = currentRecord;

      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];

        // Get the current user ID from the role context
        const currentUserId = role?.user?.userId || "user";

        const updatedRecord = {
          locationId: item.locationId,
          metricTypeId: item.metricTypeId,
          consumptionPeriodStart: item.consumptionPeriodStart,
          consumptionPeriodEnd: item.consumptionPeriodEnd,
          actualValue: parseFloat(row.actualValue),
          clientForecast: parseFloat(row.clientForecast),
          actualCost: row.actualCost ? parseFloat(row.actualCost) : null,
          clientCostForecast: row.clientCostForecast ? parseFloat(row.clientCostForecast) : null,
          sourceType: "Manual",
          sourceId: currentUserId,
        };

        try {
          // Only send fields that are in the GraphQL schema
          await updateConsumption({
            variables: {
              locationId: updatedRecord.locationId,
              metricTypeId: updatedRecord.metricTypeId,
              consumptionPeriodStart: updatedRecord.consumptionPeriodStart,
              consumptionPeriodEnd: updatedRecord.consumptionPeriodEnd,
              actualValue: updatedRecord.actualValue,
              clientForecast: updatedRecord.clientForecast,
              sourceType: updatedRecord.sourceType,
              sourceId: updatedRecord.sourceId,
            },
          });

          // Create updated record with all fields including costs
          const updatedItem = {
            ...item,
            ...row,
            actualValue: updatedRecord.actualValue,
            clientForecast: updatedRecord.clientForecast,
            actualCost: updatedRecord.actualCost,
            clientCostForecast: updatedRecord.clientCostForecast,
          };

          // Update the local data with all fields including cost
          newData.splice(index, 1, updatedItem);
          setData(newData);

          // Make sure filtered data is also updated
          const filteredIndex = filteredData.findIndex((record) => key === record.key);
          if (filteredIndex > -1) {
            const newFilteredData = [...filteredData];
            newFilteredData.splice(filteredIndex, 1, updatedItem);
            setFilteredData(newFilteredData);
          }

          // Save costs to localStorage
          try {
            // Get existing costs
            let costsMap: Record<string, { actualCost: number | null; clientCostForecast: number | null }> = {};
            const storedCosts = localStorage.getItem(`consumption-costs-${locationId}`);
            if (storedCosts) {
              costsMap = JSON.parse(storedCosts);
            }

            // Update with new costs
            costsMap[key] = {
              actualCost: updatedRecord.actualCost,
              clientCostForecast: updatedRecord.clientCostForecast,
            };

            // If this is a consumption metric with a cost pair, also update the cost record
            const costMetricTypeId = getCostMetricTypeId(item.metricTypeId);
            if (costMetricTypeId) {
              const costRecordKey = `${item.locationId}-${costMetricTypeId}-${item.consumptionPeriodStart}-${item.consumptionPeriodEnd}`;
              costsMap[costRecordKey] = {
                actualCost: updatedRecord.actualCost,
                clientCostForecast: updatedRecord.clientCostForecast,
              };
            }

            // Save back to localStorage
            localStorage.setItem(`consumption-costs-${locationId}`, JSON.stringify(costsMap));
          } catch (e) {
            logger.error("Error saving costs to localStorage:", e);
          }

          setIsEditModalVisible(false);
          setCurrentRecord(null);
          message.success("Record updated successfully");
        } catch (error: any) {
          if (typeof errorHandler === "object" && "handleError" in errorHandler) {
            // @ts-ignore
            errorHandler.handleError(error);
          } else {
            logger.error("Error updating record:", error);
          }
          message.error("Error updating record");
        }
      }
    } catch (errInfo) {
      // Silent error
    }
  };

  const handleAddNew = async (values: any) => {
    // Validate that we have both a billing month and a date range
    if (!values.billingMonth || !values.date) {
      message.error("Please select both billing month and verify the date range");
      return;
    }

    // Get the selected dates from the form
    const startDate = moment(values.date[0]);
    const endDate = moment(values.date[1]);

    // Get the current user ID from the role context
    const currentUserId = role?.user?.userId || "user";

    const newRecord = {
      locationId,
      metricTypeId: values.metricTypeId,
      consumptionPeriodStart: startDate.format("YYYY-MM-DD"),
      consumptionPeriodEnd: endDate.format("YYYY-MM-DD"),
      actualValue: isSelectedDateFuture ? null : parseFloat(values.actualValue || "0"),
      clientForecast: parseFloat(values.clientForecast || "0"),
      sourceType: "Manual",
      sourceId: currentUserId,
      updatedAt: moment().format("YYYY-MM-DD"),
    };

    // Store cost values locally even though they're not sent to the backend
    const actualCost = values.actualCost ? parseFloat(values.actualCost) : null;
    const clientCostForecast = values.clientCostForecast ? parseFloat(values.clientCostForecast) : null;

    const recordKey = `${newRecord.locationId}-${newRecord.metricTypeId}-${newRecord.consumptionPeriodStart}-${newRecord.consumptionPeriodEnd}`;

    try {
      await createConsumptionRecord({
        variables: {
          records: [newRecord],
        } as CreateConsumptionRecordsMutationVariables,
      });
      setIsModalVisible(false);
      addForm.resetFields();
      message.success("Record added successfully");

      // Update local data with the cost values that weren't sent to the backend
      const newFormattedRecord = {
        ...newRecord,
        key: recordKey,
        metricName:
          metricTypesData?.sensorflow_metric_types.find((mt) => mt.metricTypeId === newRecord.metricTypeId)
            ?.metricName || "",
        actualCost,
        clientCostForecast,
        sensorflowForecast: null,
        updatedAt: new Date().toISOString(),
      } as ConsumptionRecord;

      // Update both data and filtered data
      const updatedData = [...data, newFormattedRecord];
      setData(updatedData);

      // If no utility filter or matches the new record's type, add to filtered data
      if (!selectedUtilityFilter || selectedUtilityFilter === newRecord.metricTypeId) {
        setFilteredData((prev) => [...prev, newFormattedRecord]);
      }

      // Save costs to localStorage
      try {
        // Get existing costs
        let costsMap: Record<string, { actualCost: number | null; clientCostForecast: number | null }> = {};
        const storedCosts = localStorage.getItem(`consumption-costs-${locationId}`);
        if (storedCosts) {
          costsMap = JSON.parse(storedCosts);
        }

        // Update with new costs for consumption record
        costsMap[recordKey] = {
          actualCost,
          clientCostForecast,
        };

        // If this is a consumption metric with a cost pair, also update the cost record
        const costMetricTypeId = getCostMetricTypeId(newRecord.metricTypeId);
        if (costMetricTypeId) {
          const costRecordKey = `${newRecord.locationId}-${costMetricTypeId}-${newRecord.consumptionPeriodStart}-${newRecord.consumptionPeriodEnd}`;
          costsMap[costRecordKey] = {
            actualCost,
            clientCostForecast,
          };
        }

        // Save back to localStorage
        localStorage.setItem(`consumption-costs-${locationId}`, JSON.stringify(costsMap));
      } catch (e) {
        logger.error("Error saving costs to localStorage:", e);
      }

      // Refetch data to ensure we have the latest records
      // Add a slight delay to ensure server has processed the new record
      setTimeout(() => {
        refetch({
          locationId,
          startDate: dateRange[0].format("YYYY-MM-DD"),
          endDate: dateRange[1].format("YYYY-MM-DD"),
        });
      }, 500);
    } catch (error: any) {
      if (typeof errorHandler === "object" && "handleError" in errorHandler) {
        // @ts-ignore
        errorHandler.handleError(error);
      } else {
        logger.error("Error adding record:", error);
      }
      message.error("Error adding record");
    }
  };

  const handleDateRangeChange = (dates: any) => {
    if (dates && dates.length === 2) {
      setDateRange([dates[0], dates[1]]);
      // Reset pagination to first page when date range changes
      setPagination((prev) => ({ ...prev, current: 1 }));
      // Clear any selected utility filter when date range changes
      setSelectedUtilityFilter(null);
      // Clear existing data while new data is loading
      if (
        dates[0].format("YYYY-MM-DD") !== dateRange[0].format("YYYY-MM-DD") ||
        dates[1].format("YYYY-MM-DD") !== dateRange[1].format("YYYY-MM-DD")
      ) {
        setData([]);
        setFilteredData([]);
      }
    }
  };

  const formatMetricTypeName = (metricName: string): string => {
    // Make the utility name more client-friendly by properly capitalizing and formatting
    return metricName
      .replace(/_/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const handleTableChange = (newPagination: any, filters: any, sorter: any) => {
    setPagination((prev) => ({
      current: newPagination.current,
      pageSize: newPagination.pageSize,
      // Maintain the previously calculated total which depends on filters
      total: prev.total,
    }));

    // Handle sorting
    if (sorter && sorter.field) {
      const { field, order } = sorter;

      // Create a new sorted array based on the sorter information
      const sortedData = [...filteredData].sort((a, b) => {
        // Safely access fields using type guard
        const fieldA = field in a ? a[field as keyof ConsumptionRecord] : null;
        const fieldB = field in b ? b[field as keyof ConsumptionRecord] : null;

        // Handle dates for consumption period fields
        if (field === "consumptionPeriodStart" || field === "consumptionPeriodEnd") {
          return order === "ascend"
            ? moment(fieldA as string).valueOf() - moment(fieldB as string).valueOf()
            : moment(fieldB as string).valueOf() - moment(fieldA as string).valueOf();
        }

        // Handle numeric values
        if (typeof fieldA === "number" && typeof fieldB === "number") {
          return order === "ascend" ? fieldA - fieldB : fieldB - fieldA;
        }

        // Handle string values
        if (typeof fieldA === "string" && typeof fieldB === "string") {
          return order === "ascend" ? fieldA.localeCompare(fieldB) : fieldB.localeCompare(fieldA);
        }

        // Default case for null values
        if (fieldA === null && fieldB !== null) return order === "ascend" ? -1 : 1;
        if (fieldA !== null && fieldB === null) return order === "ascend" ? 1 : -1;

        return 0;
      });

      setFilteredData(sortedData);
    }
  };

  // Add rowSelection object for bulk editing
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRecords: ConsumptionRecord[]) => {
      setSelectedRows(selectedRecords);
    },
    getCheckboxProps: (record: ConsumptionRecord) => ({
      disabled: false, // No need to disable any rows when using modal editing
      name: record.key,
    }),
  };

  const handleBulkEditButtonClick = () => {
    if (selectedRows.length === 0) {
      message.warning("Please select at least one record to edit");
      return;
    }

    // Set initial values for the form
    bulkEditForm.resetFields();

    // No need to set a selected metric type since we're only changing dates
    setIsBulkEditModalVisible(true);
  };

  const handleBulkEditSubmit = async (values: any) => {
    // Extract date range
    const startDate = moment(values.dateRange[0]).format("YYYY-MM-DD");
    const endDate = moment(values.dateRange[1]).format("YYYY-MM-DD");

    // Validate dates
    if (!startDate || !endDate) {
      message.error("Please select valid start and end dates");
      return;
    }

    // Check that end date is after start date
    if (moment(endDate).isBefore(moment(startDate))) {
      message.error("End date must be after start date");
      return;
    }

    // Show loading message
    const loadingMessage = message.loading(`Updating billing periods for ${selectedRows.length} records...`, 0);

    // Get the current user ID from the role context
    const currentUserId = role?.user?.userId || "user";

    // Create a copy of data and filteredData
    const newData = [...data];
    const newFilteredData = [...filteredData];

    // Prepare mutation promises
    const updatePromises = selectedRows.map(async (record) => {
      try {
        // Only update if dates have changed
        if (record.consumptionPeriodStart !== startDate || record.consumptionPeriodEnd !== endDate) {
          // Step 1: Create a new record with the new dates but keeping all other values
          const newRecord = {
            locationId: record.locationId,
            metricTypeId: record.metricTypeId,
            consumptionPeriodStart: startDate,
            consumptionPeriodEnd: endDate,
            actualValue: record.actualValue,
            clientForecast: record.clientForecast,
            sourceType: "Manual",
            sourceId: currentUserId,
            updatedAt: new Date().toISOString(),
          };

          // Step 2: Delete the old record
          await deleteConsumptionRecord({
            variables: {
              locationId: record.locationId,
              metricTypeId: record.metricTypeId,
              consumptionPeriodStart: record.consumptionPeriodStart,
              consumptionPeriodEnd: record.consumptionPeriodEnd,
            },
          });

          // Step 3: Create the new record with updated dates
          await createConsumptionRecord({
            variables: {
              records: [newRecord],
            } as CreateConsumptionRecordsMutationVariables,
          });

          // Update local record in data array
          const dataIndex = newData.findIndex((item) => item.key === record.key);
          if (dataIndex > -1) {
            // Create new record with new key (since key depends on dates)
            const oldKey = record.key;
            const newKey = `${record.locationId}-${record.metricTypeId}-${startDate}-${endDate}`;

            // Update the record with new dates and key, but preserve all other properties
            const updatedRecord = {
              ...newData[dataIndex],
              consumptionPeriodStart: startDate,
              consumptionPeriodEnd: endDate,
              key: newKey,
            };

            // Remove old record and add new one
            newData.splice(dataIndex, 1);
            newData.push(updatedRecord);

            // Do the same for filtered data if record exists there
            const filteredIndex = newFilteredData.findIndex((item) => item.key === oldKey);
            if (filteredIndex > -1) {
              newFilteredData.splice(filteredIndex, 1);
              newFilteredData.push(updatedRecord);
            }

            // Update costs in localStorage
            try {
              let costsMap: Record<string, { actualCost: number | null; clientCostForecast: number | null }> = {};
              const storedCosts = localStorage.getItem(`consumption-costs-${locationId}`);
              if (storedCosts) {
                costsMap = JSON.parse(storedCosts);
              }

              // Transfer costs from old key to new key
              if (costsMap[oldKey]) {
                costsMap[newKey] = costsMap[oldKey];
                delete costsMap[oldKey];
                localStorage.setItem(`consumption-costs-${locationId}`, JSON.stringify(costsMap));
              }
            } catch (e) {
              logger.error("Error updating costs in localStorage:", e);
            }
          }
        }
      } catch (error: any) {
        if (typeof errorHandler === "object" && "handleError" in errorHandler) {
          // @ts-ignore
          errorHandler.handleError(error);
        } else {
          logger.error("Error updating record:", error);
        }
        throw error; // Rethrow to handle outside
      }
    });

    // Execute all updates
    try {
      await Promise.all(updatePromises);
      setData(newData);
      setFilteredData(newFilteredData);
      setIsBulkEditModalVisible(false);
      setSelectedRows([]);

      // Close loading message
      loadingMessage();

      message.success(`Successfully updated billing periods for ${selectedRows.length} records`);
      // Refetch data to ensure we have the latest records
      refetch();
    } catch (error) {
      // Close loading message
      loadingMessage();

      message.error("Some records failed to update. Please try again.");
    }
  };

  const getMetricUnit = (metricName: string): string => {
    if (metricName.toLowerCase().includes("electricity")) return "kWh";
    if (metricName.toLowerCase().includes("water")) return "m³";
    if (metricName.toLowerCase().includes("gas")) return "m³";
    if (metricName.toLowerCase().includes("waste")) return "kg";
    return "";
  };

  const getUtilityColor = (metricName: string): string => {
    if (metricName.toLowerCase().includes("electricity")) return "#1890ff"; // Blue
    if (metricName.toLowerCase().includes("water")) return "#13c2c2"; // Cyan
    if (metricName.toLowerCase().includes("gas")) return "#faad14"; // Orange
    if (metricName.toLowerCase().includes("waste")) return "#52c41a"; // Green
    return "#722ed1"; // Default purple
  };

  // Get sample values based on utility type for placeholder text
  const getSampleConsumptionValue = (metricTypeId: number | null): string => {
    if (!metricTypeId) return "1000";

    // Return sample values based on utility type
    switch (metricTypeId) {
      case 1:
        return "1250"; // electricity
      case 4:
        return "300"; // water
      case 6:
        return "450"; // gas
      case 7:
        return "75"; // waste
      default:
        return "1000";
    }
  };

  const getSampleCostValue = (metricTypeId: number | null): string => {
    if (!metricTypeId) return "150.00";

    // Return sample values based on utility type
    switch (metricTypeId) {
      case 1:
        return "187.50"; // electricity
      case 4:
        return "90.00"; // water
      case 6:
        return "135.00"; // gas
      case 7:
        return "45.00"; // waste
      default:
        return "150.00";
    }
  };

  const isDateInFuture = (date: moment.Moment): boolean => {
    return date.isAfter(moment(), "day");
  };

  const checkDateSelection = (dates: any) => {
    if (dates && dates.length === 2) {
      // Check if any of the dates are in the future
      const isEndDateFuture = isDateInFuture(dates[1]);
      setIsSelectedDateFuture(isEndDateFuture);
    }
  };

  // Add helper to get billing period days for a specific metric type
  const getBillingPeriodDays = (metricTypeId: number | null): { startDay: number | null; endDay: number | null } => {
    if (!metricTypeId) {
      return { startDay: null, endDay: null };
    }

    if (!propertyInformation) {
      return { startDay: null, endDay: null };
    }

    // Map metric type ID to the corresponding billing period fields
    let startDay = null;
    let endDay = null;

    switch (metricTypeId) {
      case 1: // electricity
        startDay = propertyInformation.electricityBillingPeriodStart;
        endDay = propertyInformation.electricityBillingPeriodEnd;
        break;
      case 4: // water
        startDay = propertyInformation.waterBillingPeriodStart;
        endDay = propertyInformation.waterBillingPeriodEnd;
        break;
      case 6: // gas
        startDay = propertyInformation.gasBillingPeriodStart;
        endDay = propertyInformation.gasBillingPeriodEnd;
        break;
      case 7: // waste
        startDay = propertyInformation.wasteBillingPeriodStart;
        endDay = propertyInformation.wasteBillingPeriodEnd;
        break;
      default:
        break;
    }

    return { startDay, endDay };
  };

  // Calculate billing period dates based on selected month and metric type
  const calculateBillingPeriodDates = (
    month: moment.Moment | null,
    metricTypeId: number | null
  ): [moment.Moment, moment.Moment] | null => {
    if (!month || !metricTypeId) {
      return null;
    }

    // Clone the selected month to avoid mutating the original
    const selectedMonthClone = moment(month);

    // Get billing period settings from property information
    const { startDay, endDay } = getBillingPeriodDays(metricTypeId);

    // Case 1: We have specific billing period settings from the property
    if (startDay !== null && endDay !== null) {
      // Billing periods should always span approximately one month (about 30 days apart)
      // For example: When user selects January 2024, with startDay=15, endDay=14
      // The period should be December 15, 2023 to January 14, 2024

      // Create end date in the selected month
      // e.g., for January 2024 with endDay=14, this will be January 14, 2024
      const endDate = moment(selectedMonthClone).date(Math.min(endDay, selectedMonthClone.daysInMonth()));

      // Create start date by going to the previous month and using startDay
      // e.g., for January 2024 with startDay=15, this will be December 15, 2023
      const startDate = moment(selectedMonthClone)
        .subtract(1, "month")
        .date(Math.min(startDay, moment(selectedMonthClone).subtract(1, "month").daysInMonth()));

      // Verify we have approximately 30 days between start and end
      const dayDiff = endDate.diff(startDate, "days");

      // If the gap is too short (less than 25 days), adjust to ensure a proper cycle
      // This can happen when startDay and endDay are close together
      // e.g., startDay=28, endDay=4 would only be a ~7 day period
      if (dayDiff < 25) {
        return [startDate, moment(startDate).add(30, "days")];
      }

      // If the gap is too long (more than 35 days), also adjust
      // This can happen with certain month transitions and leap years
      if (dayDiff > 35) {
        return [moment(endDate).subtract(30, "days"), endDate];
      }

      // Return the standard billing period with start date in previous month
      // and end date in selected month - this ensures ~30 day cycle
      return [startDate, endDate];
    }

    // Case 2: Default to full month when no specific start/end days are configured
    // For default, use the first day and last day of the selected month
    const defaultStart = moment(selectedMonthClone).startOf("month");
    const defaultEnd = moment(selectedMonthClone).endOf("month");

    return [defaultStart, defaultEnd];
  };

  // Handle month selection change
  const handleMonthChange = (date: moment.Moment | null) => {
    setSelectedMonth(date);

    // If we have a month and metric type, update the date range
    if (date && selectedMetricType) {
      const billingPeriod = calculateBillingPeriodDates(date, selectedMetricType);
      if (billingPeriod) {
        // Update the form field
        addForm.setFieldsValue({ date: billingPeriod });

        // Check if the end date is in the future
        checkDateSelection(billingPeriod);
      }
    }
  };

  const handleUtilityFilterChange = (value: number | null) => {
    setSelectedUtilityFilter(value);
  };

  // Metric type selection handler
  const handleMetricTypeChange = (value: number) => {
    setSelectedMetricType(value);

    // If we have a month selected, update the date range based on the new metric type
    if (selectedMonth) {
      const billingPeriod = calculateBillingPeriodDates(selectedMonth, value);
      if (billingPeriod) {
        // Update the form field
        addForm.setFieldsValue({ date: billingPeriod });

        // Check if the end date is in the future
        checkDateSelection(billingPeriod);
      }
    }
  };

  const handleAddButtonClick = () => {
    setIsModalVisible(true);
    setSelectedMetricType(null);
    setIsSelectedDateFuture(false);
    setSelectedMonth(null);
    addForm.resetFields();
  };

  // Add new function to open the billing periods settings modal
  const handleBillingPeriodsSettingsClick = () => {
    // Reset the form first
    billingPeriodsForm.resetFields();

    // Set initial values from property information if available
    if (propertyInformation) {
      billingPeriodsForm.setFieldsValue({
        electricityBillingPeriodStart: propertyInformation.electricityBillingPeriodStart,
        electricityBillingPeriodEnd: propertyInformation.electricityBillingPeriodEnd,
        waterBillingPeriodStart: propertyInformation.waterBillingPeriodStart,
        waterBillingPeriodEnd: propertyInformation.waterBillingPeriodEnd,
        gasBillingPeriodStart: propertyInformation.gasBillingPeriodStart,
        gasBillingPeriodEnd: propertyInformation.gasBillingPeriodEnd,
        wasteBillingPeriodStart: propertyInformation.wasteBillingPeriodStart,
        wasteBillingPeriodEnd: propertyInformation.wasteBillingPeriodEnd,
      });
    }

    // Show the modal
    setIsBillingPeriodsModalVisible(true);
  };

  // Add function to handle successful billing periods update
  const handleBillingPeriodsSuccess = (formValues: BillingPeriodFormData) => {
    // Update property information with new billing period values
    if (propertyInformation) {
      setPropertyInformation({
        ...propertyInformation,
        ...formValues,
      });
    }

    // Use the mutation to update billing periods in the database
    updatePropertyBillingPeriods({
      variables: {
        locationId,
        ...formValues,
      },
    }).catch((error) => {
      logger.error("Error updating billing periods:", error);
      message.error("Failed to update billing periods settings");
    });

    // Close the modal
    setIsBillingPeriodsModalVisible(false);
  };

  // Define columns
  const columns: ColumnType[] = [
    {
      title: "Billing Period",
      key: "billingPeriod",
      width: 300,
      children: [
        {
          title: "From",
          dataIndex: "consumptionPeriodStart",
          key: "consumptionPeriodStart",
          width: 150,
          render: (text: string) => moment(text).format("MMM D, YYYY"),
          sorter: (a: ConsumptionRecord, b: ConsumptionRecord) =>
            moment(a.consumptionPeriodStart).valueOf() - moment(b.consumptionPeriodStart).valueOf(),
          sortDirections: ["ascend", "descend"],
        },
        {
          title: "To",
          dataIndex: "consumptionPeriodEnd",
          key: "consumptionPeriodEnd",
          width: 150,
          render: (text: string) => moment(text).format("MMM D, YYYY"),
          sorter: (a: ConsumptionRecord, b: ConsumptionRecord) =>
            moment(a.consumptionPeriodEnd).valueOf() - moment(b.consumptionPeriodEnd).valueOf(),
          sortDirections: ["ascend", "descend"],
        },
      ],
    },
    {
      title: "Consumption",
      key: "consumption",
      width: 300,
      children: [
        {
          title: "Actual",
          dataIndex: "actualValue",
          key: "actualValue",
          width: 150,
          editable: true,
          render: (value: number | null, record: ConsumptionRecord) => {
            const unit = getMetricUnit(record.metricName);
            return value !== null ? (
              <span style={{ fontWeight: "bold" }}>
                {value.toLocaleString()} <span style={{ color: "#666", fontWeight: "normal" }}>{unit}</span>
              </span>
            ) : (
              "-"
            );
          },
        },
        {
          title: "Client Forecast",
          dataIndex: "clientForecast",
          key: "clientForecast",
          width: 150,
          editable: true,
          render: (value: number | null, record: ConsumptionRecord) => {
            const unit = getMetricUnit(record.metricName);
            return value !== null ? (
              <span>
                {value.toLocaleString()} <span style={{ color: "#666" }}>{unit}</span>
              </span>
            ) : (
              "-"
            );
          },
        },
      ],
    },
    {
      title: "Cost",
      key: "cost",
      width: 300,
      children: [
        {
          title: "Actual",
          dataIndex: "actualCost",
          key: "actualCost",
          width: 150,
          editable: true,
          render: (value: number | null) => {
            if (value === null || value === undefined) return "-";
            return (
              <span style={{ fontWeight: "bold" }}>
                ${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </span>
            );
          },
        },
        {
          title: "Forecast",
          dataIndex: "clientCostForecast",
          key: "clientCostForecast",
          width: 150,
          editable: true,
          render: (value: number | null) => {
            if (value === null || value === undefined) return "-";
            return (
              <span>${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
            );
          },
        },
      ],
    },
    {
      title: "Action",
      key: "action",
      width: 100,
      render: (_: any, record: ConsumptionRecord) => (
        <Button type="link" onClick={() => edit(record)}>
          Edit
        </Button>
      ),
    },
  ];

  return (
    <div className="consumption-data-component">
      <style>{EditableRowStyle}</style>

      <div
        className="table-header"
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "16px",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <div className="filters" style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
          <Space direction="vertical" size={4}>
            <span style={{ fontSize: "15px", fontWeight: "bold" }}>Filter by Billing Period</span>
            <RangePicker
              value={dateRange as any}
              onChange={handleDateRangeChange}
              allowClear={false}
              style={{ width: "350px", height: "40px", fontSize: "14px" }}
              format="MMM D, YYYY"
            />
          </Space>

          <Space direction="vertical" size={4}>
            <span style={{ fontSize: "15px", fontWeight: "bold" }}>Filter by Utility</span>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Select
                placeholder="All Utilities"
                onChange={handleUtilityFilterChange}
                style={{ width: "300px", height: "40px", fontSize: "14px" }}
                allowClear
                value={selectedUtilityFilter}
                size="large"
                loading={typesLoading}
                notFoundContent={
                  typesLoading ? <span>Loading utility types...</span> : <span>No utility types found</span>
                }
              >
                {metricTypes.map((type) => (
                  <Option key={type.metricTypeId} value={type.metricTypeId}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          backgroundColor: getUtilityColor(type.metricName),
                          marginRight: "8px",
                        }}
                      />
                      {formatMetricTypeName(type.metricName)}
                    </div>
                  </Option>
                ))}
              </Select>
            </div>
          </Space>

          {/* Settings icon for billing periods */}
          <Button
            onClick={handleBillingPeriodsSettingsClick}
            type="text"
            style={{ alignSelf: "flex-end", height: "40px", fontSize: "18px" }}
            title="Billing Periods Settings"
          >
            <span className="anticon">
              <svg
                viewBox="64 64 896 896"
                focusable="false"
                data-icon="setting"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M924.8 625.7l-65.5-56c3.1-19 4.7-38.4 4.7-57.8s-1.6-38.8-4.7-57.8l65.5-56a32.03 32.03 0 0 0 9.3-35.2l-.9-2.6a443.74 443.74 0 0 0-79.7-137.9l-1.8-2.1a32.12 32.12 0 0 0-35.1-9.5l-81.3 28.9c-30-24.6-63.5-44-99.7-57.6l-15.7-85a32.05 32.05 0 0 0-25.8-25.7l-2.7-.5c-52.1-9.4-106.9-9.4-159 0l-2.7.5a32.05 32.05 0 0 0-25.8 25.7l-15.8 85.4a351.86 351.86 0 0 0-99 57.4l-81.9-29.1a32 32 0 0 0-35.1 9.5l-1.8 2.1a446.02 446.02 0 0 0-79.7 137.9l-.9 2.6c-4.5 12.5-.8 26.5 9.3 35.2l66.3 56.6c-3.1 18.8-4.6 38-4.6 57.1 0 19.2 1.5 38.4 4.6 57.1L99 625.5a32.03 32.03 0 0 0-9.3 35.2l.9 2.6c18.1 50.4 44.9 96.9 79.7 137.9l1.8 2.1a32.12 32.12 0 0 0 35.1 9.5l81.9-29.1c29.8 24.5 63.1 43.9 99 57.4l15.8 85.4a32.05 32.05 0 0 0 25.8 25.7l2.7.5a449.4 449.4 0 0 0 159 0l2.7-.5a32.05 32.05 0 0 0 25.8-25.7l15.7-85a350 350 0 0 0 99.7-57.6l81.3 28.9a32 32 0 0 0 35.1-9.5l1.8-2.1c34.8-41.1 61.6-87.5 79.7-137.9l.9-2.6c4.5-12.3.8-26.3-9.3-35zM788.3 465.9c2.5 15.1 3.8 30.6 3.8 46.1s-1.3 31-3.8 46.1l-6.6 40.1 74.7 63.9a370.03 370.03 0 0 1-42.6 73.6L721 702.8l-31.4 25.8c-23.9 19.6-50.5 35-79.3 45.8l-38.1 14.3-17.9 97a377.5 377.5 0 0 1-85 0l-17.9-97.2-37.8-14.5c-28.5-10.8-55-26.2-78.7-45.7l-31.4-25.9-93.4 33.2c-17-22.9-31.2-47.6-42.6-73.6l75.5-64.5-6.5-40c-2.4-14.9-3.7-30.3-3.7-45.5 0-15.3 1.2-30.6 3.7-45.5l6.5-40-75.5-64.5c11.3-26.1 25.6-50.7 42.6-73.6l93.4 33.2 31.4-25.9c23.7-19.5 50.2-34.9 78.7-45.7l37.9-14.3 17.9-97.2c28.1-3.2 56.8-3.2 85 0l17.9 97 38.1 14.3c28.7 10.8 55.4 26.2 79.3 45.8l31.4 25.8 92.8-32.9c17 22.9 31.2 47.6 42.6 73.6L781.8 426l6.5 39.9zM512 326c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm79.2 255.2A111.6 111.6 0 0 1 512 614c-29.9 0-58-11.7-79.2-32.8A111.6 111.6 0 0 1 400 502c0-29.9 11.7-58 32.8-79.2C454 401.6 482.1 390 512 390c29.9 0 58 11.6 79.2 32.8A111.6 111.6 0 0 1 624 502c0 29.9-11.7 58-32.8 79.2z" />
              </svg>
            </span>
          </Button>
        </div>

        <div style={{ display: "flex", gap: "8px", alignItems: "flex-end" }}>
          <Button
            type="primary"
            onClick={handleBulkEditButtonClick}
            size="large"
            style={{
              height: "40px",
              fontSize: "14px",
              minWidth: "220px",
              backgroundColor: selectedRows.length > 0 ? "#52c41a" : undefined,
              borderColor: selectedRows.length > 0 ? "#52c41a" : undefined,
              color: "#ffffff",
              fontWeight: selectedRows.length > 0 ? "500" : "normal",
            }}
            disabled={selectedRows.length === 0}
          >
            {selectedRows.length > 0
              ? `Edit Billing Periods (${selectedRows.length} selected)`
              : "Edit Billing Periods"}
          </Button>

          <Button
            type="primary"
            onClick={handleAddButtonClick}
            size="large"
            style={{ height: "40px", fontSize: "14px" }}
          >
            <span className="anticon" style={{ marginRight: "8px" }}>
              +
            </span>
            Add New Utility Record
          </Button>
        </div>
      </div>

      <div style={{ marginTop: "10px" }}>
        <Table
          bordered
          dataSource={filteredData}
          columns={columns.map((col) => {
            if (col.key === "action") {
              // Replace the action column with a simple button that opens the modal
              return {
                ...col,
                render: (_: any, record: ConsumptionRecord) => (
                  <Button type="link" onClick={() => edit(record)}>
                    Edit
                  </Button>
                ),
              };
            }
            return col;
          })}
          rowClassName="editable-row"
          pagination={{
            ...pagination,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} records`,
          }}
          loading={loading}
          onChange={handleTableChange}
          style={{ marginTop: 10 }}
          scroll={{ x: 1000 }}
          size="middle"
          locale={{
            emptyText: loading ? "Loading data..." : "No data found. Try adjusting filters or adding new records.",
          }}
          tableLayout="fixed"
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
        />
      </div>

      <Modal
        title="Edit Consumption Record"
        visible={isEditModalVisible}
        onCancel={cancel}
        footer={[
          <Button key="cancel" onClick={cancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={save}>
            Save
          </Button>,
        ]}
        width={600}
      >
        {currentRecord && (
          <Form form={editForm} layout="vertical">
            <Row gutter={16}>
              <Col span={24}>
                <div style={{ marginBottom: "16px" }}>
                  <p>
                    <strong>Utility Type:</strong> {formatMetricTypeName(currentRecord.metricName)}
                  </p>
                  <p>
                    <strong>Billing Period:</strong>{" "}
                    {moment(currentRecord.consumptionPeriodStart).format("MMM D, YYYY")} to{" "}
                    {moment(currentRecord.consumptionPeriodEnd).format("MMM D, YYYY")}
                  </p>
                </div>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="actualValue"
                  label={
                    <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                      Actual Consumption
                      <span style={{ fontWeight: "normal", fontSize: "13px", marginLeft: "5px", color: "#666" }}>
                        ({getMetricUnit(currentRecord.metricName)})
                      </span>
                    </span>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please enter the actual consumption value",
                    },
                  ]}
                >
                  <Input type="number" step="0.01" size="large" suffix={getMetricUnit(currentRecord.metricName)} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="actualCost"
                  label={<span style={{ fontSize: "15px", fontWeight: "bold" }}>Actual Cost</span>}
                >
                  <Input type="number" step="0.01" size="large" prefix="$" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="clientForecast"
                  label={
                    <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                      Forecasted Consumption
                      <span style={{ fontWeight: "normal", fontSize: "13px", marginLeft: "5px", color: "#666" }}>
                        ({getMetricUnit(currentRecord.metricName)})
                      </span>
                    </span>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please enter the forecasted consumption value",
                    },
                  ]}
                >
                  <Input type="number" step="0.01" size="large" suffix={getMetricUnit(currentRecord.metricName)} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="clientCostForecast"
                  label={<span style={{ fontSize: "15px", fontWeight: "bold" }}>Forecasted Cost</span>}
                >
                  <Input type="number" step="0.01" size="large" prefix="$" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Modal>

      <Modal
        title="Add New Consumption Record"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width={600}
      >
        <Form form={addForm} layout="vertical" onFinish={handleAddNew}>
          <div className="add-form">
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="metricTypeId"
                  label={<span style={{ fontSize: "15px", fontWeight: "bold" }}>Utility Type</span>}
                  rules={[{ required: true, message: "Please select a utility type" }]}
                >
                  <Select
                    placeholder="Select a utility type"
                    onChange={handleMetricTypeChange}
                    size="large"
                    style={{ fontSize: "14px" }}
                  >
                    {metricTypes.map((type) => (
                      <Option key={type.metricTypeId} value={type.metricTypeId}>
                        {formatMetricTypeName(type.metricName)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="billingMonth"
                  label={<span style={{ fontSize: "15px", fontWeight: "bold" }}>Billing Month</span>}
                  rules={[{ required: true, message: "Please select billing month" }]}
                >
                  <DatePicker
                    picker="month"
                    style={{ width: "100%", height: "40px", fontSize: "14px" }}
                    onChange={handleMonthChange}
                    placeholder="Select Month"
                    format="MMMM YYYY"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="date"
                  label={
                    <span style={{ fontSize: "15px", fontWeight: "bold" }}>Billing Period (Automatically Set)</span>
                  }
                  rules={[{ required: true, message: "Please select billing period" }]}
                  extra={
                    <div style={{ fontSize: "12px", color: "#999", marginTop: "4px" }}>
                      Billing period is automatically calculated based on the utility type and selected month using the
                      property&apos;s billing cycle settings.
                    </div>
                  }
                >
                  <RangePicker
                    style={{ width: "100%", height: "40px", fontSize: "14px" }}
                    onChange={checkDateSelection}
                    placeholder={["Start Date", "End Date"]}
                    format="MMM D, YYYY"
                    disabled={!selectedMonth || !selectedMetricType}
                  />
                </Form.Item>
              </Col>
            </Row>

            {!isSelectedDateFuture && (
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="actualValue"
                    label={
                      <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                        Actual Consumption
                        {selectedMetricType && (
                          <span style={{ fontWeight: "normal", fontSize: "13px", marginLeft: "5px", color: "#666" }}>
                            (
                            {getMetricUnit(
                              metricTypes.find((mt) => mt.metricTypeId === selectedMetricType)?.metricName || ""
                            )}
                            )
                          </span>
                        )}
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter the actual consumption value",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      step="0.01"
                      placeholder={getSampleConsumptionValue(selectedMetricType)}
                      size="large"
                      suffix={
                        selectedMetricType &&
                        getMetricUnit(
                          metricTypes.find((mt) => mt.metricTypeId === selectedMetricType)?.metricName || ""
                        )
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}

            {!isSelectedDateFuture && (
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="actualCost"
                    label={<span style={{ fontSize: "15px", fontWeight: "bold" }}>Actual Cost</span>}
                  >
                    <Input
                      type="number"
                      step="0.01"
                      placeholder={getSampleCostValue(selectedMetricType)}
                      size="large"
                      prefix="$"
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="clientForecast"
                  label={
                    <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                      Forecasted Consumption
                      {selectedMetricType && (
                        <span style={{ fontWeight: "normal", fontSize: "13px", marginLeft: "5px", color: "#666" }}>
                          (
                          {getMetricUnit(
                            metricTypes.find((mt) => mt.metricTypeId === selectedMetricType)?.metricName || ""
                          )}
                          )
                        </span>
                      )}
                    </span>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please enter the forecasted consumption value",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    step="0.01"
                    placeholder={getSampleConsumptionValue(selectedMetricType)}
                    size="large"
                    suffix={
                      selectedMetricType &&
                      getMetricUnit(metricTypes.find((mt) => mt.metricTypeId === selectedMetricType)?.metricName || "")
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="clientCostForecast"
                  label={<span style={{ fontSize: "15px", fontWeight: "bold" }}>Forecasted Cost</span>}
                >
                  <Input
                    type="number"
                    step="0.01"
                    placeholder={getSampleCostValue(selectedMetricType)}
                    size="large"
                    prefix="$"
                  />
                </Form.Item>
              </Col>
            </Row>

            {isSelectedDateFuture && (
              <Alert
                message="Future Billing Period"
                description="Actual consumption values cannot be entered for future periods. Only forecast values are allowed."
                type="info"
                showIcon
                style={{ marginBottom: 16 }}
              />
            )}
          </div>

          <div className="form-actions">
            <Button onClick={() => setIsModalVisible(false)} style={{ marginRight: "10px" }}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        title="Bulk Edit Billing Periods"
        visible={isBulkEditModalVisible}
        onCancel={() => setIsBulkEditModalVisible(false)}
        footer={null}
        width={600}
      >
        <Form form={bulkEditForm} layout="vertical" onFinish={handleBulkEditSubmit}>
          <div className="bulk-edit-form">
            <Row gutter={16}>
              <Col span={24}>
                <Alert
                  message={`You are about to update the billing period for ${selectedRows.length} selected record(s)`}
                  type="info"
                  showIcon
                  style={{ marginBottom: 16 }}
                />
                <Form.Item
                  name="dateRange"
                  label={<span style={{ fontSize: "15px", fontWeight: "bold" }}>New Billing Period</span>}
                  rules={[{ required: true, message: "Please select a new billing period" }]}
                >
                  <RangePicker
                    style={{ width: "100%", height: "40px", fontSize: "14px" }}
                    onChange={(dates) => {
                      if (dates && dates.length === 2) {
                        bulkEditForm.setFieldValue("dateRange", dates);
                      }
                    }}
                    placeholder={["Start Date", "End Date"]}
                    format="MMM D, YYYY"
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div className="form-actions">
            <Button onClick={() => setIsBulkEditModalVisible(false)} style={{ marginRight: "10px" }}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Update Billing Periods
            </Button>
          </div>
        </Form>
      </Modal>

      {/* Add the new Billing Periods Settings Modal */}
      <BillingPeriodsModal
        visible={isBillingPeriodsModalVisible}
        onClose={() => setIsBillingPeriodsModalVisible(false)}
        onSuccess={handleBillingPeriodsSuccess}
        form={billingPeriodsForm}
        initialValues={propertyInformation || undefined}
        locationId={locationId}
      />
    </div>
  );
};

export default ConsumptionDataComponent;
