import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, message, Modal, Radio, Row, Select, SelectProps } from "antd";
import ActionButton from "components/ActionButton";
import EditableTable from "components/EditableTable/EditableTable";
import ConfirmDeleteModal, { ConfirmDeleteModalProps } from "components/Modal/ConfirmDeleteModal";
import { ModalWrapperProps } from "components/Modal/Modal";
import showModal from "components/Modal/show";
import errorHandler from "errorHandler";
import {
  useAddDomoEmbedMutation,
  useDeleteDomoEmbedMutation,
  useDomoEmbedsQuery,
  useUpdateDomoEmbedMutation,
} from "pacts/app-webcore/hasura-webcore.graphql";
import React, { useMemo, useState } from "react";

interface EmbedPayload {
  key: string;
  embed_id: string;
  dashboard_name: string;
  isHolistics: string;
  holisticsSecretKey?: string;
  permissionDatasets?: string[];
}

const ManageEmbeddingsModal = ({ modalWrapperProps: { onCancel, ...modalWrapperOtherProps } }: ModalWrapperProps) => {
  const { data, loading: embedQueryLoading, refetch } = useDomoEmbedsQuery();
  const [updateDomoEmbed] = useUpdateDomoEmbedMutation({
    onError: (error: any) => errorHandler.handleError(error),
  });
  const [deleteDomoEmbed] = useDeleteDomoEmbedMutation({
    onError: (error: any) => errorHandler.handleError(error),
  });
  const [addDomoEmbed] = useAddDomoEmbedMutation({
    onError: (error: any) => errorHandler.handleError(error),
  });

  const [embedData, setEmbedData] = useState<EmbedPayload[]>([]);
  const [options, setOptions] = useState<SelectProps["options"]>([]);
  const [selectedDatasets, setSelectedDatasets] = useState("");
  const [editingKey, setEditingKey] = useState("");
  const [isHolistics, setIsHolistics] = useState(false);
  const [form] = Form.useForm();
  const [createForm] = Form.useForm();

  const isEditing = (record: Record<string, any>) => record.key === editingKey;
  const [isAdding, setIsAdding] = useState(false);

  const edit = (record: Record<string, any> & { key: React.Key }) => {
    const arrayData = (record.permissionDatasets || []).map((dataset: string) => ({ value: dataset, label: dataset }));
    form.setFieldsValue({
      embed_id: "",
      dashboard_name: "",
      holisticsSecretKey: "",
      is_holistics: "",
      permission_datasets: [],
      ...record,
    });
    setEditingKey(record.key as string);
    setOptions(arrayData);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async () => {
    const row = (await form.validateFields()) as EmbedPayload;
    const res = await updateDomoEmbed({
      variables: {
        object: {
          name: row.dashboard_name,
          holisticsSecretKey: row.holisticsSecretKey,
          permissionDatasets: selectedDatasets,
          isHolistics: row.isHolistics === "Yes",
        },
        where: {
          embed_id: { _eq: row.embed_id },
        },
      },
    });
    if (!res) return;
    if (res.data?.update_sensorflow_domo_embeds?.affected_rows === 1) {
      setEditingKey("");
      message.success("Embed updated successfully");
    } else {
      message.error("Failed to update embed");
    }
    setEmbedData([]);
    refetch();
  };

  const deleteEmbed = async (e_id: string) => {
    const res = await deleteDomoEmbed({
      variables: {
        where: {
          embed_id: { _eq: e_id },
        },
      },
    });

    if (!res) return;
    if (res.data?.delete_sensorflow_domo_embeds?.affected_rows === 1) {
      setEditingKey("");
      message.success("Embed deleted successfully");
    } else {
      message.error("Failed to delete embed");
    }
    setEmbedData([]);
    refetch();
  };

  const createEmbed = async () => {
    const res = await addDomoEmbed({
      variables: {
        objects: [
          {
            name: createForm.getFieldValue("dashboard_name"),
            description: createForm.getFieldValue("description"),
            embed_id: createForm.getFieldValue("embed_id"),
            isHolistics: createForm.getFieldValue("isHolistics") === "Yes",
            permissionDatasets: selectedDatasets,
            holisticsSecretKey: createForm.getFieldValue("holisticsSecretKey"),
          },
        ],
      },
    });

    if (!res) return;
    message.success("Embed added successfully");
    setEmbedData([]);
    refetch();
    createForm.resetFields();
    setIsAdding(false);
  };

  const columns = [
    {
      title: "Dashboard Name",
      key: "dashboard_name",
      dataIndex: "dashboard_name",
      inputType: "text",
      width: 120,
      editable: true,
      sorter: (a: { dashboard_name: string }, b: { dashboard_name: string }) => {
        if (a.dashboard_name < b.dashboard_name) return -1;
        if (a.dashboard_name > b.dashboard_name) return 1;
        return 0;
      },
    },
    {
      title: "Embed Id",
      key: "embed_id",
      dataIndex: "embed_id",
      inputType: "text",
      width: 120,
      editable: true,
    },
    {
      title: "Holistics Secret Key",
      key: "holistics_secret_key",
      dataIndex: "holisticsSecretKey",
      inputType: "text",
      width: 240,
      editable: true,
    },
    {
      title: "Holistics Embedding?",
      key: "is_holistics",
      dataIndex: "isHolistics",
      customInput: (
        <Select>
          <Select.Option value="Yes">Yes</Select.Option>
          <Select.Option value="No">No</Select.Option>
        </Select>
      ),
      width: 120,
      editable: true,
      sorter: (a: { isHolistics: string }, b: { isHolistics: string }) => {
        if (a.isHolistics < b.isHolistics) return -1;
        if (a.isHolistics > b.isHolistics) return 1;
        return 0;
      },
    },
    {
      title: "Permission Datasets",
      key: "permission_datasets",
      render: (record: Record<string, any> & { key: React.Key }) => {
        return <div>{record.permissionDatasets.join(",")}</div>;
      },
      width: 160,
      customInput: (
        <Select
          mode="tags"
          options={options}
          defaultValue={options}
          onChange={(e) => setSelectedDatasets(e.join(","))}
          tokenSeparators={[","]}
        />
      ),
      editable: true,
    },
    {
      title: "Actions",
      width: 120,
      render: (record: Record<string, any> & { key: React.Key }) => {
        const editable = isEditing(record);
        return editable ? (
          <>
            <ActionButton
              type="save"
              onClick={() => {
                save();
              }}
              className="fs-xl mr-m"
              data-testid="delete-embeded-record"
              customIcon={
                <CheckCircleOutlined
                  className="fs-xl text-primary"
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                />
              }
            />
            <ActionButton
              type="cancel"
              onClick={() => {
                cancel();
              }}
              className="fs-xl mr-m"
              data-testid="delete-embeded-record"
              customIcon={
                <CloseCircleOutlined
                  className="fs-xl text-danger"
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                />
              }
            />
          </>
        ) : (
          <>
            <ActionButton
              type="edit"
              onClick={() => {
                edit(record);
              }}
              className="fs-xl mr-m"
              data-testid="delete-embeded-record"
            />
            <ActionButton
              type="delete"
              onClick={() => {
                showModal<ConfirmDeleteModalProps>({
                  element: ConfirmDeleteModal,
                  config: {
                    popupTitle: "Delete Embedding",
                    fieldPlaceholder: "Enter Embed ID",
                    popupOkText: "Confirm",
                    onOk: () => {
                      deleteEmbed(record.key);
                    },
                    confirmText: record.embed_id,
                    message: `Are you sure you want to delete ${record.dashboard_name} | ${record.embed_id}?`,
                    fieldLabel: "Key in Embed ID to confirm",
                  },
                });
              }}
              className="fs-xl mr-m"
              data-testid="delete-embeded-record"
            />
          </>
        );
      },
    },
  ];

  useMemo(() => {
    if (data) {
      data.domoEmbeds.map((embed) => {
        const embedPayload: EmbedPayload = {
          key: embed.embed_id,
          embed_id: embed.embed_id,
          dashboard_name: embed.name,
          isHolistics: embed.isHolistics ? "Yes" : "No",
          holisticsSecretKey: embed.holisticsSecretKey ?? "",
          permissionDatasets: (embed.permissionDatasets ?? "").split(","),
        };
        setEmbedData((prev) => [...prev, embedPayload]);
        return true;
      });
    }
  }, [data]);

  return (
    <Modal
      title="Manage Embeddings"
      centered
      width={1000}
      cancelButtonProps={{ style: { display: "none" } }}
      onCancel={onCancel}
      okButtonProps={{ style: { display: "none" } }}
      {...modalWrapperOtherProps}
    >
      {!isAdding && (
        <Button type="primary" className="mb-m" onClick={() => setIsAdding((prev) => !prev)}>
          {isAdding ? "Cancel" : "Add Dashboard"}
        </Button>
      )}
      {isAdding ? (
        <div className="flex vertical space-between">
          <Form form={createForm}>
            <h2>Add Dashboard</h2>
            <Row className="mb-m" gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item name="dashboard_name" label="Dashboard Name" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="embed_id" label="Embed Id" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-m" gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  name="description"
                  label="Description"
                  labelCol={{ span: 6, offset: 1 }}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="isHolistics" label="Is Holistics" rules={[{ required: true }]}>
                  <Radio.Group onChange={(e) => setIsHolistics(e.target.value === "Yes")}>
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            {isHolistics && (
              <>
                <Row className="mb-m" gutter={[16, 16]}>
                  <Col span={24}>
                    <Form.Item name="holisticsSecretKey" label="Holistics Secret Key">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mb-m" gutter={[16, 16]}>
                  <Col span={14}>
                    <Form.Item name="datasets" label="Datasets" labelCol={{ offset: 3 }}>
                      <Select
                        mode="tags"
                        options={options}
                        value={options}
                        onChange={(e) => setSelectedDatasets(e.join(","))}
                        tokenSeparators={[","]}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Form>
          <Row className="mb-none">
            <Button type="primary" className="mr-xs" onClick={createEmbed}>
              Save
            </Button>
            <Button onClick={() => setIsAdding(false)}>Cancel</Button>
          </Row>
        </div>
      ) : (
        <EditableTable
          form={form}
          columns={columns}
          tableData={embedData}
          editingKey={editingKey}
          setEditingKey={setEditingKey}
          loading={embedQueryLoading}
        />
      )}
    </Modal>
  );
};

export default ManageEmbeddingsModal;
