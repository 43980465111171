import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { Alert, Spin } from "antd";
import React, { useEffect, useState } from "react";

interface Address {
  address1: string;
  address2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  country?: string;
}

interface AddressMapConfirmationProps {
  address: Address;
  onCoordinatesConfirmed: (coordinates: { lat: number; lng: number }) => void;
  initialCoordinates?: { lat: number; lng: number } | null;
}

// Declare geocoder variable with proper type
let geocoder: google.maps.Geocoder | null = null;

const AddressMapConfirmation: React.FC<AddressMapConfirmationProps> = ({
  address,
  onCoordinatesConfirmed,
  initialCoordinates = null,
}) => {
  const [coordinates, setCoordinates] = useState<{ lat: number; lng: number } | null>(initialCoordinates);
  const [geocodingError, setGeocodingError] = useState<string | null>(null);
  const [isGeocoding, setIsGeocoding] = useState(false);
  const [hasAttemptedGeocoding, setHasAttemptedGeocoding] = useState(false);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
    libraries: ["places"] as const,
  });

  // Format the address for geocoding
  const formatAddress = () => {
    // Create an array of address parts, filtering out any undefined or empty values
    const addressParts = [];

    if (address.address1) addressParts.push(address.address1);
    if (address.address2) addressParts.push(address.address2);
    if (address.city) addressParts.push(address.city);
    if (address.state) addressParts.push(address.state);
    if (address.zipCode) addressParts.push(address.zipCode);
    if (address.country) addressParts.push(address.country);

    return addressParts.join(", ");
  };

  // Geocode the address to get coordinates
  const geocodeAddress = async () => {
    if (!isLoaded || !address.address1) return;

    setIsGeocoding(true);
    setGeocodingError(null);

    try {
      geocoder = new google.maps.Geocoder();
      const formattedAddress = formatAddress();

      if (geocoder) {
        geocoder.geocode({ address: formattedAddress }, (results, status) => {
          if (status === "OK" && results?.[0]) {
            const { location } = results[0].geometry;
            const newCoordinates = {
              lat: location.lat(),
              lng: location.lng(),
            };
            setCoordinates(newCoordinates);
            // Only call onCoordinatesConfirmed if we don't already have coordinates
            // This prevents multiple calls that could cause issues
            if (!coordinates) {
              onCoordinatesConfirmed(newCoordinates);
            }
          } else {
            setGeocodingError(`Could not find coordinates for the address: ${status}`);
          }
          setIsGeocoding(false);
        });
      } else {
        setGeocodingError("Geocoder could not be initialized");
        setIsGeocoding(false);
      }
    } catch (error) {
      setGeocodingError(`Error geocoding address: ${error instanceof Error ? error.message : "Unknown error"}`);
      setIsGeocoding(false);
    }
  };

  // Geocode the address when the component mounts or address changes
  useEffect(() => {
    if (isLoaded && address.address1 && !coordinates && !hasAttemptedGeocoding) {
      setHasAttemptedGeocoding(true);
      geocodeAddress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, address, hasAttemptedGeocoding]);

  // If we already have coordinates, use them
  useEffect(() => {
    if (initialCoordinates && !coordinates) {
      setCoordinates(initialCoordinates);
      onCoordinatesConfirmed(initialCoordinates);
    }
  }, [initialCoordinates, coordinates, onCoordinatesConfirmed]);

  if (loadError) {
    return <Alert type="error" message="Error loading Google Maps" description={loadError.message} />;
  }

  if (!isLoaded) {
    return <Spin tip="Loading Google Maps..." />;
  }

  return (
    <div>
      {isGeocoding && <Spin tip="Deriving coordinates from address..." />}

      {geocodingError && (
        <Alert
          type="error"
          message="Error Finding Location"
          description={geocodingError}
          style={{ marginBottom: 16 }}
        />
      )}

      {coordinates && (
        <div>
          <Alert
            type="info"
            message="Location Coordinates"
            description={`Latitude: ${coordinates.lat.toFixed(6)}, Longitude: ${coordinates.lng.toFixed(6)}`}
            style={{ marginBottom: 16 }}
          />

          <div style={{ height: "400px", width: "100%", marginBottom: 16 }}>
            <GoogleMap center={coordinates} zoom={15} mapContainerStyle={{ height: "100%", width: "100%" }}>
              <Marker
                position={coordinates}
                draggable
                onDragEnd={(e) => {
                  if (!e.latLng) {
                    return;
                  }
                  const newCoordinates = {
                    lat: e.latLng.lat(),
                    lng: e.latLng.lng(),
                  };
                  setCoordinates(newCoordinates);
                  onCoordinatesConfirmed(newCoordinates);
                }}
              />
            </GoogleMap>
          </div>

          <Alert
            type="success"
            message="Map Location Confirmation"
            description="You can drag the marker to adjust the exact location if needed."
            style={{ marginBottom: 16 }}
          />
        </div>
      )}
    </div>
  );
};

export default AddressMapConfirmation;
