import { Col, Layout, Row } from "antd";
import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Can from "components/Can/Can";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import { Permission } from "pacts/permission";
import { useAuth0 } from "services/auth/authService";
// components for routes
import classnames from "classnames";
import { useNewRelicBrowserAgent } from "hooks/use-nr-browser-agent";
import InfrastructureDetailsWrapper from "pages/InfrastructureDetails/InfrastructureDetailsWrapper/InfrastructureDetailsWrapper";
import NodeManagement from "pages/NodeManagement/NodeManagement";
import LocationOtaSettingList from "pages/Ota/LocationOtaSettings/LocationOtaSettingList";
import SmartBEA from "pages/SmartBEA/SmartBEA";
import Header from "../components/Header/Header";
import SurveyResponseProcessor from "../components/SurveyResponseProcessor/SurveyResponseProcessor";
import { LocationProvider } from "../contexts/location-context";
import { MasterDataProvider } from "../contexts/master-data-context";
import useBreakpoint from "../hooks/use-breakpoint";
import useRoleAndPermission from "../hooks/use-role-and-permission";
import CategoriesAndKeysCreation from "../pages/CategoriesAndKeysCreation/CategoriesAndKeysCreation";
import GatewayCreation from "../pages/GatewayCreation/GatewayCreation";
import KeyDetailWrapper from "../pages/Key/KeyDetailWrapper/KeyDetailWrapper";
import CustomerLocationList from "../pages/Location/CustomerLocationList/LocationList";
import LocationDetails from "../pages/Location/LocationDetails/LocationDetails";
import LocationList from "../pages/Location/LocationList/LocationList";
import OtaDetails from "../pages/Ota/OtaDetails/OtaDetails";
import RapidMapping from "../pages/RapidMapping/RapidMapping";
import SmartAllocDetails from "../pages/SmartAlloc/SmartAllocDetails/SmartAllocDetails";
import UserCreateEdit from "../pages/User/UserCreateEdit/UserCreateEdit";
import UserList from "../pages/User/UserList/UserList";
import { ROLE } from "../rbac-rules";

// styling
const { Content } = Layout;

const Routes = () => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  const screens = useBreakpoint();
  const roleAndPermission = useRoleAndPermission();
  const nrBrowserAgent = useNewRelicBrowserAgent();

  return (
    <Layout
      className={classnames("layout", {
        "layout-contractor": roleAndPermission.isContractor(),
      })}
    >
      <ErrorBoundary newrelicAgent={nrBrowserAgent}>
        <Header />
      </ErrorBoundary>
      {!isLoading && isAuthenticated && (
        <ErrorBoundary newrelicAgent={nrBrowserAgent}>
          <Content>
            <Row>
              <Col
                span={24}
                className={classnames({
                  "main-container-lg": screens.desktopUp,
                  "main-container": screens.mobileOnly || screens.tabletOnly,
                })}
              >
                <Switch>
                  <Route path="/users/new">
                    <Can
                      requiredPermission={Permission.USER_CREATE}
                      yes={<UserCreateEdit />}
                      no={<Redirect to="/locations" />}
                    />
                  </Route>
                  <Route path="/users/:userId">
                    <Can
                      requiredPermission={Permission.USER_EDIT}
                      yes={<UserCreateEdit />}
                      no={<Redirect to="/locations" />}
                    />
                  </Route>
                  <Route path="/users">
                    <Can
                      requiredPermission={Permission.USER_VIEW}
                      yes={<UserList />}
                      no={<Redirect to="/locations" />}
                    />
                  </Route>
                  <Route path="/locations/:locationId/rapid-mapping">
                    <Can
                      requiredPermission={Permission.SLOT_RAPID_MAP}
                      yes={
                        <LocationProvider>
                          <RapidMapping />
                        </LocationProvider>
                      }
                      no={<Redirect to="/locations" />}
                    />
                  </Route>
                  <Route path="/locations/:locationId/categories-and-keys-creation">
                    <Can
                      requiredPermission={Permission.KEY_CREATE}
                      yes={
                        <LocationProvider>
                          <MasterDataProvider loadTypes={["ACModels"]}>
                            <CategoriesAndKeysCreation />
                          </MasterDataProvider>
                        </LocationProvider>
                      }
                      no={<Redirect to="/locations" />}
                    />
                  </Route>
                  <Route path="/locations/:locationId/gateways-creation-mode">
                    <Can
                      requiredPermission={Permission.GATEWAY_CREATE}
                      yes={
                        <LocationProvider>
                          <GatewayCreation />
                        </LocationProvider>
                      }
                      no={<Redirect to="/locations" />}
                    />
                  </Route>
                  <Route path="/locations/:locationId/keys/:keyId/:activeTab?">
                    <Can
                      requiredPermission={Permission.LOCATION_VIEW}
                      yes={
                        <LocationProvider>
                          <MasterDataProvider loadTypes={["ACModels"]}>
                            <KeyDetailWrapper />
                          </MasterDataProvider>
                        </LocationProvider>
                      }
                      no={<Redirect to="/locations" />}
                    />
                  </Route>
                  <Route path="/locations/:locationId/infrastructures/:infrastructureId/:activeTab?">
                    <Can
                      requiredPermission={Permission.INFRASTRUCTURE_VIEW}
                      yes={
                        <LocationProvider>
                          <InfrastructureDetailsWrapper />
                        </LocationProvider>
                      }
                      no={<Redirect to="/locations" />}
                    />
                  </Route>
                  <Route path="/locations/:locationId/:activeTab?">
                    <Can
                      requiredPermission={Permission.LOCATION_VIEW}
                      yes={
                        <LocationProvider>
                          <LocationDetails />
                        </LocationProvider>
                      }
                      no={<Redirect to="/locations" />}
                    />
                  </Route>
                  <Route path="/locations">
                    {roleAndPermission.isCustomer() ? <CustomerLocationList /> : <LocationList />}
                  </Route>
                  <Route path="/location-ota-settings">
                    <Can
                      requiredRole={ROLE.PROJECT_COORDINATOR}
                      yes={<LocationOtaSettingList />}
                      no={<Redirect to="/locations" />}
                    />
                  </Route>
                  <Route path="/ota/:activeTab?">
                    <Can
                      requiredRole={ROLE.PROJECT_COORDINATOR}
                      yes={<OtaDetails />}
                      no={<Redirect to="/locations" />}
                    />
                  </Route>
                  <Route path="/smart-alloc/:activeTab?">
                    <Can
                      requiredRole={ROLE.CUSTOMER}
                      yes={
                        <LocationProvider>
                          <SmartAllocDetails />
                        </LocationProvider>
                      }
                      no={<Redirect to="/locations" />}
                    />
                  </Route>
                  <Route path="/node-management">
                    <Can
                      requiredRole={[ROLE.WAREHOUSE_STAFF, ROLE.PROJECT_COORDINATOR]}
                      yes={<NodeManagement />}
                      no={<Redirect to="/locations" />}
                    />
                  </Route>
                  <Route path="/smartbea">
                    <Can
                      requiredRole={[ROLE.PROJECT_COORDINATOR]}
                      yes={<SmartBEA />}
                      no={<Redirect to="/locations" />}
                    />
                  </Route>
                  <Route path="/survey-processor">
                    <Can
                      requiredRole={[ROLE.PROJECT_COORDINATOR]}
                      yes={<SurveyResponseProcessor />}
                      no={<Redirect to="/locations" />}
                    />
                  </Route>
                  <Route path="/">
                    {/* TODO 2020-08-31: add behaviour for routes other than "/", essentially a 404 */}
                    <Redirect to="/locations" />
                  </Route>
                </Switch>
              </Col>
            </Row>
          </Content>
        </ErrorBoundary>
      )}
    </Layout>
  );
};

export default Routes;
